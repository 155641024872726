/* eslint-disable react/prop-types */
import BlockIcon from "@mui/icons-material/Block";
import { CircularProgress } from "@mui/material";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import AddExcelModal from "customComponents/Modals/AddExcelModal";
import { BASE_URL } from "lib/utilities/globalConstants";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import PropTypes from "prop-types";
import { UserAuth } from "provider/AuthProvider";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const CandidatesTable = ({ formId, token, formData }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState({
    pageIndex: parseInt(searchParams.get("pageIndex")) || 0,
    pageSize: parseInt(searchParams.get("pageSize")) || 5,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState(
    searchParams.get("sorting") ? JSON.parse(searchParams.get("sorting")) : []
  );
  const [columnFilters, setColumnFilters] = useState(
    searchParams.get("columnFilters") ? JSON.parse(searchParams.get("columnFilters")) : []
  );
  const [assignmentStatus, setAssignmentStatus] = useState(
    searchParams.get("assignmentStatus") ? JSON.parse(searchParams.get("assignmentStatus")) : ""
  );
  const [interviewStatus, setInterviewStatus] = useState(
    searchParams.get("interviewStatus") ? JSON.parse(searchParams.get("interviewStatus")) : ""
  );
  const [result, setResult] = useState(
    searchParams.get("result") ? JSON.parse(searchParams.get("result")) : ""
  );
  const [interviewStartTime, setInterviewStartTime] = useState(
    searchParams.get("interviewStartTime") ? JSON.parse(searchParams.get("interviewStartTime")) : ""
  );
  const [isOpening, setIsOpening] = useState(false);

  const navigate = useNavigate();
  const { user } = UserAuth();

  useEffect(() => {
    const params = {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      globalFilter: globalFilter,
      sorting: JSON.stringify(sorting),
      columnFilters: JSON.stringify(columnFilters),
      assignmentStatus: JSON.stringify(assignmentStatus),
      interviewStatus: JSON.stringify(interviewStatus),
      result: JSON.stringify(result),
      interviewStartTime: JSON.stringify(interviewStartTime),
    };
    setSearchParams(params, { replace: true });
  }, [pagination, globalFilter, sorting, columnFilters, assignmentStatus, interviewStatus, result]);

  const {
    data = [],
    isLoading,
    isRefetching,
    isError,
  } = useQuery({
    queryKey: [
      "getAllCandidates",
      pagination,
      formId,
      token,
      columnFilters,
      sorting,
      globalFilter,
      assignmentStatus,
      interviewStatus,
      interviewStartTime,
      result,
    ],
    queryFn: fetchCandidates,
    placeholderData: keepPreviousData,
    enabled: !!token && !!formId,
    refetchOnWindowFocus: false,
  });

  async function fetchCandidates() {
    try {
      const res = await fetch(
        `${BASE_URL}admin/getFormCandidates?page=${pagination.pageIndex + 1}&pageSize=${
          pagination.pageSize
        }&columnFilters=${JSON.stringify(columnFilters ?? [])}&globalFilter=${
          globalFilter ?? ""
        }&sorting=${JSON.stringify(
          sorting ?? []
        )}&assignmentStatus=${assignmentStatus}&interviewStatus=${interviewStatus}&interviewStartTime=${interviewStartTime || ""}&result=${result}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            formId,
            adminId: user.uid,
          }),
        }
      );
      const data = await res.json();
      // console.log(data);
      return data;
    } catch (err) {
      console.log(err);
    }
  }
  // console.log(result);

  const columns = [
    {
      header: "ID",
      accessorKey: "id",
      size: 150,
    },
    {
      header: "Name",
      accessorKey: "name",
      size: 220,
    },
    {
      header: "Email",
      accessorKey: "email",
      size: 250,
    },
    {
      header: "Assignment Status",
      enableSorting: false,
      accessorKey: "assignmentStatus",
      size: 230,
      muiTableBodyCellProps: {
        sx: {
          display: "flex",
          justifyContent: "center",
        },
      },
      Cell: ({ cell }) => {
        const status = cell?.row?.original?.submissionId;
        return (
          <span
            style={{
              color: `${status !== null ? "green" : "#faaf00"}`,
              fontWeight: 400,
            }}
          >
            {status !== null || undefined ? "SUBMITTED" : "SENT"}
          </span>
        );
      },
      Filter: () => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              marginTop: "0.2rem",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "0.5rem",
              }}
            >
              <button
                style={{
                  flex: 1,
                  padding: "0.5rem",
                  backgroundColor: "#e0f7fa",
                  border: `${assignmentStatus === 1 ? "1.5px solid black" : "1px solid #009688"}`,
                  color: "#009688",
                  fontWeight: "bold",
                  cursor: "pointer",
                  textAlign: "center",
                }}
                onClick={() => setAssignmentStatus(1)}
              >
                Submitted
              </button>
              <button
                style={{
                  flex: 1,
                  padding: "0.5rem",
                  backgroundColor: "#fff3e0",
                  border: `${assignmentStatus === 0 ? "1.5px solid black" : "1px solid #ff9800"}`,
                  color: "#ff9800",
                  fontWeight: "bold",
                  cursor: "pointer",
                  textAlign: "center",
                }}
                onClick={() => setAssignmentStatus(0)}
              >
                Sent
              </button>
            </div>
            <p
              style={{
                textAlign: "center",
                padding: "0.5rem",
                border: "1px solid #e0e0e0",
                color: "#757575",
                backgroundColor: "#f5f5f5",
                cursor: "pointer",
                fontSize: "14px",
                borderRadius: "4px",
                margin: "0",
              }}
              onClick={() => setAssignmentStatus("")}
            >
              Clear filter
            </p>
          </div>
        );
      },
    },
    {
      header: "Assignment Score",
      accessorKey: "assignmentScore",
      size: 220,
      muiTableBodyCellProps: {
        sx: {
          display: "flex",
          justifyContent: "center",
        },
      },
    },
    {
      header: "Interview Status",
      enableSorting: false,
      accessorKey: "interviewStatus",
      size: 220,
      muiTableBodyCellProps: {
        sx: {
          display: "flex",
          justifyContent: "center",
        },
      },
      Cell: ({ cell }) => {
        const status = cell?.row?.original?.interviewTaken;
        return (
          <span
            style={{
              color: `${status === 0 ? "#faaf00" : "green"}`,
              fontWeight: 400,
            }}
          >
            {status === null ? "" : status === 0 ? "SCHEDULED" : "DONE"}
          </span>
        );
      },
      Filter: () => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              marginTop: "0.2rem",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "0.5rem",
              }}
            >
              <button
                style={{
                  flex: 1,
                  padding: "0.5rem",
                  backgroundColor: "#e0f7fa",
                  border: `${interviewStatus === 1 ? "1.5px solid black" : "1px solid #009688"}`,
                  color: "#009688",
                  fontWeight: "bold",
                  cursor: "pointer",
                  textAlign: "center",
                }}
                onClick={() => setInterviewStatus(1)}
              >
                Done
              </button>
              <button
                style={{
                  flex: 1,
                  padding: "0.5rem",
                  backgroundColor: "#fff3e0",
                  border: `${interviewStatus === 0 ? "1.5px solid black" : "1px solid #ff9800"}`,
                  color: "#ff9800",
                  fontWeight: "bold",
                  cursor: "pointer",
                  textAlign: "center",
                }}
                onClick={() => setInterviewStatus(0)}
              >
                Scheduled
              </button>
            </div>
            <p
              style={{
                textAlign: "center",
                padding: "0.5rem",
                border: "1px solid #e0e0e0",
                color: "#757575",
                backgroundColor: "#f5f5f5",
                cursor: "pointer",
                fontSize: "14px",
                borderRadius: "4px",
                margin: "0",
              }}
              onClick={() => setInterviewStatus("")}
            >
              Clear filter
            </p>
          </div>
        );
      },
    },
    {
      header: "Interview Start Time",
      enableSorting: false,
      size: 220,
      accessorKey: "startTime",
      muiTableBodyCellProps: {
        sx: {
          display: "flex",
          justifyContent: "center",
        },
      },
      Cell: ({ cell }) => {
        function formatDateTime(dateTime) {
          if (!dateTime) return;
          const data = dateTime?.split("T");
          const date = data[0];
          let time = data[1]?.split(".")[0];
          const [year, month, day] = date.split("-");
          const formattedDate = `${day}/${month}/${year}`;
          let [hours, minutes] = time?.split(":");
          const formattedTime = `${hours}:${minutes}`;
          const string = `${formattedDate}, ${formattedTime}`;
          return string;
        }
        const result = cell?.row?.original?.startTime;
        return (
          <span>{formatDateTime(result)}</span>
        );
      },
      Filter: () => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              marginTop: "0.2rem",
            }}
          >
            <input
              type="datetime-local"
              onChange={(e) => setInterviewStartTime(e.target.value)}
              value={interviewStartTime}
              style={{
                padding: "0.5rem",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
            <button
              onClick={() => {
                setInterviewStartTime("");  // Clear filter logic
              }}
              style={{
                padding: "0.5rem",
                backgroundColor: "#e0f7fa",
                border: "1px solid #009688",
                color: "#009688",
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              Clear filter
            </button>
          </div>
        );
      },
    },
    {
      header: "Solved Questions",
      accessorKey: "highRatingCount",
      enableSorting: false,
      size: 190,
    },
    {
      header: "Interview Result",
      enableSorting: false,
      accessorKey: "result",
      size: 220,
      muiTableBodyCellProps: {
        sx: {
          display: "flex",
          justifyContent: "center",
        },
      },
      Cell: ({ cell }) => {
        const result = cell?.row?.original?.result;
        // console.log(result);
        const interviewTaken = cell?.row?.original?.interviewTaken;
        return (
          <span
            style={{
              color: `${result === "fail" ? "red" : "green"}`,
              fontWeight: 400,
            }}
          >
            {interviewTaken === 0 || interviewTaken === null ? "" : result.toUpperCase()}
          </span>
        );
      },
      Filter: () => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              marginTop: "0.2rem",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "0.5rem",
              }}
            >
              <button
                style={{
                  flex: 1,
                  padding: "0.5rem",
                  backgroundColor: "#e0f7fa",
                  border: `${result === "pass" ? "1.5px solid black" : "1px solid #009688"}`,
                  color: "#009688",
                  fontWeight: "bold",
                  cursor: "pointer",
                  textAlign: "center",
                }}
                onClick={() => setResult("pass")}
              >
                Pass
              </button>
              <button
                style={{
                  flex: 1,
                  padding: "0.5rem",
                  backgroundColor: "#ffebee",
                  border: `${result === "fail" ? "1.5px solid black" : "1px solid red"}`,
                  color: "red",
                  fontWeight: "bold",
                  cursor: "pointer",
                  textAlign: "center",
                }}
                onClick={() => setResult("fail")}
              >
                Fail
              </button>
            </div>
            <p
              style={{
                textAlign: "center",
                padding: "0.5rem",
                border: "1px solid #e0e0e0",
                color: "#757575",
                backgroundColor: "#f5f5f5",
                cursor: "pointer",
                fontSize: "14px",
                borderRadius: "4px",
                margin: "0",
              }}
              onClick={() => setResult("")}
            >
              Clear filter
            </p>
          </div>
        );
      },
    },
  ];

  data?.candidates?.forEach((candidate) => {
    candidate.assignmentScore = (candidate.assignmentScore * 100).toFixed(2) + "%";
  });

  const table = useMaterialReactTable({
    columns,
    data: data?.candidates || [],
    enableColumnResizing: true,
    initialState: { showColumnFilters: false },
    onColumnFiltersChange: setColumnFilters,
    manualFiltering: true,
    onGlobalFilterChange: setGlobalFilter,
    manualPagination: true,
    onPaginationChange: setPagination,
    manualSorting: true,
    onSortingChange: setSorting,
    enablePagination: true,
    enableGlobalFilter: false,
    rowCount: data.total || 0,
    // paginationDisplayMode: "pages",
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    state: {
      pagination,
      showProgressBars: isRefetching,
      isLoading,
      sorting,
      columnFilters,
      globalFilter,
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 20],
      showFirstButton: false,
      showLastButton: false,
    },
    getRowId: (row) => row.id,

    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        if (isOpening) return {};
        navigate(`/admin/form/${formId}/candidate/${row.id}`);
      },
      sx: { cursor: "pointer" },
    }),
  });

  const langIds = formData.languages.map((lang) => lang.language_id);
  const exp = formData.experience.split("-")[1];

  if (isError)
    return (
      <div
        style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <BlockIcon style={{ color: "red", height: "4rem", width: "4rem" }} />
          <p style={{ fontWeight: "700", color: "gray", fontSize: "1.3rem" }}>
            You cannot access this page
          </p>
        </div>
      </div>
    );

  return (
    <div
      style={{
        backgroundColor: "#ebebeb",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <div style={{ width: "90%" }}>
        <div style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "18px",
          paddingBottom: "10px",
          paddingInline: "10px"
        }}>
          {formData.jobRole}
          <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            fontSize: "18px",
            gap: "10px",
          }}>
            <span>Add Candidates</span>
            <AddExcelModal
              formId={formId}
              setIsOpening={setIsOpening}
              langIds={langIds}
              exp={exp}
            /></div>
        </div>
        {isLoading ? <CircularProgress /> : <MaterialReactTable table={table} />}
      </div>
    </div>
  );
};

CandidatesTable.propTypes = {
  formId: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
};

export default CandidatesTable;
