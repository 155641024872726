import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { BASE_URL } from "lib/utilities/globalConstants";
import PropTypes from "prop-types";
import { UserAuth } from "provider/AuthProvider";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import ExcelExport from "../ExcelExport";
import MailTemplatesForm from "./MailTemplates";
import SkillBox from "./SkillBox";

function JobForm({ handleClose }) {
  const { user, formData, setFormData, draftId, useDefaultTemplates } = UserAuth();
  const [missingVariables, setMissingVariables] = useState({
    sendAssignmentTemplate: [],
    interviewScheduleTemplate: [],
    interviewInviteTemplate: [],
  });
  const maxAllowedSkills = 10;
  const [remainingSkills, setRemainingSkills] = useState(maxAllowedSkills);
  const [techStack, setTechStack] = useState(formData.draftTechStack || []);
  const [skills, setSkills] = useState([]);
  const [allSkills, setAllSkills] = useState({});
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [pendingChange, setPendingChange] = useState(null);
  const [skillOrExpChange, setSkillOrExpChange] = useState(false);
  const [openSubmissionModal, setOpenSubmissionModal] = useState(false);
  const [templatesValid, setTemplatesValid] = useState(true);
  const [strExists, setStrExists] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    const getUserInfo = async () => {
      if (user) {
        const domain = user.email.split("@")[1].split(".")[0];
        const uid = user.uid;
        const adminName = user.displayName;
        setFormData({ ...formData, companyName: domain, adminId: uid, adminName: adminName });
      }
    };
    getUserInfo();
  }, [user]);

  useEffect(() => {
    if (useDefaultTemplates) return;
    const getLastUsedTemplates = async () => {
      try {
        const response = await fetch(`${BASE_URL}admin/getMailTemplates`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.accessToken}`,
          },
        });
        const data = await response.json();
        if (!data.success) {
          if (data.message === "No templates found") {
            return;
          }
          throw new Error(data.message);
        }
        setFormData((prevFormData) => ({
          ...prevFormData,
          templates: {
            sendAssignmentSubject:
              data.mailTemplates.sendAssignmentSubject ||
              prevFormData.templates.sendAssignmentSubject,
            sendAssignmentTemplate:
              data.mailTemplates.sendAssignmentTemplate ||
              prevFormData.templates.sendAssignmentTemplate,
            interviewScheduleSubject:
              data.mailTemplates.interviewScheduleSubject ||
              prevFormData.templates.interviewScheduleSubject,
            interviewScheduleTemplate:
              data.mailTemplates.interviewScheduleTemplate ||
              prevFormData.templates.interviewScheduleTemplate,
            interviewInviteSubject:
              data.mailTemplates.interviewInviteSubject ||
              prevFormData.templates.interviewInviteSubject,
            interviewInviteTemplate:
              data.mailTemplates.interviewInviteTemplate ||
              prevFormData.templates.interviewInviteTemplate,
          },
        }));
      } catch (error) {
        toast.error(error.message);
      }
    };
    getLastUsedTemplates();
  }, [user, setFormData]);

  useEffect(() => {
    async function getAllSkills() {
      if (!user) return;
      setToken(user.accessToken);
      try {
        const response = await fetch(`${BASE_URL}admin/getFormTechStack`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.accessToken}`,
          },
        });
        const data = await response.json();
        if (data.success) {
          const skillsData = data.data.reduce((acc, item) => {
            acc[item.stack] = item.languages.split(", ");
            return acc;
          }, {});
          setAllSkills(skillsData);
        } else {
          toast.error("Failed to fetch skills data.");
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
    getAllSkills();
  }, [user]);

  const handleTechStackChange = (event) => {
    const selectedStack = event.target.value;
    setTechStack(selectedStack);
    const res = selectedStack.flatMap((key) => allSkills[key]);
    const uniqueRes = new Set(res);
    setSkills([...uniqueRes] || []);
  };

  const handleSkillsChange = (event) => {
    const selectedSkills = event.target.value;
    if (formData.oldSkills.length > 0) {
      setPendingChange(() => () => {
        setFormData({ ...formData, skills: selectedSkills });
        setSkillOrExpChange(true);
        setOpenModal(false);
      });
      setOpenModal(true);
    } else {
      setFormData({ ...formData, skills: selectedSkills });
    }
  };

  const handleDiscardSkill = (skill) => {
    const filtered = formData.skills.filter((prevSkills) => prevSkills !== skill);
    setFormData({ ...formData, skills: filtered });
  };

  const handleTemplateChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      templates: { ...prevFormData.templates, [id]: value },
    }));
  };

  const handleChange = (field) => (event) => {
    if (field === "experience" && formData.oldSkills.length > 0) {
      setPendingChange(() => () => {
        setFormData({ ...formData, [field]: event.target.value });
        setSkillOrExpChange(true);
        setOpenModal(false);
      });
      setOpenModal(true);
    } else {
      setFormData({ ...formData, [field]: event.target.value });
    }
  };

  const handleConfirmChange = () => {
    if (pendingChange) {
      pendingChange();
      setPendingChange(null);
    }
  };

  const handleCandidates = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);
        setFormData({ ...formData, candidates: parsedData });
      };
    } else {
      console.error("No file selected or file type is incorrect.");
    }
  };

  const validateSubmission = (e) => {
    e.preventDefault();
    if (templatesValid) handleSubmit();
    else setOpenSubmissionModal(true);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (!user) return;
    try {
      const response = await fetch(`${BASE_URL}admin/sendJobFormDetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify({ formData: formData, formId: draftId }),
      });
      const data = await response.json();
      if (response.ok) {
        toast.success(data.message);
        queryClient.invalidateQueries(["getAllForms"]);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  function handleSubmissionModalClose() {
    setOpenSubmissionModal(false);
  }

  function handleSubmissionModalProceed() {
    setOpenSubmissionModal(false);
    handleSubmit();
    console.log("Proceeding with submission");
  }

  async function makeDraft() {
    if (!user) return;
    try {
      const response = await fetch(`${BASE_URL}admin/makeJobFormDraft`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        const data = await response.json();
        toast.success(data.message);
        queryClient.invalidateQueries(["getJobFormDrafts"]);
      } else {
        toast.error("An error occurred");
      }
    } catch (error) {
      toast.error(error);
    } finally {
      handleClose();
    }
  }

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
      setFormData({ ...formData, candidates: [] });
    }
  };

  return (
    <form
      onSubmit={validateSubmission}
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        gap: "40px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "max-content",
          padding: "20px 0px 0px",
          gap: "10px",
        }}
      >
        <TextField
          sx={{ mb: 2 }}
          id="jobRole"
          label="Job Role"
          variant="outlined"
          value={formData.jobRole}
          onChange={handleChange("jobRole")}
          required
        />
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel style={{ height: "100%" }} id="tech-stack-label">
            Tech Stack
          </InputLabel>
          <Select
            labelId="tech-stack-label"
            id="tech-stack"
            value={techStack}
            label="Tech Stack"
            multiple
            onChange={handleTechStackChange}
            MenuProps={{
              PaperProps: { sx: { height: 200 } },
            }}
            SelectDisplayProps={{
              style: {
                height: "40px",
                display: "flex",
                alignItems: "center",
              },
            }}
          >
            {Object.keys(allSkills).map((stack) => (
              <MenuItem key={stack} value={stack}>
                {stack}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {(formData?.skills?.length > 0 || techStack?.length > 0) &&
          <FormControl fullWidth sx={{ mb: 1 }} required>
            <InputLabel id="skills-label">Skills</InputLabel>
            <Select
              labelId="skills-label"
              id="skills"
              value={formData.skills}
              label="Skills"
              multiple
              onChange={handleSkillsChange}
              MenuProps={{
                PaperProps: { sx: { height: 200 } },
              }}
              SelectDisplayProps={{
                style: {
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                },
              }}
            >
              {skills.map((skill) => (
                <MenuItem key={skill} value={skill}>
                  {skill}
                </MenuItem>
              ))}
            </Select>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "5px",
                marginTop: "10px",
              }}
            >
              {formData?.skills?.map((skill) => (
                <Chip key={skill} label={skill} onDelete={() => handleDiscardSkill(skill)} />
              ))}
            </div>
          </FormControl>
        }
        <div style={{ marginTop: "15px", marginBottom: "5px" }}>
          <InputLabel id="experience">Years of experience</InputLabel>
          <RadioGroup
            id="experience"
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            label="Years of experience"
            value={formData.experience}
            onChange={handleChange("experience")}
            required
          >
            <FormControlLabel value="0-1" control={<Radio />} label="0-1" />
            <FormControlLabel value="2-3" control={<Radio />} label="2-3" />
            <FormControlLabel value="4-5" control={<Radio />} label="4-5" />
            <FormControlLabel value="6-7" control={<Radio />} label="6-7" />
          </RadioGroup>
        </div>
        <SkillBox
          skills={formData.skills}
          exp={formData.experience.split("-")[1]}
          data={formData.oldSkills}
          setFormData={setFormData}
          newSkills={formData.newSkills}
          token={token}
          skillOrExpChange={skillOrExpChange}
          setSkillOrExpChange={setSkillOrExpChange}
          remainingSkills={remainingSkills}
          setRemainingSkills={setRemainingSkills}
          maxAllowedSkills={maxAllowedSkills}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginBottom: "5px",
          }}
        >
          <InputLabel>
            Click the button to download a sample document.{" "}
            <span style={{ fontWeight: "bolder", textWrap: "wrap", color: "red" }}>
              *Please ensure that you insert it with the exact headings, word for word, as provided*
            </span>
          </InputLabel>
          <ExcelExport />
        </div>
        <input
          ref={fileInputRef}
          type="file"
          accept=".xlsx, .xls"
          required
          onChange={handleCandidates}
          style={{ padding: "5px 3px 5px", color: "#3d4f6e", cursor: "pointer" }}
        />
        <p
          style={{
            width: "max-content",
            padding: "5px 4px 5px",
            borderBottom: "1px solid #006566",
            color: "#006566",
            fontWeight: "400",
            backgroundColor: "#f9f9f9",
            lineHeight: "3px",
            cursor: "pointer",
          }}
          onClick={clearFileInput}
        >
          Reset File Input
        </p>
        <MailTemplatesForm
          templates={formData.templates}
          handleTemplateChange={handleTemplateChange}
          setTemplatesValid={setTemplatesValid}
          missingVariables={missingVariables}
          setMissingVariables={setMissingVariables}
          setStrExists={setStrExists}
          strExists={strExists}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
        <Button
          onClick={makeDraft}
          style={{
            color: "black",
            backgroundColor: "lightgray",
            width: "30%",
            padding: "0px , 6px , 0px",
            cursor: "pointer",
          }}
          disabled={isLoading || formData.candidates.length > 0}
        >
          Save as Draft
        </Button>
        <Button
          style={{ color: "white", width: "30%", padding: "0px , 6px , 0px", cursor: "pointer" }}
          variant="contained"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? "Submitting" : "Submit"}
        </Button>
      </div>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "1px solid black",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <h2
            style={{
              marginBottom: 16,
              fontSize: 24,
              fontWeight: "bolder",
              textAlign: "center",
              color: "red",
            }}
          >
            Warning
          </h2>
          <p style={{ marginBottom: 24, fontSize: 16, textAlign: "center", color: "#555" }}>
            Changing the skills or experience will reset your skills changes. Do you want to
            proceed?
          </p>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              style={{ color: "black" }}
              variant="outlined"
              onClick={() => setOpenModal(false)}
            >
              No
            </Button>
            <Button
              style={{ backgroundColor: "#006566", color: "white" }}
              variant="contained"
              onClick={handleConfirmChange}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={openSubmissionModal} onClose={handleSubmissionModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            border: "1px solid black",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          {Object.values(missingVariables).some((v) => v.length > 0) &&
            <>
            <h2
              style={{
                marginBottom: 16,
                fontSize: 24,
                fontWeight: "bolder",
                textAlign: "center",
                color: "red",
              }}
            >
              Missing Variables Detected
            </h2>
            <p style={{ marginBottom: 24, fontSize: 16, textAlign: "center", color: "#555" }}>
              Some templates are missing required variables. Do you want to proceed with submission?
              <ul>
                {Object.entries(missingVariables).map(([templateId, missingVars]) =>
                  missingVars.length > 0 ? (
                    <li key={templateId}>
                      <b>{templateId}:</b> Missing "{missingVars.join('", "')}"
                    </li>
                  ) : null
                )}
              </ul>
            </p>
            </>
          }
          {Object.values(strExists).some((s) => s.length > 0) &&
            <>
              <h2
                style={{
                  marginBottom: 16,
                  fontSize: 24,
                  fontWeight: "bolder",
                  textAlign: "center",
                  color: "red",
                }}
              >
                Missing Essential Information
              </h2>
              <p style={{ marginBottom: 24, fontSize: 16, textAlign: "center", color: "#555" }}>
                Some templates are missing essential information, such as the Job Role and Company Name. Please ensure all fields are complete before submitting.
                <ul>
                  {Object.entries(strExists).map(([templateId, str]) =>
                    str.length > 0 ? (
                      <li key={templateId}>
                        <b>{templateId}:</b> Missing "{str.join('", "')}"
                      </li>
                    ) : null
                  )}
                </ul>
              </p>
            </>
          }
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              style={{ color: "black" }}
              variant="outlined"
              onClick={handleSubmissionModalClose}
            >
              Go Back
            </Button>
            <Button
              style={{ backgroundColor: "#006566", color: "white" }}
              variant="contained"
              onClick={handleSubmissionModalProceed}
            >
              Submit Anyway
            </Button>
          </Box>
        </Box>
      </Modal>
    </form>
  );
}

JobForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default JobForm;
