// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";
import AboutUs from "layouts/pages/landing-pages/about-us";
import ContactUs from "layouts/pages/landing-pages/contact-us";
// import Author from "layouts/pages/landing-pages/author";
import Clients from "layouts/pages/clients/our-clients";
import BrainGage from "layouts/pages/landing-pages/brain-gage";
import EvalAI from "layouts/pages/landing-pages/eval-ai";
import Hyrr from "layouts/pages/landing-pages/hyrr";
import HyrrStream from "layouts/pages/landing-pages/hyrr-stream";
export default {
  brand: {
    name: "Neusort",
    image: logoCT,
    route: "/",
  },
  socials: [
    // {
    //   icon: <FacebookIcon />,
    //   link: "https://www.facebook.com/CreativeTim/",
    // },
    // {
    //   icon: <TwitterIcon />,
    //   link: "https://twitter.com/creativetim",
    // },
    // {
    //   icon: <GitHubIcon />,
    //   link: "https://github.com/creativetimofficial",
    // },
    // {
    //   icon: <YouTubeIcon />,
    //   link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
    // },
  ],
  menus: [
    {
      name: "services",
      items: [
        {
          name: "EvalAI",
          route: "/pages/landing-pages/eval-ai",
          component: <EvalAI />,
        },
        {
          name: "BrainGage",
          route: "/pages/landing-pages/brain-gage",
          component: <BrainGage />,
        },
        {
          name: "HyrrStream",
          route: "/pages/landing-pages/hyrr-stream",
          component: <HyrrStream />,
        },
        {
          name: "Hyrr",
          route: "/pages/landing-pages/hyrr",
          component: <Hyrr />,
        },
      ],
    },
    {
      name: "About us",
      items: [
        {
          name: "Our Team",
          route: "/pages/landing-pages/about-us",
          component: <AboutUs />,
        },
        {
          name: "Our clients",
          route: "/pages/landing-pages/our-clients",
          component: <Clients />,
        },
      ],
    },
    {
      name: "Careers",
      items: [
        {
          name: "contact us",
          route: "/pages/landing-pages/contact-us",
          component: <ContactUs />,
        },
      ],
    },
    // {
    //   name: "contact us",
    //   route: "/pages/landing-pages/contact-us",
    //   component: <ContactUs />,
    // },
    // {
    //   name: "Footprint",
    //   items: [
    //     { name: "Case Studies", href: "https://www.neusort.com/terms" },
    //     { name: "Blog", href: "https://www.neusort.com/privacy" },
    //     { name: "Research", href: "https://www.neusort.com/license" },
    //   ],
    // },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Made with love by
      <MKTypography
        component="a"
        href="https://www.neusort.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Neusort Tech
      </MKTypography>
      .
    </MKTypography>
  ),
};
