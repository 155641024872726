import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import { BASE_URL } from "lib/utilities/globalConstants";
import PropTypes from "prop-types";
import { useState } from "react";

function RoomData({ data, id, companyId, token }) {
  const [isLoading, setisLoading] = useState(false);

  function calculateDuration(startTime, endTime) {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const duration = Math.abs(end - start);
    const hours = Math.floor(duration / 36e5);
    const minutes = Math.floor((duration % 36e5) / 60000);
    return `${hours} hours and ${minutes} minutes`;
  }

  const fileName = `${data.roomid}_${data.file_create_time}`;
  const handleDownloadRecording = async () => {
    try {
      setisLoading(true);
      const response = await fetch(`${BASE_URL}admin/downloadRecording/${fileName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();

      if (response.status === 200 && data.downloadUrl) {
        const link = document.createElement('a');
        link.href = data.downloadUrl;
        link.download = `${fileName}.mp4`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error('Failed to retrieve download URL');
      }
    } catch (error) {
      console.error('Error fetching download URL:', error);
    } finally {
      setisLoading(false);
    }
  }

  function formatDateTime(dateTime) {
    if (!dateTime) return;
    const data = dateTime?.split("T");
    const date = data[0];
    const time = data[1]?.split(".")[0];
    const abbreviation = time?.split(":")[0] >= 12 ? "PM" : "AM";
    const string = `${date},${time} ${abbreviation}`;
    return string;
  }

  return (
    <div
      id={id}
      style={{
        marginBottom: "4rem",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          textAlign: "center",
          fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
        }}
      >
        <a
          href={`https://evalai.neusort.com/${companyId ? companyId + "/" + data?.roomid : data?.roomid}`}
          target="_blank"
          rel="noreferrer"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.5rem",
            fontSize: "1rem",
            fontWeight: "400",
            color: "#007bff",
            textDecoration: "none",
            border: "1px solid #007bff",
            padding: "10px 20px",
            borderRadius: "5px",
          }}
        >
          Meeting Link
          <VideoCameraFrontIcon fontSize="medium" />
        </a>
        <h1
          style={{
            fontSize: "24px",
            color: "#333",
            margin: "20px 0 10px",
            fontWeight: "600",
            textAlign: "left",
          }}
        >
          Start Time:{" "}
          <span style={{ fontWeight: "400", color: "#666" }}>
            {formatDateTime(data?.start_time)}
          </span>
        </h1>
        <h1
          style={{
            fontSize: "24px",
            color: "#333",
            margin: "10px 0",
            fontWeight: "600",
            textAlign: "left",
          }}
        >
          End Time:{" "}
          <span style={{ fontWeight: "400", color: "#666" }}>{formatDateTime(data?.end_time)}</span>
        </h1>
        <p
          style={{
            fontSize: "18px",
            color: "#333",
            margin: "10px 0",
            fontWeight: "600",
          }}
        >
          Duration:{" "}
          <span style={{ fontWeight: "400", color: "#666" }}>
            {calculateDuration(data?.start_time, data?.end_time)}
          </span>
        </p>
        {data?.file_create_time &&
          <button
            onClick={handleDownloadRecording}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.5rem",
              fontSize: "1rem",
              fontWeight: "400",
              color: "#007bff",
              textDecoration: "none",
              border: "1px solid #007bff",
              padding: "10px 20px",
              borderRadius: "5px",
              background: "white",
              width: "100%",
              cursor: "pointer"
            }}
          >
            {isLoading ? "Generating Url" : "Download Interview Recording"}
            <VideoCameraFrontIcon fontSize="medium" />
          </button>}
      </div>
    </div>
  );
}

RoomData.propTypes = {
  id: PropTypes.string.isRequired,
  companyId: PropTypes.string,
  token: PropTypes.string.isRequired,
  data: PropTypes.shape({
    end_time: PropTypes.string.isRequired,
    roomid: PropTypes.string.isRequired,
    start_time: PropTypes.string.isRequired,
  }).isRequired,
};

export default RoomData;
