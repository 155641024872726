import { useMediaQuery, useTheme } from "@mui/material";
import SendRecQuestionsModal from "customComponents/Modals/sendRecQuestionsModal";
import PropTypes from "prop-types";
import { useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import AssignmentFeatures from "./AssignmentFeatures";
import QuestionsV1 from "./QuestionsV1";
import QuestionsV2 from "./QuestionsV2";
import RecommendedQuestions from "./RecommendedQuestions";

function SubmissionResults({ id, data, token, uid }) {
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const theme = useTheme();
  const isSmallAndMediumScreen = useMediaQuery(theme.breakpoints.down("sm" && "md"));

  const chartData = [
    {
      name: "No. of features",
      Expected: data?.expectedFeatures?.length || 0,
      Unimplemented: data?.unimplementedFeatures?.length || 0,
    },
  ];

  return (
    <div
      id={id}
      style={{
        marginTop: "4rem",
        width: "100%",
        height: "max-content",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "4rem",
      }}
    >
      <div
        style={{
          width: "70%",
          padding: "2rem 3rem 2rem",
          height: "30rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "white",
        }}
      >
        <ResponsiveContainer
          style={{ fontWeight: "400", fontSize: `${isSmallAndMediumScreen ? "15px" : "20px"}` }}
          width="100%"
          height="100%"
        >
          <BarChart
            width={500}
            height={300}
            data={chartData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
            <XAxis dataKey="name" stroke="black" />
            <YAxis stroke="black" />
            <Tooltip
              contentStyle={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                border: "1px solid #ccc",
              }}
              cursor={{ fill: "rgba(200, 200, 200, 0.3)" }}
            />
            <Legend />
            <Bar dataKey="Expected" fill="#4caf50" radius={[10, 10, 0, 0]} />
            <Bar dataKey="Unimplemented" fill="#ff9800" radius={[10, 10, 0, 0]} />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <AssignmentFeatures
        unimplemented={data?.unimplementedFeatures}
        expected={data?.expectedFeatures}
        githubLink={data?.githubLink}
        score={data.score}
        uid={uid}
      />
      {data.recommendedQuestions ? (
        <RecommendedQuestions data={data.recommendedQuestions} />
      ) : (
        <div
          style={{
            marginBottom: "3rem",
            padding: "0rem 5rem 0rem",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
          }}
        >
          <h2
            style={{
              textAlign: "center",
              fontWeight: "900",
              color: "#dff7f9",
              backgroundColor: "#00796b",
              padding: "2rem 5rem 2rem",
              borderRadius: "25px",
            }}
          >
            You have the option to select the questions that EvalAi should ask, or EvalAi will
            randomly select the questions.
          </h2>
          <div
            style={{
              backgroundColor: "whitesmoke",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "15px",
              padding: " 1rem 1rem 1rem",
              width: "100%",
              display: "flex",
              flexDirection: `${isSmallAndMediumScreen ? "column" : "row"}`,
              flexWrap: "wrap",
              gap: "30px",
              justifyContent: "space-around",
            }}
          >
            {data?.questionsV2 ? (
              <QuestionsV2
                questionSets={data.questionsV2}
                selectedQuestions={selectedQuestions}
                setSelectedQuestions={setSelectedQuestions}
                isSmallAndMediumScreen={isSmallAndMediumScreen}
              />
            ) : (
              <QuestionsV1
                questions={data.questions}
                selectedQuestions={selectedQuestions}
                setSelectedQuestions={setSelectedQuestions}
                isSmallAndMediumScreen={isSmallAndMediumScreen}
              />
            )}
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <SendRecQuestionsModal
              token={token}
              recQuestions={selectedQuestions}
              firebaseLink={data.firebaseLink}
            />
          </div>
        </div>
      )}
    </div>
  );
}

SubmissionResults.propTypes = {
  id: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  data: PropTypes.shape({
    date: PropTypes.string.isRequired,
    firebaseLink: PropTypes.string.isRequired,
    githubLink: PropTypes.string.isRequired,
    score: PropTypes.number.isRequired,
    expectedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
    unimplementedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
    questions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
    recommendedQuestions: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default SubmissionResults;
