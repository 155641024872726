/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";

// Images
import bgBack from "assets/images/rotating-card-bg-back.jpeg";
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
// import AssistantIcon from "@mui/icons-material/Assistant";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Feel the
                    <br />
                    Change
                  </>
                }
                description="Neusort's suite of products is at the forefront of revolutionizing the recruitment landscape, leveraging cutting-edge neuroscience methodologies to optimize the hiring process."
              />
              <RotatingCardBack
                image={bgBack}
                title="Discover More"
                description="Transform your recruitment efficiency with Neusort's innovative solutions—streamline your hiring process and allocate your valuable time and resources more effectively"
                action={{
                  type: "internal",
                  route: "/pages/landing-pages/contact-us",
                  label: "Request a demo",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FilledInfoCard
                  icon="assistant"
                  title="EvalAI"
                  description="Utilize AI-powered interviews to impartially identify and shortlist the most suitable candidates for the position, eliminating unconscious biases and enhancing the selection process."
                  action={{
                    type: "internal",
                    route: "/pages/landing-pages/eval-ai",
                    label: "Read more",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FilledInfoCard
                  icon="person"
                  title="BrainGage"
                  description="Discover the ideal candidate for your organization through our neuroscience game-based assessments, designed for precision-fit hiring."
                  action={{
                    type: "internal",
                    route: "/pages/landing-pages/brain-gage",
                    label: "Read more",
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <FilledInfoCard
                  icon="data_exploration"
                  title="HyrrStream"
                  description="Gain immediate entry to our curated candidate database, complete with detailed metrics, to select the perfect match for your open position with precision."
                  action={{
                    type: "internal",
                    route: "/pages/landing-pages/hyrr-stream",
                    label: "Read more",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FilledInfoCard
                  icon="autorenew"
                  title="Hyrr"
                  description="Our B2C platform operates as a self-sustaining agency, utilizing AI automation to create and meticulously maintain a comprehensive candidate database."
                  action={{
                    type: "internal",
                    route: "/pages/landing-pages/hyrr",
                    label: "Read more",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
