import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";

function ExcelExport() {
  const excelData = [
    {
      name: "Ram",
      email: "ram@gmail.com",
      phoneNo: "9098459999",
    },
    {
      name: "Shyam",
      email: "shyam@gmail.com",
      phoneNo: "9997645399",
    },
  ];
  const fileName = "Sample_excel_sheet";
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
      }}
    >
      <p
        style={{
          width: "max-content",
          padding: "5px 4px 5px",
          borderBottom: "1px solid #006566",
          color: "#006566",
          fontWeight: "400",
          backgroundColor: "#f9f9f9",
          lineHeight: "3px",
          cursor: "pointer",
        }}
        onClick={exportToExcel}
      >
        Download
      </p>
    </div>
  );
}

export default ExcelExport;
