import PropTypes from "prop-types";

function QuestionsV1({
  questions,
  selectedQuestions,
  isSmallAndMediumScreen,
  setSelectedQuestions,
}) {
  function handleQuestionClick(question) {
    setSelectedQuestions((prevSelected) => {
      if (prevSelected.some((q) => q.question === question.question)) {
        return prevSelected.filter((q) => q.question !== question.question);
      } else {
        return [...prevSelected, question];
      }
    });
  }
  return questions?.map((qArray) =>
    qArray?.map((question) => {
      const isSelected = selectedQuestions.some((q) => q.question === question.question);
      return (
        <div
          key={question.question}
          onClick={() => handleQuestionClick(question)}
          style={{
            marginBottom: "20px",
            padding: "15px",
            backgroundColor: isSelected ? "#d4edda" : "#fff",
            borderRadius: "8px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            width: `${isSmallAndMediumScreen ? "100%" : "30%"}`,
          }}
        >
          <h1
            style={{
              fontSize: "1.1rem",
              color: "#333",
              margin: "0 0 10px 0",
              fontWeight: "400",
            }}
          >
            {question.question}
          </h1>
          <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
            {question?.concepts?.map((c) => (
              <p
                key={c}
                style={{
                  backgroundColor: "#e0f7fa",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  fontSize: "0.7em",
                  color: "#00796b",
                }}
              >
                {c}
              </p>
            ))}
          </div>
        </div>
      );
    })
  );
}

QuestionsV1.propTypes = {
  questions: PropTypes.object.isRequired,
  selectedQuestions: PropTypes.array.isRequired,
  isSmallAndMediumScreen: PropTypes.bool.isRequired,
  setSelectedQuestions: PropTypes.func.isRequired,
};

export default QuestionsV1;
