import PropTypes from "prop-types";
import QuestionHeader from "./QuestionHeader";
import QuestionAccordion from "./QuestionAccordion";

function InterviewQuestions({ data }) {
  return (
    <div
      style={{
        maxWidth: "80%",
        display: "flex",
        flexDirection: "column",
        gap: "3rem",
        padding: "2rem",
        backgroundColor: "#f9f9f9",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        borderRadius: "10px",
      }}
    >
      <QuestionHeader />
      {data.map((item, questionIndex) => (
        <QuestionAccordion key={questionIndex} item={item} questionIndex={questionIndex} />
      ))}
    </div>
  );
}

InterviewQuestions.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string,
      programming_languages: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
      attempts: PropTypes.arrayOf(
        PropTypes.shape({
          answer: PropTypes.string,
          coding_hints: PropTypes.arrayOf(
            PropTypes.shape({
              hint: PropTypes.string.isRequired,
              query: PropTypes.string,
            })
          ).isRequired,
          coding_conversation_ratings: PropTypes.arrayOf(
            PropTypes.shape({
              programming_language: PropTypes.string,
              rating_id: PropTypes.number,
              rating_value: PropTypes.number.isRequired,
              rating_reason: PropTypes.string,
              rating_title: PropTypes.string.isRequired,
            })
          ).isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};

export default InterviewQuestions;
