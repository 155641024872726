import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useQueryClient } from "@tanstack/react-query";
import { BASE_URL } from "lib/utilities/globalConstants";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { toast } from "react-toastify";

export default function SendRecQuestionsModal({ token, recQuestions, firebaseLink }) {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  // console.log(token, recQuestions, firebaseLink);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const send = async () => {
    try {
      const response = await fetch(`${BASE_URL}admin/sendRecommendedQuestions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ recQuestions, firebaseLink }),
      });
      const data = await response.json();
      if (response.ok) {
        toast.success(data.message);
        queryClient.invalidateQueries(["getCandidateAssignment"]);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Some error occurred:", error);
      toast.error(error.message);
    } finally {
      handleClose();
    }
  };

  return (
    <React.Fragment>
      <Button
        disabled={recQuestions.length === 0}
        variant="contained"
        sx={{ width: "200px" }}
        style={{ color: "white" }}
        onClick={handleClickOpen}
      >
        Send Questions
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Send {recQuestions.length} Questions to EvalAi</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to proceed with sending these questions? Please note that this
            action is irreversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={send}>Send</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

SendRecQuestionsModal.propTypes = {
  token: PropTypes.string.isRequired,
  firebaseLink: PropTypes.string.isRequired,
  recQuestions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      concepts: PropTypes.arrayOf(PropTypes.string).isRequired,
      example: PropTypes.string,
      fileName: PropTypes.string,
      unimplementedFeature: PropTypes.string,
    }).isRequired
  ).isRequired,
};
