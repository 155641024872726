import { constants } from "lib/utilities/Auth";
import { z } from "zod";
const { regex } = constants;
export const signupSchema = z.object({
  fullName: z
    .string()
    .min(2, "Name must be atleast 2 characters")
    .max(50, `Name can't be more than 50 characters`),
  gender: z.string().min(2, "Select a gender").max(50, `Gender can't be more than 50 characters`),
  phone: z
    .string()
    .min(10, "Phone number must be atleast 10 characters")
    .max(10, "Phone number must be atmost 10 characters")
    .transform((value) => {
      return value.replace(regex.bannedSymbols.phoneNumber, "");
    })
    .refine((value) => value.match(regex.validation.phoneNumber), {
      message: "Invalid phone number",
    }),
  dob: z.date({
    required_error: "A date of birth is required.",
  }),
  email: z.string().email(),
  password: z
    .string()
    .min(6, "Password must be atleast 6 characters")
    .max(50, `Password can't be more than 50 characters`),
});

export const techyrrAdminSignupSchema = z.object({
  fullName: z
    .string()
    .min(2, "Name must be atleast 2 characters")
    .max(50, `Name can't be more than 50 characters`),
  company: z
    .string()
    .min(2, "Company name must be atleast 2 characters")
    .max(50, `Company name can't be more than 50 characters`),
  gender: z.string().min(2, "Select a gender").max(50, `Gender can't be more than 50 characters`),
  phone: z
    .string()
    .min(10, "Phone number must be atleast 10 characters")
    .max(10, "Phone number must be atmost 10 characters")
    .transform((value) => {
      return value.replace(regex.bannedSymbols.phoneNumber, "");
    })
    .refine((value) => value.match(regex.validation.phoneNumber), {
      message: "Invalid phone number",
    }),
  dob: z.date({
    required_error: "A date of birth is required.",
  }),
  email: z.string().email(),
  password: z
    .string()
    .min(6, "Password must be atleast 6 characters")
    .max(50, `Password can't be more than 50 characters`),
});
