import { AccountCircleRounded, EmailRounded, PrivacyTipRounded } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import Navbar from "./Navbar";

const ConfigNavbar = (origin) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const techyrrRoutes = [
    {
      icon: <AccountCircleRounded />,
      name: "Recruiter",
      collapse: [
        {
          name: "Sign up",
          route: "/techyrr-admin/signup/techyrr",
        },
        {
          name: "Sign in",
          route: "/techyrr-admin/signin/techyrr",
        },
        {
          name: "Forgot Password?",
          route: "/techyrr-admin/forgot-password/techyrr",
        },
      ],
    },
    {
      icon: <AccountCircleRounded />,
      name: "Student",
      collapse: [
        {
          name: "Sign up",
          route: "/signup/techyrr",
        },
        {
          name: "Sign in",
          route: "/signin/techyrr",
        },
        {
          name: "Forgot Password?",
          route: "/forgot-password/techyrr",
        },
      ],
    },
    ...(isSmallScreen
      ? [
          {
            icon: <PrivacyTipRounded />,
            name: "Policy",
            collapse: [
              {
                name: "Services",
                href: "https://techyrr.com/policies/services",
              },
              {
                name: "Privacy Policy",
                href: "https://techyrr.com/policies/privacy-policy",
              },
              {
                name: "Refund Policy",
                href: "https://techyrr.com/policies/refund-policy",
              },
              {
                name: "Terms and Conditions",
                href: "https://techyrr.com/policies/terms-and-conditions",
              },
            ],
          },
          {
            icon: <EmailRounded />,
            name: "Contact Us",
            route: "mailto:contact@techyrr.com",
          },
        ]
      : []),
  ];

  switch (origin) {
    case "techyrr":
      return () => <Navbar brand={"Techhyrr"} brandLink={""} routes={techyrrRoutes} />;
    default:
      return false;
  }
};

export default ConfigNavbar;
