const errorCodes = {
  firebase: {
    "auth/email-already-in-use": "Email already in use",
    "auth/email-already-exists": "Email already in use",
    "auth/invalid-email": "Invalid email",
    "auth/weak-password": "Weak password",
    "auth/user-not-found": "User not found",
    "auth/wrong-password": "Wrong password",
    "auth/missing-password": "Missing password",
    "auth/too-many-requests": "Too many requests",
    "auth/other": "Something went wrong",
  },
  incomplete: {
    email: "incomplete/email",
    name: "incomplete/name",
    password: "incomplete/password",
    gender: "incomplete/gender",
    phoneNumber: "incomplete/phone-number",
    dateOfBirth: "incomplete/date-of-birth",
    privacyPolicy: "incomplete/privacy-policy",
  },
  custom: {
    invalidPhoneNumber: "custom/invalid-phone-number",
    invalidEmail: "custom/invalid-email",
    emailAlreadyUsed: "custom/email-already-used",
  },
};

const regex = {
  validation: {
    email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
    phoneNumber: /^([+]{1}[\d]{1,})?\d{10}$/,
  },
  bannedSymbols: {
    name: /[^a-zA-Z\s]/g,
    phoneNumber: /[^\d+]/g,
    email: /[^a-zA-Z0-9@._-]/g,
  },
};

const constants = { errorCodes, regex };

export default constants;
