import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { BASE_URL } from "lib/utilities/globalConstants";
import { UserAuth } from "provider/AuthProvider";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const ApproveCustomSkills = () => {
  const { companyId, formId } = useParams();
  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [openWarning, setOpenWarning] = useState(false);
  const [formApproved, setFormApproved] = useState(false);
  const { user } = UserAuth();

  const handleCheckboxChange = (skillId) => {
    setSelectedSkills((prev) =>
      prev.includes(skillId) ? prev.filter((id) => id !== skillId) : [...prev, skillId]
    );
  };

  const fetchUnapprovedSkills = async () => {
    const idTokenResult = await user.getIdTokenResult();
    const res = await fetch(
      `${BASE_URL}super-admin/get-unapproved-custom-skills/${companyId}/${formId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idTokenResult.token}`,
        },
      }
    );
    const data = await res.json();
    setSkills(data?.data ?? []);
    return data;
  };

  const { isLoading, isError } = useQuery({
    queryKey: ["unapprovedSkills", formId],
    queryFn: fetchUnapprovedSkills,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const handleApproveSelected = async (skillIds) => {
    const idTokenResult = await user.getIdTokenResult();
    const res = await fetch(`${BASE_URL}super-admin/approve-custom-skills`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idTokenResult.token}`,
      },
      body: JSON.stringify({ skillIds }),
    });
    const result = await res.json();
    if (res.ok) {
      toast.success("Skill approved successfully");
      setSkills((prev) => prev.filter((skill) => !skillIds.includes(skill.id)));
    } else {
      toast.error(result.message);
    }
  };

  const handleApproveAll = async () => {
    handleApproveSelected(skills.map((skill) => skill.id));
    toast.success("All skills approved successfully");
  };

  const handleApproveForm = async () => {
    const unapprovedSkills = skills.filter((skill) => !skill.approved);
    if (unapprovedSkills.length > 0) {
      setOpenWarning(true);
    } else {
      await finalizeFormApproval();
    }
  };

  const finalizeFormApproval = async () => {
    const idTokenResult = await user.getIdTokenResult();
    const res = await fetch(`${BASE_URL}super-admin/approve-form`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idTokenResult.token}`,
      },
      body: JSON.stringify({ companyId, formId }),
    });
    if (res.ok) {
      toast.success("Form approved successfully");
      setFormApproved(true);
    } else {
      const result = await res.json();
      toast.error(result.message);
    }
  };

  const handleProceedWarning = async () => {
    setOpenWarning(false);
    await finalizeFormApproval();
  };

  if (isLoading) return <CircularProgress />;
  if (isError) return <div>Error loading skills</div>;

  return (
    <div
      style={{ display: "flex", flexDirection: "column", padding: "30px 40px 0px", gap: "15px" }}
    >
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ display: "flex" }}>
              <TableCell sx={{ flex: 1 }} align="center">
                Select
              </TableCell>
              <TableCell sx={{ flex: 2 }}>ID</TableCell>
              <TableCell sx={{ flex: 2 }} align="center">
                Skill
              </TableCell>
              <TableCell sx={{ flex: 2 }} align="center">
                Programming Language
              </TableCell>
              <TableCell sx={{ flex: 2 }} align="center">
                Approval Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {skills
              .filter((skill) => skill.approved !== 1)
              .map((skill, index) => (
                <TableRow
                  key={skill.id}
                  sx={{ display: "flex", "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell sx={{ flex: 1 }} align="center">
                    <Checkbox
                      checked={selectedSkills.includes(skill.id) || skill.approved}
                      onChange={() => handleCheckboxChange(skill.id)}
                      disabled={skill.approved}
                    />
                  </TableCell>
                  <TableCell sx={{ flex: 2 }}>{skill.id}</TableCell>
                  <TableCell sx={{ flex: 2 }} align="center">
                    {skill.skill}
                  </TableCell>
                  <TableCell sx={{ flex: 2 }} align="center">
                    {skill.programming_language.programming}
                  </TableCell>
                  <TableCell sx={{ flex: 2 }} align="center">
                    {skill.approved ? "Approved" : "Pending"}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "20px 20px",
          gap: "20px",
          flexWrap: "wrap",
        }}
      >
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleApproveSelected(selectedSkills)}
            style={{ color: "white" }}
          >
            Approve Selected Skills
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleApproveAll}
            style={{ color: "white" }}
          >
            Approve All Skills
          </Button>
        </div>

        <Button
          variant="contained"
          color="primary"
          onClick={handleApproveForm}
          disabled={formApproved}
          style={{ color: "white" }}
        >
          {formApproved ? "Form Approved" : "Approve Form"}
        </Button>
      </div>

      <Dialog open={openWarning} onClose={() => setOpenWarning(false)}>
        <DialogTitle>Unapproved Skills</DialogTitle>
        <DialogContent>
          <DialogContentText>
            There are still unapproved skills. Are you sure you want to approve the form with
            unapproved skills?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenWarning(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleProceedWarning} color="secondary" autoFocus>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ApproveCustomSkills;
