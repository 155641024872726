import { Paper } from "@mui/material";

const PropTypes = require("prop-types");

function QuestionsV2({
  questionSets,
  selectedQuestions,
  isSmallAndMediumScreen,
  setSelectedQuestions,
}) {
  function handleSelectMultipleQuestions(qArray) {
    setSelectedQuestions((prevSelected) => {
      if (qArray.some((q) => prevSelected.some((sq) => sq.question === q.question))) {
        return [];
      } else {
        return qArray;
      }
    });
  }
  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
      {questionSets.map((qArray, arrayIndex) => {
        const isSelected = qArray.some((q) =>
          selectedQuestions.some((sq) => sq.question === q.question)
        );
        return (
          <Paper
            key={arrayIndex}
            sx={{
              borderRadius: "8px",
              backgroundColor: "#fff",
              border: isSelected ? "2px solid #28a745" : "2px solid #fff",
            }}
            onClick={() => handleSelectMultipleQuestions(qArray)}
          >
            <p
              style={{
                textAlign: "center",
                fontSize: "1.2rem",
                fontWeight: "bold",
                margin: "10px 0 0 0",
                color: "#00796b",
              }}
            >
              Set {arrayIndex + 1}
            </p>
            <div
              style={{
                width: "100%",
                padding: "20px",
                cursor: "pointer",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
                gap: "20px",
              }}
            >
              {qArray?.map((questionList) => {
                return (
                  <div
                    key={questionList.question}
                    style={{
                      padding: "15px",
                      backgroundColor: isSelected ? "#d4edda" : "#fff",
                      borderRadius: "8px",
                      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      width: `${isSmallAndMediumScreen ? "100%" : "30%"}`,
                    }}
                  >
                    <h1
                      style={{
                        fontSize: "1.1rem",
                        color: "#333",
                        margin: "0 0 10px 0",
                        fontWeight: "400",
                      }}
                    >
                      {questionList.question}
                    </h1>
                    <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
                      {questionList?.concepts?.map((c) => (
                        <p
                          key={c}
                          style={{
                            backgroundColor: "#e0f7fa",
                            borderRadius: "5px",
                            padding: "5px 10px",
                            fontSize: "0.7em",
                            color: "#00796b",
                          }}
                        >
                          {c}
                        </p>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          </Paper>
        );
      })}
    </div>
  );
}

QuestionsV2.propTypes = {
  questionSets: PropTypes.object.isRequired,
  selectedQuestions: PropTypes.array.isRequired,
  isSmallAndMediumScreen: PropTypes.bool.isRequired,
  setSelectedQuestions: PropTypes.func.isRequired,
};

export default QuestionsV2;
