/* eslint-disable react/prop-types */

import DefaultNavbar from "customComponents/DefaultNavbar";

const Navbar = ({ brand, routes, brandLink, action }) => {
  return (
    <DefaultNavbar routes={routes} brand={brand} brandLink={brandLink} sticky action={action} />
  );
};

export default Navbar;
