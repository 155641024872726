import { Alert, Container, TextField, Typography } from "@mui/material";
import { requiredVariables } from "assets/static/mailTemplates";
import { defaultVariables } from "lib/utilities/templates";
import RichTextEditor from "./RichTextEditor";

const MailTemplatesForm = ({
  templates,
  handleTemplateChange,
  setTemplatesValid,
  missingVariables,
  setMissingVariables,
  setStrExists,
  strExists
}) => {
  const checkTemplateVariables = (templateId, content) => {
    const required = requiredVariables[templateId];
    const missing = required.filter((variable) => !content.includes(`{${variable}}`));
    setMissingVariables((prev) => ({
      ...prev,
      [templateId]: missing,
    }));

    const defaultStr = defaultVariables[templateId];
    const exists = defaultStr.filter((str) => content.includes(str));
    setStrExists((prev) => ({
      ...prev,
      [templateId]: exists,
    }));

    setTemplatesValid((!Object.values(missingVariables).some((v) => v.length > 0)) && (!Object.values(strExists).some((s) => s.length > 0)));
  };

  const handleTemplateChangeAndValidate = (event) => {
    const { id, value } = event.target;
    handleTemplateChange(event);
    checkTemplateVariables(id, value);
  };

  return (
    <Container>
      <Typography variant="h4" mt={2}>
        Mail Template Form
      </Typography>
      <Typography variant="caption">
        There are a few variables which should be included in the template to provide details about
        the interview process to the candidates. You may include these variables in the templates by
        enclosing them within curly braces preceded with a dollar sign. <br /> For example, to
        include the name of the candidate, you can use the variable <code>{`\${name}`}</code>.
      </Typography>

      {/* Send Assignment Template */}
      <Typography variant="h5" mt={1}>
        Send Assignment Mail Template
      </Typography>
      <Typography variant="caption" gutterBottom>
        Available variables in this template are:{" "}
        <code>
          {requiredVariables.sendAssignmentTemplate.map((v) => "${" + v + "}").join(", ")}
        </code>
      </Typography>
      <TextField
        id="sendAssignmentSubject"
        label="Send Assignment Subject"
        fullWidth
        variant="outlined"
        value={templates.sendAssignmentSubject}
        onChange={handleTemplateChange}
        sx={{ mt: 1 }}
      />
      <RichTextEditor
        id="sendAssignmentTemplate"
        label="Send Assignment Template"
        value={templates.sendAssignmentTemplate}
        onChange={handleTemplateChangeAndValidate}
        placeholder="Provide the send assignment mail template here..."
        style={{ marginTop: "10px" }}
      />
      {missingVariables?.sendAssignmentTemplate?.length > 0 && (
        <Alert severity="warning" sx={{ mt: 2 }}>
          Missing variable{missingVariables.sendAssignmentTemplate.length > 1 && "s"}:{" "}
          {missingVariables.sendAssignmentTemplate.join(", ")}
        </Alert>
      )}
      {strExists?.sendAssignmentTemplate?.length > 0 && (
        <Alert severity="warning" sx={{ mt: 2 }}>
          Missing Essential Information{strExists.sendAssignmentTemplate.length > 1 && "s"}:{" "}
          {strExists.sendAssignmentTemplate.join(", ")}
        </Alert>
      )}

      {/* Interview Schedule Template */}
      <Typography variant="h5" gutterBottom mt={2}>
        Interview Schedule Mail Template
      </Typography>
      <Typography variant="caption" gutterBottom>
        Available variables in this template are:{" "}
        <code>
          {requiredVariables.interviewScheduleTemplate.map((v) => "${" + v + "}").join(", ")}
        </code>
      </Typography>
      <TextField
        id="interviewScheduleSubject"
        label="Interview Schedule Subject"
        fullWidth
        variant="outlined"
        value={templates.interviewScheduleSubject}
        onChange={handleTemplateChange}
        sx={{ mt: 2 }}
      />
      <RichTextEditor
        id="interviewScheduleTemplate"
        label="Interview Schedule Template"
        value={templates.interviewScheduleTemplate}
        onChange={handleTemplateChangeAndValidate}
        placeholder="Provide the interview schedule mail template here..."
        style={{ marginTop: "10px" }}
      />
      {missingVariables?.interviewScheduleTemplate?.length > 0 && (
        <Alert severity="warning" sx={{ mt: 2 }}>
          Missing variable{missingVariables.interviewScheduleTemplate.length > 1 && "s"}:{" "}
          {missingVariables.interviewScheduleTemplate.join(", ")}
        </Alert>
      )}
      {strExists?.interviewScheduleTemplate?.length > 0 && (
        <Alert severity="warning" sx={{ mt: 2 }}>
          Missing Essential Information{strExists.interviewScheduleTemplate.length > 1 && "s"}:{" "}
          {strExists.interviewScheduleTemplate.join(", ")}
        </Alert>
      )}

      {/* Interview Invite Template */}
      <Typography variant="h5" gutterBottom mt={2}>
        Interview Invite Mail Template
      </Typography>
      <Typography variant="caption" gutterBottom>
        Available variables in this template are:{" "}
        <code>
          {requiredVariables.interviewInviteTemplate.map((v) => "${" + v + "}").join(", ")}
        </code>
      </Typography>
      <TextField
        id="interviewInviteSubject"
        label="Interview Invite Subject"
        fullWidth
        variant="outlined"
        value={templates.interviewInviteSubject}
        onChange={handleTemplateChange}
        sx={{ mt: 2 }}
      />
      <RichTextEditor
        id="interviewInviteTemplate"
        label="Interview Invite Template"
        value={templates.interviewInviteTemplate}
        onChange={handleTemplateChangeAndValidate}
        placeholder="Provide the interview invite mail template here..."
        style={{ marginTop: "10px" }}
      />
      {missingVariables?.interviewInviteTemplate?.length > 0 && (
        <Alert severity="warning" sx={{ mt: 2 }}>
          Missing variable{missingVariables.interviewInviteTemplate.length > 1 && "s"}:{" "}
          {missingVariables.interviewInviteTemplate.join(", ")}
        </Alert>
      )}
      {strExists?.interviewInviteTemplate?.length > 0 && (
        <Alert severity="warning" sx={{ mt: 2 }}>
          Missing Essential Information{strExists.interviewInviteTemplate.length > 1 && "s"}:{" "}
          {strExists.interviewInviteTemplate.join(", ")}
        </Alert>
      )}

    </Container>
  );
};

export default MailTemplatesForm;
