import { useParams } from "react-router-dom";
import AdminSignup from "../../../../pages/Admin/Signup/index";

function AdminSignupPage() {
  let { inviteId } = useParams();
  return (
    <div>
      <AdminSignup inviteId={inviteId} />
    </div>
  );
}

export default AdminSignupPage;
