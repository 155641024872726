/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router components
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

// @mui material components
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";

// Material Kit 2 React routes
import ConfigWrapper from "customComponents/ConfigWrapper";
import AdminLayout from "layouts/pages/admin";
import AdminSignupPage from "layouts/pages/admin/signup";
import ForgotPasswordPage from "layouts/pages/authentication/forgot-password";
import SignInPage from "layouts/pages/authentication/sign-in";
import SignUpPage from "layouts/pages/authentication/sign-up";
import CookiePolicyPage from "layouts/pages/landing-pages/cookie-policy";
import DataPrivacyPolicyPage from "layouts/pages/landing-pages/data-privacy-policy";
import DataRetentionPolicyPage from "layouts/pages/landing-pages/data-retention-policy";
import DataSubjectRightsPolicyPage from "layouts/pages/landing-pages/data-subject-rights-policy";
import SuperAdminLayout from "layouts/pages/super-admin";
import AdminHome from "pages/Admin/AdminHome";
import CandidatePage from "pages/Admin/Candidate";
import AdminDashboard from "pages/Admin/Dashboard";
import FormPage from "pages/Admin/FormPage";
import { AuthProvider } from "provider/AuthProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import routes from "webRoutes";
import "./global.css";
import SuperDashboard from "./pages/SuperAdmin/Dashboard/index";
import ApproveCustomSkills from "pages/SuperAdmin/ApproveCustomSkills";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.route} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <CssBaseline />
        <ToastContainer
          position="bottom-right"
          autoClose={4000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
          theme="light"
          limit={2}
        />
        <Routes>
          {getRoutes(routes)}
          <Route path="/signin/:origin?" element={<ConfigWrapper Component={<SignInPage />} />} />
          <Route path="/signup/:origin?" element={<ConfigWrapper Component={<SignUpPage />} />} />
          <Route
            path="/forgot-password/:origin?"
            element={<ConfigWrapper Component={<ForgotPasswordPage />} />}
          />
          <Route
            path="/techyrr-admin/signup/:origin?"
            element={<ConfigWrapper Component={<SignUpPage techyrrAdmin={true} />} />}
          />
          <Route
            path="/techyrr-admin/signin/:origin?"
            element={<ConfigWrapper Component={<SignInPage techyrrAdmin={true} />} />}
          />
          <Route
            path="/techyrr-admin/forgot-password/:origin?"
            element={<ConfigWrapper Component={<ForgotPasswordPage techyrrAdmin={true} />} />}
          />
          <Route path="/" element={<Presentation />} />
          <Route path="*" element={<Navigate to="/presentation" />} />
          <Route path="/admin/signup/:inviteId" element={<AdminSignupPage />} />
          <Route
            path="/super-admin/dashboard"
            element={<SuperAdminLayout component={<SuperDashboard />} />}
          />
          <Route
            path="/super-admin/approve-custom-skills/:companyId/:formId"
            element={<SuperAdminLayout component={<ApproveCustomSkills />} />}
          />
          <Route path="/admin/dashboard" element={<AdminLayout component={<AdminDashboard />} />} />
          <Route path="/admin" element={<AdminLayout component={<AdminHome />} />} />
          <Route path="/admin/form/:formId" element={<AdminLayout component={<FormPage />} />} />
          <Route
            path="/admin/form/:formId/candidate/:candidateId"
            element={<AdminLayout component={<CandidatePage />} />}
          />
          <Route path="/cookie-policy" element={<CookiePolicyPage />} />;
          <Route path="/data-privacy-policy" element={<DataPrivacyPolicyPage />} />
          <Route path="/data-retention-policy" element={<DataRetentionPolicyPage />} />
          <Route path="/data-subject-rights-policy" element={<DataSubjectRightsPolicyPage />} />
        </Routes>
      </AuthProvider>
    </ThemeProvider>
    
  );
}
