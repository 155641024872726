/* eslint-disable react/prop-types */
/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useState } from "react";

// react-router-dom components
import { useNavigate, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// import MuiLink from "@mui/material/Link";

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";

// Material Kit 2 React example components

// Material Kit 2 React page layout routes

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { constants } from "lib/utilities/Auth";
import { AuthContext } from "provider/AuthProvider";
import { toast } from "react-toastify";
import { z } from "zod";

function ForgotPasswordBasic({ techyrrAdmin }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState({
    address: "",
    sending: false,
    sent: false,
    error: false,
    errorCode: "",
  });
  const { resetpassword } = useContext(AuthContext);
  const { errorCodes } = constants;
  const { origin } = useParams();

  const onChange = (e) => {
    setEmail({ ...email, address: e.target.value });
  };

  const resetPasswordSchema = z.object({
    email: z.string().email(),
  });

  const handleResetPassword = () => {
    const validationResult = resetPasswordSchema.safeParse({ email: email.address });
    if (validationResult.success) {
      resetpassword(email.address, setEmail).catch((error) => {
        toast.error(
          <p style={{ fontSize: "16px" }}>
            {errorCodes.firebase[error.code] || error.errorInfo?.message || "Try Again"}
          </p>
        );
      });
    } else {
      toast.error(
        <p style={{ fontSize: "16px" }}>
          {validationResult.error.errors[0].message || "Try Again"}
        </p>
      );
    }
  };

  const handleGotoSignIn = () => {
    if (origin) {
      if (techyrrAdmin) {
        navigate(`/techyrr-admin/signin/${origin}`);
      } else {
        navigate(`/signin/${origin}`);
      }
    } else {
      navigate(`/signin`);
    }
  };

  return (
    <>
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox
        px={1}
        pt={10}
        pb={5}
        width="100%"
        height="100vh"
        mx="auto"
        position="relative"
        zIndex={2}
      >
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
          sx={{
            overflowY: "scroll",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
          pt={2}
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white">
                  Forgot Password?
                </MKTypography>
                {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                  <Grid item xs={2}>
                  <MKTypography component={MuiLink} href="#" variant="body1" color="white">
                  <FacebookIcon color="inherit" />
                  </MKTypography>
                  </Grid>
                  <Grid item xs={2}>
                  <MKTypography component={MuiLink} href="#" variant="body1" color="white">
                  <GitHubIcon color="inherit" />
                  </MKTypography>
                  </Grid>
                  <Grid item xs={2}>
                  <MKTypography component={MuiLink} href="#" variant="body1" color="white">
                  <GoogleIcon color="inherit" />
                  </MKTypography>
                  </Grid>
                </Grid> */}
              </MKBox>
              <MKBox pt={1} pb={3} px={3}>
                {!email.sent && (
                  <>
                    <MKTypography mb={2} sx={{ fontSize: "16px" }}>
                      Enter your email to get a password reset link.
                    </MKTypography>
                    <MKBox component="form" role="form">
                      <MKBox mb={2}>
                        <MKInput
                          name="email"
                          type="email"
                          label="Email"
                          value={email.address}
                          onChange={onChange}
                          fullWidth
                        />
                      </MKBox>
                      <MKBox mt={4} mb={1}>
                        <MKButton
                          onClick={handleResetPassword}
                          variant="gradient"
                          color="info"
                          fullWidth
                        >
                          {email.sending ? "Sending Email..." : "Send Email"}
                        </MKButton>
                      </MKBox>
                    </MKBox>
                  </>
                )}
                {email.sent && (
                  <MKTypography sx={{ fontSize: "16px" }}>
                    An email has been sent to {email.address} with further instructions.
                  </MKTypography>
                )}
                <MKBox mt={3} mb={1} textAlign="center">
                  <MKTypography
                    onClick={handleGotoSignIn}
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                    sx={{ cursor: "pointer" }}
                  >
                    ← Back to Signin
                  </MKTypography>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
}

export default ForgotPasswordBasic;
