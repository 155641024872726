import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PropTypes from "prop-types";

function RecommendedQuestions({ data }) {
  // console.log(data);
  return (
    <div
      style={{
        height: "max-content",
        padding: "40px 100px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        backgroundColor: "white",
        marginBottom: "4rem",
        width: "80%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CheckCircleIcon
          fontSize="large"
          style={{
            color: "#228B22",
            marginBottom: "10px",
          }}
        />
        <h3
          style={{
            textAlign: "center",
            color: "#333",
            marginBottom: "10px",
          }}
        >
          The questions have been selected
        </h3>
      </div>
      <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap", flexDirection: "column" }}>
        {data?.map((q) => (
          <p
            key={q}
            style={{
              backgroundColor: "#e0f7fa",
              borderRadius: "5px",
              padding: "5px 10px",
              fontSize: "0.7em",
              color: "#00796b",
            }}
          >
            {q.question}
          </p>
        ))}
      </div>
    </div>
  );
}

RecommendedQuestions.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      concepts: PropTypes.arrayOf(PropTypes.string).isRequired,
      example: PropTypes.string.isRequired,
      fileName: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
      unimplementedFeature: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default RecommendedQuestions;
