import FaceIcon from "@mui/icons-material/Face";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import { Divider } from "@mui/material";
import PropTypes from "prop-types";

function CodingHint({ data }) {
  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "20px 0",
      }}
    >
      {data?.map((item, index) => (
        <div
          key={index}
          style={{
            backgroundColor: "white",
            padding: "20px",
            marginBottom: "16px",
            border: "1px solid #ddd",
            borderRadius: "10px",
            width: "100%",
            maxWidth: "600px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "0.5rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "5%",
                  justifyContent: "center",
                  paddingTop: "3px",
                }}
              >
                <FaceIcon style={{ color: "#017bfe" }} />
              </div>
              <p
                style={{
                  fontSize: "0.9rem",
                  color: "#555",
                  width: "100%",
                }}
              >
                {item?.query}
              </p>
            </div>
            <Divider style={{ margin: "0.5rem" }} />{" "}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "0.5rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "5%",
                  justifyContent: "center",
                  paddingTop: "3px",
                }}
              >
                <SmartToyIcon style={{ color: "#017bfe" }} />
              </div>
              <p
                style={{
                  fontSize: "0.9rem",
                  color: "#555",
                  width: "100%",
                }}
              >
                {item?.hint}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

CodingHint.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      hint: PropTypes.string.isRequired,
      query: PropTypes.string,
    })
  ).isRequired,
};

export default CodingHint;
