/* eslint-disable react/prop-types */
import DefaultNavbar from "customComponents/DefaultNavbar";
import { useParams } from "react-router-dom";
import routes from "webRoutes";
import configFooter from "./configFooter";
import configNavbar from "./configNavbar";

const ConfigWrapper = ({ Component }) => {
  const { origin } = useParams();
  const Footer = configFooter(origin || "default");
  const Navbar = configNavbar(origin || "default");
  return (
    <>
      {!origin && (
        <DefaultNavbar
          routes={routes}
          brandLink={"/"}
          action={{
            type: "internal",
            route: "/pages/landing-pages/contact-us",
            label: "Contact Us",
            color: "info",
          }}
          sticky
        />
      )}
      {Navbar && <Navbar />}
      {Component}
      {Footer && <Footer />}
    </>
  );
};

export default ConfigWrapper;
