// @mui material components
import BarChartIcon from "@mui/icons-material/BarChart";
import PsychologyIcon from "@mui/icons-material/Psychology";
import SpeedIcon from "@mui/icons-material/Speed";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import bgImage from "assets/images/bg_hyrrstream.webp";
import bgImageProfessionals from "assets/images/bg_professionals.webp";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import DefaultFooter from "examples/Footers/DefaultFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import footerRoutes from "footer.routes";
import routes from "webRoutes";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function HyrrStream() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/pages/landing-pages/contact-us",
          label: "Contact Us",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              style={textStyles}
            >
              HyrrStream{" "}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              style={textStyles}
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Revolutionize Your Recruitment: Precision-Fit Talent
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" py={{ xs: 3, md: 12 }}>
          <Container>
            <Grid container spacing={3} sx={{ mb: 10 }}>
              <Grid item xs={12} lg={5}>
                <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
                  <MKTypography variant="h3" my={1}>
                    Why HyrrStream?
                  </MKTypography>
                  <MKTypography variant="body2" color="text" mb={2}>
                    HyrrStream is a cutting-edge Data as a Service (DaaS) platform leveraging AI and
                    machine learning for precision in matching candidates to job openings. It
                    revolutionizes recruitment by enhancing hiring accuracy and efficiency, ensuring
                    a perfect candidate-job fit. This platform addresses the modern recruitment
                    challenges, making it an essential tool for businesses aiming to streamline
                    their hiring process in today&apos;s fast-paced job market
                  </MKTypography>
                </MKBox>
                {/* <MKTypography
                  component="a"
                  href="#"
                  variant="body2"
                  color="info"
                  fontWeight="regular"
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",

                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transform: "translateX(3px)",
                      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: "translateX(6px)",
                    },
                  }}
                >
                  More about us
                  <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography> */}
              </Grid>
              <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
                <Stack>
                  <MKBox display="flex" alignItems="center" p={2}>
                    <MKBox
                      width="3rem"
                      height="3rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      {/* <Icon fontSize="small">mediation</Icon> */}
                      <PsychologyIcon />
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      <MKTypography variant="h6">AI-Driven Precision:</MKTypography>
                      Utilize advanced AI algorithms for unmatched accuracy in candidate-job fit.
                    </MKTypography>
                  </MKBox>
                  <MKBox display="flex" alignItems="center" p={2}>
                    <MKBox
                      width="3rem"
                      height="3rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <BarChartIcon />
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      <MKTypography variant="h6">Data Democratization:</MKTypography>
                      Access comprehensive, democratized candidate data that simplifies
                      decision-making
                    </MKTypography>
                  </MKBox>
                  <MKBox display="flex" alignItems="center" p={2}>
                    <MKBox
                      width="3rem"
                      height="3rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <SpeedIcon />
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      <MKTypography variant="h6">Efficiency & Speed:</MKTypography>
                      Accelerate the hiring process with real-time data and automation.
                    </MKTypography>
                  </MKBox>
                </Stack>
              </Grid>
            </Grid>
            <Grid item>
              <MKBox display="flex" alignItems="center" justifyContent="center" p={2}>
                <MKTypography variant="h3">Features</MKTypography>
              </MKBox>
            </Grid>
            <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <FilledInfoCard
                    icon="join_inner"
                    title="AI-Powered Matching"
                    description="HyrrStream utilizes advanced artificial intelligence to deeply analyze job requirements and candidate profiles, ensuring a highly accurate match. This innovative approach not only elevates the quality of hires but also significantly reduces the time to fill positions, revolutionizing the recruitment process."
                    // action={{
                    //   type: "internal",
                    //   route: "/pages/landing-pages/eval-ai",
                    //   label: "Read more",
                    // }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FilledInfoCard
                    icon="integration_instructions"
                    title="Seamless Integration"
                    description="The platform is designed for effortless integration with existing HR systems, minimizing disruption and facilitating a smoother workflow. HyrrStream's compatibility with a wide range of HR technologies ensures that companies can leverage its powerful features without overhauling their current processes."
                    // action={{
                    //   type: "internal",
                    //   route: "/test",
                    //   label: "Read more",
                    // }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
                <Grid item xs={12} md={6}>
                  <FilledInfoCard
                    icon="shield"
                    title="Data Security"
                    description="With a strong commitment to data security, HyrrStream implements robust encryption and compliance measures to protect sensitive information. This focus on security not only safeguards company and candidate data but also builds trust, making it a reliable choice for businesses in any sector."
                    // action={{
                    //   type: "internal",
                    //   route: "/test",
                    //   label: "Read more",
                    // }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FilledInfoCard
                    icon="stars"
                    title="Role-specific Data"
                    description="Receive personalized recommendations and precisions for each individual's suitability for specific roles within your organization based on their cognitive, behavioral, and personality profiles."
                    // action={{
                    //   type: "internal",
                    //   route: "/test",
                    //   label: "Read more",
                    // }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <MKBox display="flex" alignItems="center" justifyContent="center" p={2}>
                <MKTypography variant="h3">Unlock Human Potential with HyrrStream</MKTypography>
              </MKBox>
              <MKBox display="flex" alignItems="center" justifyContent="center" pb={2}>
                <MKTypography variant="body1">
                  Ready to harness the power of neuroscience to build stronger, more effective
                  teams? Sign up for HyrrStream today and revolutionize the way you understand and
                  optimize human potential.
                </MKTypography>
              </MKBox>
            </Grid>
            <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <MKBox
                    display="flex"
                    alignItems="center"
                    borderRadius="xl"
                    my={2}
                    py={6}
                    sx={{
                      backgroundImage: ({
                        functions: { linearGradient, rgba },
                        palette: { gradients },
                      }) =>
                        `${linearGradient(
                          rgba(gradients.dark.main, 0.8),
                          rgba(gradients.dark.state, 0.8)
                        )}, url(${bgImageProfessionals})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <Container>
                      <Grid container item xs={12} lg={10} sx={{ ml: { xs: 0, lg: 6 } }}>
                        <MKTypography variant="h4" color="white" fontWeight="bold">
                          For Organizations
                        </MKTypography>
                        <MKTypography variant="body1" color="white" opacity={0.8} mb={2}>
                          Drive organizational success by making data-driven decisions in hiring,
                          training, and talent development with HyrrStream.
                        </MKTypography>
                      </Grid>
                    </Container>
                  </MKBox>
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <MKBox
                    display="flex"
                    alignItems="center"
                    borderRadius="xl"
                    my={2}
                    py={6}
                    sx={{
                      backgroundImage: ({
                        functions: { linearGradient, rgba },
                        palette: { gradients },
                      }) =>
                        `${linearGradient(
                          rgba(gradients.dark.main, 0.8),
                          rgba(gradients.dark.state, 0.8)
                        )}, url(${bgImageStudents})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <Container>
                      <Grid container item xs={12} lg={10} sx={{ ml: { xs: 0, lg: 6 } }}>
                        <MKTypography variant="h4" color="white" fontWeight="bold">
                          For Students
                        </MKTypography>
                        <MKTypography variant="body1" color="white" opacity={0.8} mb={2}>
                          Showcase your skills and expertise in a fair and unbiased environment.
                          Take your career to new heights with EvalAI.
                        </MKTypography>
                      </Grid>
                    </Container>
                  </MKBox>
                </Grid> */}
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={4} px={1} mt={4}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

const textStyles = { textShadow: "2px 2px 4px #000000", color: "##C0C0C0" };

export default HyrrStream;
