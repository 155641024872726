/* eslint-disable react/prop-types */
import PixSharpIcon from "@mui/icons-material/PixSharp";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import AddExcelModal from "customComponents/Modals/AddExcelModal";
import FormDraftsModal from "customComponents/Modals/FormDraftsModal";
import JobFormModal from "customComponents/Modals/JobFormModal";
import { BASE_URL } from "lib/utilities/globalConstants";
import { tokenResult } from "lib/utilities/tokenResult";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { UserAuth } from "provider/AuthProvider";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const AdminDashboard = () => {
  const { user } = UserAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    pageIndex: parseInt(searchParams.get("pageIndex")) || 0,
    pageSize: parseInt(searchParams.get("pageSize")) || 5,
  });
  const [globalFilter, setGlobalFilter] = useState(searchParams.get("globalFilter") || "");
  const [sorting, setSorting] = useState(
    searchParams.get("sorting")
      ? JSON.parse(searchParams.get("sorting"))
      : [{ id: "created_at", desc: true }]
  );
  const [columnFilters, setColumnFilters] = useState(
    searchParams.get("columnFilters") ? JSON.parse(searchParams.get("columnFilters")) : []
  );
  const [createdAtFilter, setCreatedAtFilter] = useState(
    searchParams.get("createdAtFilter")
      ? JSON.parse(searchParams.get("createdAtFilter"))
      : { startDate: null, endDate: null }
  );
  const [isOpening, setIsOpening] = useState(false);
  const companyName = user?.email.split("@")[1].split(".")[0];

  useEffect(() => {
    const params = {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      globalFilter: globalFilter,
      sorting: JSON.stringify(sorting),
      columnFilters: JSON.stringify(columnFilters),
      createdAtFilter: JSON.stringify(createdAtFilter),
    };
    setSearchParams(params, { replace: true });
  }, [pagination, globalFilter, sorting, columnFilters, createdAtFilter]);

  const getAllForms = async () => {
    const idTokenResult = await tokenResult(user);
    if (idTokenResult) {
      const res = await fetch(
        `${BASE_URL}admin/getAllForms/${companyName}?page=${pagination.pageIndex + 1}&pageSize=${
          pagination.pageSize
        }&columnFilters=${JSON.stringify(columnFilters ?? [])}&globalFilter=${
          globalFilter ?? ""
        }&sorting=${JSON.stringify(sorting ?? [])}&createdAt=${JSON.stringify(
          createdAtFilter ? createdAtFilter : null
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idTokenResult.token}`,
          },
        }
      );
      const data = await res.json();
      return data;
    }
  };

  const { data, isRefetching, isLoading, isError } = useQuery({
    queryKey: [
      "getAllForms",
      pagination,
      columnFilters,
      sorting,
      globalFilter,
      companyName,
      createdAtFilter,
    ],
    queryFn: getAllForms,
    placeholderData: keepPreviousData,
    enabled: !!user,
    refetchOnWindowFocus: false,
  });

  const columns = [
    {
      accessorKey: "adminName",
      header: "Admin Name",
      size: 180,
      muiTableBodyCellProps: {
        sx: {
          display: "flex",
          justifyContent: "center",
        },
      },
    },
    {
      accessorKey: "created_at",
      header: "Created On",
      size: 180,
      Cell: ({ cell }) => cell.getValue().split("T")[0],
      muiTableBodyCellProps: {
        sx: {
          display: "flex",
          justifyContent: "center",
        },
      },
      Filter: () => {
        return (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.7rem", marginTop: "0.2rem" }}
          >
            <input
              style={{
                fontWeight: "200",
                border: "1px solid gray",
                padding: "5px 10px 5px",
                backgroundColor: "white",
                cursor: "pointer",
                borderRadius: "5px",
                outlineColor: "#006566",
              }}
              type="text"
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
              value={createdAtFilter.startDate ?? ""}
              onChange={(e) => {
                return setCreatedAtFilter({ ...createdAtFilter, startDate: e.target.value });
              }}
              placeholder="select start date"
            />
            <input
              style={{
                fontWeight: "200",
                border: "1px solid gray",
                padding: "5px 10px 5px",
                backgroundColor: "white",
                cursor: "pointer",
                borderRadius: "5px",
                outlineColor: "#006566",
              }}
              type="text"
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
              value={createdAtFilter.endDate ?? ""}
              onChange={(e) => {
                // console.log(e.target.value);
                return setCreatedAtFilter({ ...createdAtFilter, endDate: e.target.value });
              }}
              placeholder="select end date"
            />
          </div>
        );
      },
    },
    {
      accessorKey: "experience",
      header: "Experience(yrs)",
      size: 200,
      muiTableBodyCellProps: {
        sx: {
          display: "flex",
          justifyContent: "center",
        },
      },
    },
    { accessorKey: "jobRole", header: "Job Role", size: 150 },
    {
      accessorKey: "languages",
      header: "Languages",
      Cell: ({ cell }) => {
        let lang = cell.getValue().map((l) => l.programming);
        return lang.join(", ");
      },
      size: 200,
      enableSorting: false,
    },
    {
      accessorKey: "_count.candidate_form_mapping",
      enableColumnFilter: false,
      header: "No. Of Candidates",
      size: 220,
      muiTableBodyCellProps: {
        sx: {
          display: "flex",
          justifyContent: "center",
        },
      },
    },
    {
      accessorKey: "addCandidates",
      header: "Add Candidates",
      Cell: ({ row }) => {
        const langIds = row.original.languages.map((lang) => lang.language_id);
        const exp = row.original.experience.split("-")[1];
        if (langIds.length > 0 && exp) {
          return (
            <AddExcelModal
              formId={row.original.id}
              setIsOpening={setIsOpening}
              langIds={langIds}
              exp={exp}
            />
          );
        }
      },
      muiTableBodyCellProps: {
        sx: {
          display: "flex",
          justifyContent: "center",
        },
      },
      size: 200,
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data: data?.company?.forms || [],
    initialState: { showColumnFilters: false },
    enableColumnResizing: true,
    onColumnFiltersChange: setColumnFilters,
    manualFiltering: true,
    onGlobalFilterChange: setGlobalFilter,
    enableGlobalFilter: false,
    manualSorting: true,
    onSortingChange: setSorting,
    manualPagination: true,
    onPaginationChange: setPagination,
    enablePagination: true,
    rowCount: data?.numberOfForms || 0,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    state: {
      pagination,
      showProgressBars: isRefetching,
      showAlertBanner: isError,
      isLoading,
      sorting,
      columnFilters,
      globalFilter,
    },
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 20],
      showFirstButton: false,
      showLastButton: false,
    },
    enableSubRowSelection: true,
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        if (isOpening) return {};
        navigate(`/admin/form/${row.id}`);
      },
      sx: { cursor: "pointer" },
    }),
  });

  if (isLoading) return <></>;

  if (isError) return <div>Error fetching forms</div>;

  return (
    <div style={{ backgroundColor: "#ebebeb" }}>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "15px",
            marginBottom: "15px",
            padding: "0% 7% 0%",
          }}
        >
          <div
            style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px" }}
          >
            <FormDraftsModal />
            <h1
              style={{
                fontSize: "1rem",
                fontWeight: "400",
                backgroundColor: "white",
                border: "2px solid #006566",
                padding: "2px 15px 2px",
                borderRadius: "5px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                textAlign: "center",
              }}
            >
              {" "}
              NUMBER OF FORMS: {data?.numberOfForms}
            </h1>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "400",
              gap: "1.3rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "400",
                gap: "2px",
              }}
            >
              {data?.numberOfCoins}
              <PixSharpIcon style={{ color: "yellowgreen" }} />
            </div>
            <JobFormModal />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#ebebeb",
          }}
        >
          <div style={{ width: "90%" }}>
            <MaterialReactTable table={table} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;