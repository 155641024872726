import { auth } from "lib/firebase/firebase";
import { BASE_URL } from "./globalConstants";

export const checkCompany = async (company) => {
  try {
    const res = await fetch(`${BASE_URL}techyrr/check-company`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        company: company,
      }),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const addCompany = async (company) => {
  try {
    await fetch(`${BASE_URL}techyrr-admin/add-company`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
      },
      body: JSON.stringify({
        company: company,
      }),
    });
  } catch (error) {
    console.error("error:", error);
  }
};
