import { useMediaQuery } from "@mui/material";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

const CustomTechyrrFooter = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  return (
    <MKBox
      position="absolute"
      bgColor="rgba(0, 0, 0, 0)"
      color="white"
      display="flex"
      width="100%"
      pb={1}
      bottom={0}
      boxShadow={1}
      zIndex={50}
    >
      <MKBox
        display="flex"
        justifyContent={`${isSmallScreen ? "center" : "space-between"}`}
        alignItems="center"
        width="100%"
        mx="auto"
        px={4}
        maxWidth="xxl"
        sx={{
          borderTop: "1px solid #777777",
          paddingTop: "7px",
          height: "40px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <MKTypography
          variant="body2"
          color="white"
          sx={{
            fontSize: "14px",
          }}
        >
          Copyright &copy; {new Date().getFullYear()}{" "}
          <a
            href="https://techyrr.com/"
            target="_blank"
            rel="noreferrer"
            style={{ color: "white" }}
          >
            Techyrr™
          </a>
          . All Rights Reserved.
        </MKTypography>
        <MKBox
          display={isSmallScreen ? "none" : "flex"}
          flexWrap="wrap"
          alignItems="center"
          mt={1}
          mb={0}
          p={0}
          m={0}
        >
          <MKButton
            variant="text"
            color="white"
            href="https://techyrr.com/policies/services"
            target="_blank"
            size="small"
            sx={{
              cursor: "pointer",
              "&:hover": {
                background: "#444444",
              },
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            Services
          </MKButton>
          <MKButton
            variant="text"
            color="white"
            href="https://techyrr.com/policies/refund-policy"
            target="_blank"
            size="small"
            sx={{
              cursor: "pointer",
              "&:hover": {
                background: "#444444",
              },
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            Refund Policy
          </MKButton>
          <MKButton
            variant="text"
            color="white"
            href="https://techyrr.com/policies/privacy-policy"
            target="_blank"
            size="small"
            sx={{
              cursor: "pointer",
              "&:hover": {
                background: "#444444",
              },
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            Privacy Policy
          </MKButton>
          <MKButton
            variant="text"
            color="white"
            href="https://techyrr.com/policies/terms-and-conditions"
            target="_blank"
            size="small"
            sx={{
              cursor: "pointer",
              "&:hover": {
                background: "#444444",
              },
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            Terms and Conditions
          </MKButton>
          <MKButton
            variant="text"
            color="white"
            href="mailto:contact@techyrr.com"
            size="small"
            sx={{
              cursor: "pointer",
              "&:hover": {
                background: "#444444",
              },
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            Contact Us
          </MKButton>
        </MKBox>
      </MKBox>
    </MKBox>
  );
};

export default CustomTechyrrFooter;
