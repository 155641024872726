import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const toolbarOptions = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ["bold", "italic", "underline", "strike"], 
  [{ color: [] }, { background: [] }],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ align: [] }],
  [{ indent: "-1" }, { indent: "+1" }],
  ["link", "image", "video"],
  ["blockquote", "code-block"],
  [{ script: "sub" }, { script: "super" }],
  [{ direction: "rtl" }],
  ["clean"],
];

const RichTextEditor = ({ id, value, onChange, ...params }) => {
  const handleChange = (content) => {
    console.log(content);
    onChange({ target: { id, value: content } });
  };

  return (
    <ReactQuill
      value={value}
      onChange={handleChange}
      theme={"snow"}
      {...params}
      placeholder="Start typing here..."
      modules={{
        toolbar: toolbarOptions,
      }}
      formats={[
        "size",
        "font",
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "color",
        "background",
        "list",
        "bullet",
        "align",
        "indent",
        "link",
        "image",
        "video",
        "blockquote",
        "code-block",
        "script",
        "direction",
        "table",
      ]}
    />
  );
};

export default RichTextEditor;
