/* eslint-disable no-unused-vars */
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import { BASE_URL } from "lib/utilities/globalConstants";
import PropTypes from "prop-types";
import { UserAuth } from "provider/AuthProvider";
import * as React from "react";
import { toast } from "react-toastify";

function DeleteDraftModal({ formId, setIsDeleting }) {
  const [open, setOpen] = React.useState(false);
  const { user } = UserAuth();
  const queryClient = useQueryClient();

  async function onDeleteDraft() {
    if (!user || !formId) return;
    const result = await fetch(`${BASE_URL}admin/deleteDraft`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.accessToken}`,
      },
      body: JSON.stringify({
        formId: formId,
      }),
    });
    const data = await result.json();
    toast.success(data.message);
    queryClient.invalidateQueries(["getJobFormDrafts"]);
    handleClose();
    setIsDeleting(false);
  }

  const handleOpen = (e) => {
    e.stopPropagation();
    setIsDeleting(true);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsDeleting(false);
  };

  return (
    <div>
      <Tooltip title="Delete Draft" placement="right">
        <DeleteIcon
          style={{
            color: "#FF2400",
            cursor: "pointer",
          }}
          onClick={handleOpen}
          fontSize="medium"
        />
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "#fff",
            p: 2,
            borderRadius: 2,
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            style={{ marginBottom: "16px", fontWeight: "bold", textAlign: "center" }}
          >
            Do you want to delete this draft?
          </Typography>
          <div style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}>
            <Button
              onClick={handleClose}
              style={{
                backgroundColor: "#f0f0f0",
                color: "#000",
                borderRadius: "4px",
              }}
            >
              No
            </Button>
            <Button
              onClick={onDeleteDraft}
              style={{
                backgroundColor: "#FF2400",
                color: "#fff",
                borderRadius: "4px",
                textTransform: "none",
              }}
            >
              Yes
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
DeleteDraftModal.propTypes = {
  formId: PropTypes.string.isRequired,
  setIsDeleting: PropTypes.func.isRequired,
};

export default DeleteDraftModal;