/* eslint-disable react/prop-types */
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const Footer = ({ brand }) => {
  return (
    <MKBox
      position="absolute"
      bgColor="rgba(0, 0, 0, 0)"
      color="white"
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      py={2}
      bottom={0}
      boxShadow={1}
      zIndex={1}
    >
      <MKTypography variant="body2" color="light">
        Copyright © {new Date().getFullYear()} {brand}
      </MKTypography>
    </MKBox>
  );
};

export default Footer;
