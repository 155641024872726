import PropTypes from "prop-types";
import CodingHint from "./CodingHint";
import GitDiff from "./GitDiff";
import RatingCard from "./Ratingcard";

function AttemptDetails({ attempt, attemptIndex }) {
  return (
    <div style={{ marginBottom: "1rem" }}>
      <h4 style={{ margin: 0, fontWeight: "bold", color: "#555", marginBottom: "1rem" }}>
        Attempt {attemptIndex + 1}
      </h4>
      <GitDiff answer={attempt.answer} />
      {attempt?.answer && (
        <>
          <CodingHint data={attempt.coding_hints} />
          <RatingCard data={attempt.coding_conversation_ratings} />
        </>
      )}
    </div>
  );
}

AttemptDetails.propTypes = {
  attempt: PropTypes.object.isRequired,
  attemptIndex: PropTypes.number.isRequired,
};

export default AttemptDetails;
