import StarIcon from "@mui/icons-material/Star";
import { Rating } from "@mui/material";
import PropTypes from "prop-types";

function RatingCard({ data }) {
  if (!Array.isArray(data)) {
    data = [data];
  }

  return (
    <div style={{ marginTop: "3rem" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          margin: "auto",
          width: "100%",
          maxWidth: "800px",
        }}
      >
        {data?.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: data.length > 1 ? "row" : "column",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                gap: "20px",
                backgroundColor: "#fff",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                padding: "10px 20px",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  color: "#333",
                  fontWeight: "bold",
                  width: "30%",
                  textAlign: data.length > 1 ? "left" : "center",
                }}
              >
                {item.rating_title || "Skill"}
              </p>

              <div style={{ width: "40%", display: "flex", flexGrow: 2, justifyContent: "flex-end",}}>
                <Rating
                  max={10}
                  value={item.rating_value}
                  readOnly
                  precision={0.5}
                  emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                />
                <p
                  style={{
                    fontSize: "16px",
                    color: "#333",
                    fontWeight: "bold",
                    width: "30%",
                    textAlign: "left",
                  }}
                >
                  &nbsp; {item.rating_value}
                </p>
              </div>

              {item.rating_reason && (
                <p
                  style={{
                    fontSize: "16px",
                    color: "#333",
                    width: "30%",
                    textAlign: "right",
                  }}
                >
                  {item.rating_reason || "Rating Reason"}
                </p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

RatingCard.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      skill: PropTypes.string.isRequired,
      rating_value: PropTypes.number.isRequired,
      rating_reason: PropTypes.string.isRequired,
      ratings: PropTypes.shape({
        rating_title: PropTypes.string,
      }),
    })
  ).isRequired,
};

export default RatingCard;
