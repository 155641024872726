import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EventIcon from "@mui/icons-material/Event";
import GamepadIcon from "@mui/icons-material/Gamepad"; // Import the icon
import SendIcon from "@mui/icons-material/Send";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const StepIconContainer = styled("div")(({ theme, active, completed, color }) => ({
  color: active || completed ? color : theme.palette.action.disabled,
  backgroundColor: active || completed ? "white" : theme.palette.action.disabled,
  zIndex: 1,
  width: 30,
  height: 30,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
}));

const icons = {
  1: { icon: <SendIcon />, color: "green" },
  2: { icon: <AssignmentTurnedInIcon />, color: "green" },
  3: { icon: <EventIcon />, color: "green" },
  4: { icon: <CheckCircleIcon />, color: "green" },
  5: { icon: <GamepadIcon />, color: "green" },
};

const CustomStepIcon = ({ active, completed, icon }) => {
  const stepIcon = icons[icon];
  return (
    <StepIconContainer active={active} completed={completed} color={stepIcon.color}>
      {stepIcon.icon}
    </StepIconContainer>
  );
};

CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.number.isRequired,
};

export default CustomStepIcon;
