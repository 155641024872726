// @mui material components
import BarChartIcon from "@mui/icons-material/BarChart";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import PsychologyIcon from "@mui/icons-material/Psychology";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import bgImage from "assets/images/bg_careers.webp";
import MKButton from "components/MKButton";
import DefaultFooter from "examples/Footers/DefaultFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import footerRoutes from "footer.routes";
import { Link } from "react-router-dom";
import routes from "webRoutes";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Careers() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/pages/landing-pages/contact-us",
          label: "Contact Us",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              style={textStyles}
            >
              Join Our Team{" "}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              style={textStyles}
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              We&apos;re always on the lookout for talented individuals who share our passion for
              changing the industry.
            </MKTypography>
            <MKButton
              variant="contained"
              color="white"
              size="large"
              component={Link}
              to="/pages/landing-pages/contact-us"
              sx={{ mt: 2 }}
            >
              Contact us now
            </MKButton>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" py={{ xs: 3, md: 12 }}>
          <Container>
            <Grid container spacing={3} sx={{ mb: 10 }}>
              <Grid item xs={12} lg={5}>
                <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
                  <MKTypography variant="h3" my={1}>
                    Why Neusort?
                  </MKTypography>
                  <MKTypography variant="body2" color="text" mb={2}>
                    At Neusort, we&aops;re on a mission to help companies hire better & faster. We
                    believe that the key to achieving our goals lies in the talent, passion, and
                    dedication of our team members. If you&aops;re looking to make a meaningful
                    impact, grow professionally, and be part of an innovative and collaborative
                    environment, you&aops;ve come to the right place.
                  </MKTypography>
                </MKBox>
                {/* <MKTypography
                  component="a"
                  href="#"
                  variant="body2"
                  color="info"
                  fontWeight="regular"
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",

                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transform: "translateX(3px)",
                      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: "translateX(6px)",
                    },
                  }}
                >
                  More about us
                  <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography> */}
              </Grid>
              <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
                <Stack>
                  <MKBox display="flex" alignItems="center" p={2}>
                    <MKBox
                      width="3rem"
                      height="3rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      {/* <Icon fontSize="small">mediation</Icon> */}
                      <PsychologyIcon />
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      <MKTypography variant="h6">Purpose-Driven Culture:</MKTypography>
                      Join a team that is passionate about making a difference. At Neusort,
                      you&apos;ll have the opportunity to work on projects that have a real impact
                      on the Human resource management industry. We change how the recruitment
                      processes work using technology.
                    </MKTypography>
                  </MKBox>
                  <MKBox display="flex" alignItems="center" p={2}>
                    <MKBox
                      width="3rem"
                      height="3rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <BarChartIcon />
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      <MKTypography variant="h6">Innovative Environment:</MKTypography>
                      Embrace creativity and innovation in everything we do. We encourage our team
                      members to think outside the box, challenge the status quo, and push
                      boundaries to drive positive change.
                    </MKTypography>
                  </MKBox>
                  <MKBox display="flex" alignItems="center" p={2}>
                    <MKBox
                      width="3rem"
                      height="3rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <FactCheckIcon />
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      <MKTypography variant="h6">Collaborative Spirit:</MKTypography>
                      Collaboration is at the heart of our culture. We believe that the best ideas
                      emerge when diverse perspectives come together. Join a team where
                      collaboration is valued, and teamwork is celebrated.
                    </MKTypography>
                  </MKBox>
                  <MKBox display="flex" alignItems="center" p={2}>
                    <MKBox
                      width="3rem"
                      height="3rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <LightbulbIcon />
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      <MKTypography variant="h6">Continuous Learning:</MKTypography>
                      We believe in investing in our people. Whether it&apos;s through training
                      programs, mentorship opportunities, or access to resources, we&apos;re
                      committed to helping you grow both personally and professionally.
                    </MKTypography>
                  </MKBox>
                </Stack>
              </Grid>
            </Grid>
            {/* <Grid item>
              <MKBox display="flex" alignItems="center" justifyContent="center" p={2}>
                <MKTypography variant="h3">Features</MKTypography>
              </MKBox>
            </Grid>
            <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <FilledInfoCard
                    icon="sports_esports"
                    title="Cognitive Games"
                    description="Engage users with a variety of brain-training exercises designed to enhance cognitive skills such as memory, attention, problem-solving, and more."
                    // action={{
                    //   type: "internal",
                    //   route: "/pages/landing-pages/eval-ai",
                    //   label: "Read more",
                    // }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FilledInfoCard
                    icon="assessment"
                    title="Behavioral Assessments"
                    description="Dive deep into individuals' behavioral patterns with scientifically validated assessments that uncover traits like resilience, adaptability, communication style, and emotional intelligence."
                    // action={{
                    //   type: "internal",
                    //   route: "/test",
                    //   label: "Read more",
                    // }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
                <Grid item xs={12} md={6}>
                  <FilledInfoCard
                    icon="face"
                    title="Personality Profiling"
                    description="Gain a nuanced understanding of individuals' personality traits and preferences to ensure cultural fit and team cohesion."
                    // action={{
                    //   type: "internal",
                    //   route: "/test",
                    //   label: "Read more",
                    // }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FilledInfoCard
                    icon="online_prediction"
                    title="Role-specific Predictions"
                    description="Receive personalized recommendations and predictions for each individual's suitability for specific roles within your organization based on their cognitive, behavioral, and personality profiles."
                    // action={{
                    //   type: "internal",
                    //   route: "/test",
                    //   label: "Read more",
                    // }}
                  />
                </Grid>
              </Grid>
            </Grid> */}
            <Grid item>
              <MKBox display="flex" alignItems="center" justifyContent="center" p={2}>
                <MKTypography variant="h3">What We Look For</MKTypography>
              </MKBox>
              <MKBox display="flex" alignItems="center" justifyContent="center" pb={2}>
                <MKTypography variant="body1">
                  We&apos;re always on the lookout for talented individuals who share our passion
                  for changing the industry. While specific requirements may vary depending on the
                  role, we generally look for candidates who demonstrate passion, integrity and
                  drive.
                </MKTypography>
              </MKBox>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={4} px={1} mt={4}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

const textStyles = { textShadow: "2px 2px 4px #000000", color: "##C0C0C0" };

export default Careers;
