import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  // Your Firebase configuration object
  apiKey: "AIzaSyA1bSN3fFdwtgpLtG_W_NcAd2WjUM2px-E",
  authDomain: "auth.hyrr.app",
  databaseURL: "https://hyra-720a2-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "hyra-720a2",
  storageBucket: "hyra-720a2.appspot.com",
  messagingSenderId: "998672992934",
  appId: "1:998672992934:web:3a70490df57d32c4104267",
  measurementId: "G-GQ4BMD12RP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getDatabase(app);

// const functions = getFunctions(app);
// connectAuthEmulator(auth, 'http://localhost:9098');
// connectDatabaseEmulator(db, 'localhost', 5004);
// connectFunctionsEmulator(functions, 'localhost', 5003);

export { auth, db };
export default db;
