import AddIcon from "@mui/icons-material/Add";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { TextField } from "@mui/material";
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import JobForm from "customComponents/AdminComponents/JobForm/JobForm";
import { BASE_URL } from "lib/utilities/globalConstants";
import { interviewTemplates } from "lib/utilities/templates";
import { UserAuth } from "provider/AuthProvider";
import { useState } from "react";
import { toast } from "react-toastify";

export default function JobFormModal() {
  const { user, isJobFormOpen, openJobForm, closeJobForm, setFormData, formData, setDraftId, setUseDefaultTemplates } = UserAuth();
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const [jobDescription, setJobDescription] = useState("");
  const [processing, setProcessing] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getExperience = (exp) => {
    if (exp === "0" || exp === "1") {
      return "0-1"
    } else if (exp === "2" || exp === "3") {
      return "2-3"
    } else if (exp === "4" || exp === "5") {
      return "4-5"
    } else if (exp >= "6") {
      return "6-7"
    } else {
      return ""
    }
  }
  const handleOpenJobForm = async (autoFill = false) => {
    if (autoFill && jobDescription.length > 0) {
      try {
        setUseDefaultTemplates(true);
        setProcessing(true);
        const res = await fetch(`${BASE_URL}admin/getDataFromJD`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.accessToken}`,
          },
          body: JSON.stringify({
            job_description: jobDescription,
            adminId: user.uid
          }),
        });
        if (res.status === 200) {
          const { data, jd_id } = await res.json();
          setFormData({
            ...formData,
            skills: data.skills || [],
            otherSkills: "",
            jobRole: data.job_role,
            experience: getExperience(data.experience),
            candidates: [],
            companyName: data.company_name || "",
            adminId: "",
            adminName: "",
            oldSkills: [],
            newSkills: [],
            draftTechStack: null,
            founderName: data.founder_name || "",
            location: data.location || "",
            templates: interviewTemplates(data.job_role, data.company_name),
            jd_id,
          });
        } else {
          toast.error("Failed to fetch job data");
        }
      } catch (error) {
        console.log(error);
        toast.error("Failed to fetch job data");
      } finally {
        setProcessing(false);
      }
    } else {
      setUseDefaultTemplates(false);
      setFormData({
        ...formData,
        skills: [],
        otherSkills: "",
        jobRole: "",
        experience: "",
        candidates: [],
        companyName: "",
        adminId: "",
        adminName: "",
        oldSkills: [],
        newSkills: [],
        draftTechStack: null,
        founderName: "",
        location: "",
      });
    }
    openJobForm();
    setDraftId(null);
    setIsPromptOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        style={{
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          paddingTop: "15px",
        }}
        onClick={() => setIsPromptOpen(true)}
      >
        Create Assessment{" "}
        <span>
          <AddIcon fontSize="large" />
        </span>
      </Button>

      <Dialog
        open={isPromptOpen}
        onClose={() => setIsPromptOpen(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            p: 1
          }
        }}
      >
        <DialogTitle sx={{ textAlign: "center", fontWeight: 700, fontSize: 24, pb: 2 }}>
          Create New Assessment
        </DialogTitle>
        <DialogContent>

          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                centered
              >
                <Tab
                  icon={<EditNoteIcon />}
                  iconPosition="start"
                  label="Manual Entry"
                  sx={{ textTransform: 'none' }}
                />
                <Tab
                  icon={<AutoAwesomeIcon />}
                  iconPosition="start"
                  label="Auto-fill"
                  sx={{ textTransform: 'none' }}
                />
              </Tabs>
            </Box>

            {tabValue === 0 && (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}>
                <Paper elevation={0} sx={{ p: 1, bgcolor: 'grey.50', borderRadius: 2, mb: 2 }}>
                  <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                    Manual Entry
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Create a new assessment by entering all the details manually including job role,
                    required skills, experience, and company information.
                  </Typography>
                </Paper>
                <Button
                  variant="contained"
                  onClick={() => handleOpenJobForm(false)}
                  style={{ width: "100%", color: "white", marginTop: "10px" }}

                >
                  Enter Details Manually
                </Button>
              </Box>
            )}
            {tabValue === 1 && (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column'
              }}>
                <Paper elevation={0} sx={{ p: 1, bgcolor: 'grey.50', borderRadius: 2, flex: 1, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                    Auto-fill Instructions
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
                    Paste your complete job description below. Our system will automatically extract:
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    placeholder="Paste your job description here..."
                    value={jobDescription}
                    onChange={(e) => setJobDescription(e.target.value)}
                    sx={{
                      mb: 2,
                      flex: 1,
                      '& .MuiOutlinedInput-root': {
                        bgcolor: 'white'
                      }
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenJobForm(true)}
                    style={{ width: "100%", marginTop: "10px", color: "white" }}
                    disabled={processing || !jobDescription.length}
                  >
                    {processing ? (
                      <>
                        <CircularProgress size={20} sx={{ mr: 1 }} color="inherit" />
                        Processing...
                      </>
                    ) : (
                      <>
                        <AutoAwesomeIcon sx={{ mr: 1 }} />
                        Auto-fill Form
                      </>
                    )}
                  </Button>
                </Paper>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth="lg"
        open={isJobFormOpen}
        onClose={closeJobForm}
        PaperProps={{
          sx: {
            borderRadius: 2
          }
        }}
      >
        <DialogTitle sx={{ fontSize: 30, fontWeight: 900, textAlign: "center" }}>
          Job Description Input Form
        </DialogTitle>
        <DialogContent>
          <JobForm handleClose={closeJobForm} />
        </DialogContent>
      </Dialog>
    </>
  );
}