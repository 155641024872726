import PostAddIcon from "@mui/icons-material/PostAdd";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import { useQueryClient } from "@tanstack/react-query";
import { BASE_URL } from "lib/utilities/globalConstants";
import PropTypes from "prop-types";
import { UserAuth } from "provider/AuthProvider";
import * as React from "react";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";

function AddExcelModal({ formId, setIsOpening, langIds, exp }) {
  const [open, setOpen] = React.useState(false);
  const fileInputRef = React.useRef(null);
  const [candidates, setCandidates] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const queryClient = useQueryClient();
  const { user } = UserAuth();

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
    setIsOpening(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsOpening(false);
  };

  const handleCandidates = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);
        setCandidates(parsedData);
      };
    } else {
      console.error("No file selected or file type is incorrect.");
    }
  };

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
      setCandidates([]);
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    if (!user) return;
    try {
      const response = await fetch(`${BASE_URL}admin/addCandidatesToForm`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify({
          formId: formId,
          candidates: candidates,
          langIds: langIds,
          exp: exp,
        }),
      });
      if (response.ok) {
        const data = await response.json();
        toast.success(data.message);
        queryClient.invalidateQueries(["getAllForms"]);
      } else {
        toast.error("An error occurred");
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoading(false);
      handleClose();
    }
  }
  return (
    <div>
      <Tooltip title="Add Excel" placement="right">
        <PostAddIcon
          style={{
            color: "#006566",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={handleOpen}
          fontSize="medium"
        />
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <form onSubmit={handleSubmit}>
            <p
              style={{
                width: "max-content",
                padding: "5px 4px",
                borderBottom: "1px solid #006566",
                color: "#006566",
                fontWeight: 400,
                backgroundColor: "#f9f9f9",
                lineHeight: "16px",
                cursor: "pointer",
                marginBottom: "16px",
                display: "inline-block",
              }}
              onClick={clearFileInput}
            >
              Reset File Input
            </p>
            <input
              ref={fileInputRef}
              type="file"
              accept=".xlsx, .xls"
              required
              onChange={handleCandidates}
              style={{
                padding: "8px 4px",
                color: "#3d4f6e",
                cursor: "pointer",
                display: "block",
                marginBottom: "16px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                width: "100%",
                boxSizing: "border-box",
              }}
            />
            <Button
              style={{
                color: "white",
                width: "30%",
                padding: "8px",
                cursor: "pointer",
                backgroundColor: "#006566",
                border: "none",
                borderRadius: "4px",
                display: "block",
                margin: "0 auto",
              }}
              variant="contained"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Submitting" : "Submit"}
            </Button>
          </form>
        </Box>
      </Modal>
    </div>
  );
}

AddExcelModal.propTypes = {
  formId: PropTypes.number.isRequired,
  exp: PropTypes.string.isRequired,
  setIsOpening: PropTypes.func.isRequired,
  langIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default AddExcelModal;