/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team4 from "assets/images/arjun.jpg";
import team3 from "assets/images/brij.jpg";
import team1 from "assets/images/rohan.jpg";
import team2 from "assets/images/shagoon.jpg";
import team5 from "assets/images/shashwat.jpg";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              The Team
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              There&apos;s nothing I really wanted to do in life that I wasn&apos;t able to get good
              at. That&apos;s my skill.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Rohan Marwaha"
                position={{ color: "info", label: "Founder and CEO" }}
                description="A seasoned marketing and sales professional, boasting over seven years of comprehensive experience in the industry."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Shagoon Maurya"
                position={{ color: "info", label: "Co Founder and Psychologist" }}
                description="Our in-house psychologist, with six years of experience in the field and currently a prospective PhD candidate, brings a deep understanding of psychology to our team."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team3}
                name="Brij Vaid"
                position={{ color: "info", label: "Co Founder and CTO" }}
                description="Boasts over eight years of industry experience in developing scalable software systems, demonstrating a proven track record of technical expertise."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="Arjun Sethi"
                position={{ color: "info", label: "Lead Software Engineer" }}
                description="A highly motivated individual possessing a profound understanding of technology and its practical applications."
              />
            </MKBox>
          </Grid>
          <Grid item xs={18} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team5}
                name="Shashwat Gupta"
                position={{ color: "info", label: "Software Development Engineer" }}
                description="A quick and self-motivated learner, known for diligence and a strong work ethic, consistently driving projects to completion."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
