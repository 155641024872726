/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
// import Icon from "@mui/material/Icon";

// @mui icons
// import GitHubIcon from "@mui/icons-material/GitHub";

// Pages
import AboutUs from "layouts/pages/landing-pages/about-us";
// import Author from "layouts/pages/landing-pages/author";
import { SecurityRounded } from "@mui/icons-material";
import HailIcon from "@mui/icons-material/Hail";
import InfoIcon from "@mui/icons-material/Info";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import Clients from "layouts/pages/clients/our-clients";
import BrainGage from "layouts/pages/landing-pages/brain-gage";
import Careers from "layouts/pages/landing-pages/careers";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import CookiePolicyPage from "layouts/pages/landing-pages/cookie-policy";
import DataPrivacyPolicyPage from "layouts/pages/landing-pages/data-privacy-policy";
import DataRetentionPolicyPage from "layouts/pages/landing-pages/data-retention-policy";
import DataSubjectRightsPolicyPage from "layouts/pages/landing-pages/data-subject-rights-policy";
import EvalAI from "layouts/pages/landing-pages/eval-ai";
import Hyrr from "layouts/pages/landing-pages/hyrr";
import HyrrStream from "layouts/pages/landing-pages/hyrr-stream";
// Sections
// import PageHeaders from "layouts/sections/page-sections/page-headers";
// import Features from "layouts/sections/page-sections/featuers";
// import Navbars from "layouts/sections/navigation/navbars";
// import NavTabs from "layouts/sections/navigation/nav-tabs";
// import Pagination from "layouts/sections/navigation/pagination";
// import Inputs from "layouts/sections/input-areas/inputs";
// import Forms from "layouts/sections/input-areas/forms";
// import Alerts from "layouts/sections/attention-catchers/alerts";
// import Modals from "layouts/sections/attention-catchers/modals";
// import TooltipsPopovers from "layouts/sections/attention-catchers/tooltips-popovers";
// import Avatars from "layouts/sections/elements/avatars";
// import Badges from "layouts/sections/elements/badges";
// import BreadcrumbsEl from "layouts/sections/elements/breadcrumbs";
// import Buttons from "layouts/sections/elements/buttons";
// import Dropdowns from "layouts/sections/elements/dropdowns";
// import ProgressBars from "layouts/sections/elements/progress-bars";
// import Toggles from "layouts/sections/elements/toggles";
// import Typography from "layouts/sections/elements/typography";

const routes = [
  {
    name: "Services",
    icon: <PsychologyAltIcon />,
    columns: 2,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "SAAS",
        collapse: [
          {
            name: "EvalAI",
            route: "/pages/landing-pages/eval-ai",
            component: <EvalAI />,
          },
          {
            name: "BrainGage",
            route: "/pages/landing-pages/brain-gage",
            component: <BrainGage />,
          },
        ],
      },
      {
        name: "DAAS",
        collapse: [
          {
            name: "HyrrStream",
            route: "/pages/landing-pages/hyrr-stream",
            component: <HyrrStream />,
          },
        ],
      },
      {
        name: "Toolency",
        collapse: [
          {
            name: "Hyrr",
            route: "/pages/landing-pages/hyrr",
            component: <Hyrr />,
          },
        ],
      },
    ],
  },
  {
    name: "About Us",
    icon: <InfoIcon />,
    collapse: [
      {
        name: "Our Team",
        route: "/pages/landing-pages/about-us",
        component: <AboutUs />,
      },
      {
        name: "Our Clients",
        route: "/pages/landing-pages/our-clients",
        component: <Clients />,
      },
      {
        name: "Contact us",
        route: "/pages/landing-pages/contact-us",
        component: <ContactUs />,
      },
    ],
  },
  // {
  //   name: "Case Studies",
  //   route: "/pages/casestudies",
  //   icon: <AutoStoriesIcon />,
  //   component: <SignIn />,
  // },
  {
    name: "Policy",
    icon: <SecurityRounded />,
    collapse: [
      {
        name: "Cookie",
        route: "/cookie-policy",
        component: <CookiePolicyPage />,
      },
      {
        name: "Data Privacy",
        route: "/data-privacy-policy",
        component: <DataPrivacyPolicyPage />,
      },
      {
        name: "Data Retention",
        route: "/data-retention-policy",
        component: <DataRetentionPolicyPage />,
      },
      {
        name: "Data Subject Rights",
        route: "/data-subject-rights-policy",
        component: <DataSubjectRightsPolicyPage />,
      },
    ],
  },
  {
    name: "Careers",
    route: "/careers",
    icon: <HailIcon />,
    component: <Careers />,
  },
  // {
  //   name: "Blog",
  //   route: "/blog",
  //   icon: <BookIcon />,
  //   component: <SignIn />,
  // },
  // {
  //   name: "Research",
  //   icon: <SearchIcon />,
  //   route: "/research",
  //   component: <SignIn />,
  // },
];

export default routes;
