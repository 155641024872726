import PropTypes from "prop-types";
import InterviewQuestions from "./InterviewQuestions";
import InterviewSummary from "./InterviewSummary";

function InterviewStats({ data, id }) {
  function extractSummaryData(coding_conversation, groupedCodingConversations) {
    let hintsTaken = 0;
    const ratingData = {};

    coding_conversation.forEach((item) => {
      hintsTaken += item.coding_hints.length;

      item.coding_conversation_ratings.forEach((rating) => {
        const { rating_id, rating_title, rating_value } = rating;

        if (!ratingData[rating_id]) {
          ratingData[rating_id] = { rating_title, totalRating: 0, count: 0 };
        }

        ratingData[rating_id].totalRating += rating_value;
        ratingData[rating_id].count += 1;
      });
    });

    const ratings = Object.keys(ratingData).map((rating_id) => {
      if (ratingData[rating_id].rating_title === "Overall") {
        // Set the overall rating considering the skipped questions as well
        const overallRating = (
          groupedCodingConversations.reduce((total, item) => {
            return total + Number(item.overallRating);
          }, 0) / groupedCodingConversations.length
        ).toFixed(2);

        return {
          rating_id: Number(rating_id),
          rating_title: ratingData[rating_id].rating_title,
          rating_value: overallRating,
        };
      }
      return {
        rating_id: Number(rating_id),
        rating_title: ratingData[rating_id].rating_title,
        rating_value: (ratingData[rating_id].totalRating / ratingData[rating_id].count).toFixed(2),
      };
    });

    return { hintsTaken, ratings };
  }

  const recommendedQuestions = data.recommendedQuestions.reduce((acc, question) => {
    acc[question.question] = {
      question: question.question,
      attempts: [],
      programming_languages: new Set(),
    };
    return acc;
  }, {});

  const groupedCodingConversations = Object.values(
    data?.coding_conversation.reduce((acc, item) => {
      const { question, ...otherFields } = item;

      if (!acc[question]) {
        acc[question] = { question, attempts: [], programming_languages: {} };
      }

      acc[question].attempts.push(otherFields);

      item.coding_conversation_ratings.forEach((rating) => {
        const { programming_language, rating_title = [] } = rating;

        if (!acc[question].programming_languages[programming_language]) {
          acc[question].programming_languages[programming_language] = new Set();
        }

        acc[question].programming_languages[programming_language].add(rating_title);
      });

      return acc;
    }, recommendedQuestions)
  ).map((item) => ({
    ...item,
    attempts: item.attempts.map((attempt) => ({
      ...attempt,
      coding_conversation_ratings: attempt.coding_conversation_ratings.sort(
        (a, b) => a.rating_id - b.rating_id
      ),
    })),
    programming_languages: Object.fromEntries(
      Object.entries(item.programming_languages)
        .map(([language, skillsSet]) => [language, Array.from(skillsSet)])
        .filter(([language]) => language !== "General Programming")
    ),
  }));

  groupedCodingConversations.forEach((item) => {
    const totalRatings = item.attempts.reduce((total, attempt) => {
      return (
        total +
        attempt.coding_conversation_ratings.reduce(
          (sum, rating) => sum + (rating.rating_id === 1 ? rating.rating_value : 0),
          0
        )
      );
    }, 0);

    const totalRatingCount = item.attempts.reduce((total, attempt) => {
      return (
        total +
        attempt.coding_conversation_ratings.reduce(
          (count, rating) => count + (rating.rating_id === 1 ? 1 : 0),
          0
        )
      );
    }, 0);

    item.overallRating = (totalRatingCount ? totalRatings / totalRatingCount : 0).toFixed(2);
  });

  const { hintsTaken, ratings } = extractSummaryData(
    data?.coding_conversation,
    groupedCodingConversations
  );

  return (
    <div
      id={id}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "3rem",
      }}
    >
      <InterviewSummary
        data={data?.summary[0]?.summary}
        hintsTaken={hintsTaken}
        ratings={ratings}
      />
      {/* <Introduction data={data?.conversation} /> */}
      <InterviewQuestions data={groupedCodingConversations} />
    </div>
  );
}

InterviewStats.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    coding_conversation: PropTypes.arrayOf(
      PropTypes.shape({
        answer: PropTypes.string.isRequired,
        coding_conversation_ratings: PropTypes.arrayOf(
          PropTypes.shape({
            rating_value: PropTypes.number.isRequired,
            rating_reason: PropTypes.string,
            ratings: PropTypes.shape({
              rating_title: PropTypes.string.isRequired,
            }).isRequired,
          })
        ).isRequired,
        question: PropTypes.string.isRequired,
      })
    ).isRequired,
    conversation: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        conversation_ratings: PropTypes.arrayOf(
          PropTypes.shape({
            rating_value: PropTypes.number.isRequired,
            rating_reason: PropTypes.string,
            ratings: PropTypes.shape({
              rating_title: PropTypes.string.isRequired,
            }).isRequired,
          })
        ).isRequired,
      })
    ).isRequired,
    responses: PropTypes.arrayOf(PropTypes.object).isRequired,
    summary: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
};

export default InterviewStats;
