/* eslint-disable react/prop-types */
/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components

// @mui material components

// @mui icons

// Material Kit 2 React components

// Material Kit 2 React example components

// Material Kit 2 React page layout routes

// Images
import SignupComponent from "customComponents/SignupComponent";
import { constants } from "lib/utilities/Auth";
import { addCompany, checkCompany } from "lib/utilities/company";
import { signupSchema, techyrrAdminSignupSchema } from "lib/utilities/schemas/signupSchema";
import { validateDomain } from "lib/utilities/urlUtilities";
import { AuthContext } from "provider/AuthProvider";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

function SignUpBasic({ techyrrAdmin }) {
  const [credentials, setCredentials] = useState({
    fullName: "",
    gender: "",
    phone: "",
    dob: "",
    email: "",
    password: "",
    company: "",
  });
  const [dateString, setDateString] = useState("");
  const [creatingAccount, setCreatingAccount] = useState(false);
  const { signup, getCustomTokenFromBackend } = useContext(AuthContext);
  const { regex, errorCodes } = constants;
  const navigate = useNavigate();
  const { origin } = useParams();

  const onChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;

    if (name === "phone") {
      updatedValue = value.replace(regex.bannedSymbols.phoneNumber, "");
    } else if (e.target.type === "date") {
      setDateString(value);
      updatedValue = new Date(value);
    }

    setCredentials({ ...credentials, [name]: updatedValue });
  };

  const handleSignup = async () => {
    const validationResult = techyrrAdmin
      ? techyrrAdminSignupSchema.safeParse(credentials)
      : signupSchema.safeParse(credentials);
    if (validationResult.success) {
      setCreatingAccount(true);
      if (techyrrAdmin) {
        try {
          const res = await checkCompany(credentials.company);
          if (!res.success) {
            setCreatingAccount(false);
            return toast.error(
              <p style={{ fontSize: "16px" }}>{res.msg || "Something went wrong."}</p>
            );
          }
        } catch (error) {
          console.error(error);
          setCreatingAccount(false);
          return toast.error(<p style={{ fontSize: "16px" }}>{"Something went wrong."}</p>);
        }
      }
      signup(
        credentials.fullName,
        credentials.gender,
        credentials.phone,
        credentials.dob,
        credentials.email,
        credentials.password,
        techyrrAdmin && credentials.company,
        techyrrAdmin
      )
        .then(async (res) => {
          const url = document.referrer;
          if (validateDomain(url)) {
            const customToken = await getCustomTokenFromBackend(res.user.uid);
            if (techyrrAdmin) {
              try {
                await addCompany(credentials.company);
              } catch (error) {
                console.error(error);
              }
            }
            window.parent.postMessage({ token: customToken }, "*");
          }
        })
        .catch((error) => {
          toast.error(
            <p style={{ fontSize: "16px" }}>
              {errorCodes.firebase[error.code] || error.errorInfo?.message || "Try Again"}
            </p>
          );
        })
        .finally(() => {
          setCreatingAccount(false);
        });
    } else {
      toast.error(
        <p style={{ fontSize: "16px" }}>
          {validationResult.error.errors[0].message || "Try Again"}
        </p>
      );
    }
  };

  const handleGotoSignIn = () => {
    if (origin) {
      if (techyrrAdmin) {
        navigate(`/techyrr-admin/signin/${origin}`);
      } else {
        navigate(`/signin/${origin}`);
      }
    } else {
      navigate(`/signin`);
    }
  };
  return (
    <>
      <SignupComponent
        techyrrAdmin={techyrrAdmin}
        handleSignup={handleSignup}
        handleGotoSignIn={handleGotoSignIn}
        credentials={credentials}
        onChange={onChange}
        creatingAccount={creatingAccount}
        dateString={dateString}
      />
    </>
  );
}

export default SignUpBasic;
