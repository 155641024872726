import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { BASE_URL } from "lib/utilities/globalConstants";
import { tokenResult } from "lib/utilities/tokenResult";
import { UserAuth } from "provider/AuthProvider";
import React, { useState } from "react";
import { toast } from "react-toastify";

export default function InviteAdmin() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);
  const { user } = UserAuth();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isValidEmail = (email) => {
    const restrictedDomains = [
      "mail.com",
      "gmail.com",
      "hotmail.com",
      "yahoo.com",
      "outlook.com",
      "icloud.com",
      "aol.com ",
      "zoho.com",
      "protonmail.com",
      "yandex.com",
      "live.com",
      "me.com",
      "msn.com",
      "gmx.com",
      "inbox.com",
    ];
    const domain = email.split("@")[1];
    return !restrictedDomains.includes(domain);
  };

  const sendInvite = async () => {
    if (!user) {
      toast.error("Error, please sign in first");
      return;
    }

    if (!isValidEmail(email)) {
      toast.error("Invalid email domain. Please use a different email.");
      return;
    }

    try {
      const idTokenResult = await tokenResult(user);
      const response = await fetch(`${BASE_URL}super-admin/invite`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idTokenResult.token}`,
        },
        body: JSON.stringify({ email: email, name: name }),
      });
      const data = await response.json();
      if (response.ok) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Some error occurred:", error);
      toast.error(error.message);
    } finally {
      setName("");
      setEmail("");
      handleClose();
    }
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        sx={{ width: "200px" }}
        style={{ color: "white" }}
        onClick={handleClickOpen}
      >
        Invite Admin
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Invite User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To send an invitation link for admin role, please fill the details below.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            value={name}
            onChange={(e) => setName(e.target.value)}
            label="Name"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={sendInvite}>Send link</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
