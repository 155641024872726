/* eslint-disable no-unused-vars */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionDetails, CircularProgress } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { BASE_URL } from "lib/utilities/globalConstants";
import PropTypes from "prop-types";
import { UserAuth } from "provider/AuthProvider";
import { useEffect, useState } from "react";
import DeletedSkills from "./DeletedSkills";
import NewSkills from "./NewSkills";
import OldSkills from "./OldSkills";
import SkillInput from "./SkillInput";

function SkillBox({
  skills,
  exp,
  data,
  setFormData,
  newSkills,
  token,
  skillOrExpChange,
  setSkillOrExpChange,
  remainingSkills,
  setRemainingSkills,
  maxAllowedSkills,
}) {
  const [deletedSkills, setDeletedSkills] = useState([]);
  const [recievedCustomSkills, setRecievedCustomSkills] = useState([]);
  const { draftId } = UserAuth();

  async function getProgrammingSkills() {
    setFormData((prev) => {
      const data = { ...prev, formId: null, oldSkills: [] };
      return data;
    });
    if (deletedSkills.length > 0) {
      setDeletedSkills(() => {
        const newDeletedSkills = deletedSkills.filter((s) => s.skill.type !== "old");
        return newDeletedSkills;
      });
    }
    const res = await fetch(`${BASE_URL}admin/getProgrammingSkills`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        skills,
        exp,
      }),
    });
    const data = await res.json();

    const programmingSkills = data.data.programmingSkills;
    const programmingCustomSkills = data.data.programmingCustomSkills;

    console.log(data.data);
    setFormData((prev) => ({ ...prev, oldSkills: programmingSkills.map((d) => ({ ...d, skills: [] })) }));
    setDeletedSkills((prev) => [
      ...prev,
      ...programmingSkills
        .map((d) => {
          let deletedSkills = [];
          d.skills.forEach((skill) => {
            deletedSkills.push({ languageId: d.languageId, skill: { ...skill } });
          });
          return deletedSkills;
        })
        .flat(),
    ]);
    setRecievedCustomSkills(programmingCustomSkills);
    return data;
  }

  useEffect(() => {
    if (!draftId) {
      setSkillOrExpChange(true);
    }
  }, []);

  const { isFetching } = useQuery({
    queryKey: ["getProgrammingSkills", skills, exp],
    queryFn: getProgrammingSkills,
    placeholderData: keepPreviousData,
    enabled: skills?.length > 0 && !!exp && !!token && skillOrExpChange,
    refetchOnWindowFocus: false,
  });

  if (isFetching) return <CircularProgress />;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        marginBottom: "20px",
      }}
    >
      {data && (
        <div>
          {data?.length > 0 && (
            <p
              style={{
                marginBottom: "10px",
              }}
            >
              Maximum number of skills that can be tested in a single interview: {maxAllowedSkills}
              <br />
              No. of skills remaining: {remainingSkills}
            </p>
          )}
          {data?.map((d) => {
            const filteredNewSkills = newSkills.filter(
              (skill) => skill.languageId === d.languageId
            );
            const filteredDeletedSkills = deletedSkills.filter(
              (skill) => skill.languageId === d.languageId
            );
            return (
              <Accordion key={d.languageId}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  style={{ color: "#344666", fontWeight: "700" }}
                >
                  {d.languageName}
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    fontSize: "0.8rem",
                    color: "gray",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {d.skills && (
                    <OldSkills
                      skills={d.skills}
                      setDeletedSkills={setDeletedSkills}
                      setFormData={setFormData}
                      languageId={d.languageId}
                      setRemainingSkills={setRemainingSkills}
                    />
                  )}
                  {filteredNewSkills.length > 0 && (
                    <NewSkills
                      skills={filteredNewSkills}
                      setDeletedSkills={setDeletedSkills}
                      setFormData={setFormData}
                      languageId={d.languageId}
                      setRemainingSkills={setRemainingSkills}
                    />
                  )}
                  {filteredDeletedSkills.length > 0 && (
                    <DeletedSkills
                      skills={filteredDeletedSkills}
                      setFormData={setFormData}
                      setDeletedSkills={setDeletedSkills}
                      remainingSkills={remainingSkills}
                      setRemainingSkills={setRemainingSkills}
                      maxAllowedSkills={maxAllowedSkills}
                    />
                  )}
                  <div style={{ margin: "10px 0px 20px" }}>
                    <SkillInput
                      setFormData={setFormData}
                      languageName={d.languageName}
                      languageId={d.languageId}
                      remainingSkills={remainingSkills}
                      setRemainingSkills={setRemainingSkills}
                      maxAllowedSkills={maxAllowedSkills}
                      recievedCustomSkills={recievedCustomSkills}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      )}
    </div>
  );
}

SkillBox.propTypes = {
  skills: PropTypes.arrayOf(PropTypes.string).isRequired,
  exp: PropTypes.string,
  data: PropTypes.array.isRequired,
  newSkills: PropTypes.array.isRequired,
  setFormData: PropTypes.func.isRequired,
  token: PropTypes.string,
  skillOrExpChange: PropTypes.bool.isRequired,
  setSkillOrExpChange: PropTypes.func.isRequired,
  formId: PropTypes.string,
};

export default SkillBox;
