import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import CandidatesTable from "customComponents/AdminComponents/CandidatesTable";
import { BASE_URL } from "lib/utilities/globalConstants";
import { tokenResult } from "lib/utilities/tokenResult";
import { UserAuth } from "provider/AuthProvider";
import { useState } from "react";
import { useParams } from "react-router-dom";

const FormPage = () => {
  let { formId } = useParams();
  const { user } = UserAuth();
  const [token, setToken] = useState("");

  const getFormDetails = async () => {
    const idTokenResult = await tokenResult(user);
    setToken(idTokenResult.token);
    const res = await fetch(`${BASE_URL}admin/getFormDetails/${formId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idTokenResult.token}`,
      },
    });
    const data = await res.json();
    return data;
  };

  const { data, error, isLoading } = useQuery({
    queryKey: ["getFormDetails", formId],
    queryFn: getFormDetails,
    enabled: !!user,
    refetchOnWindowFocus: false,
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error fetching forms: {error.message}</div>;

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Form Details
      </Typography>

      <CandidatesTable formId={data.id} token={token} formData={data} />

      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Grid container spacing={4} my={4} width={"92.5%"}>
          <Grid item xs={12}>
            <Paper elevation={3} style={{ padding: "16px" }}>
              <Typography variant="h5" gutterBottom>
                Send Assignment Template
              </Typography>
              <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                <strong>Subject:</strong> {data.sendAssignmentSubject}
              </Typography>
              <Box
                dangerouslySetInnerHTML={{ __html: data.sendAssignmentTemplate }}
                sx={{ px: 6, py: 2, backgroundColor: "#f9f9f9", border: "1px solid #ccc" }}
              />
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={3} style={{ padding: "16px" }}>
              <Typography variant="h5" gutterBottom>
                Interview Schedule Template
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                <strong>Subject:</strong> {data.interviewScheduleSubject}
              </Typography>
              <Box
                dangerouslySetInnerHTML={{ __html: data.interviewScheduleTemplate }}
                sx={{ px: 6, py: 2, backgroundColor: "#f9f9f9", border: "1px solid #ccc" }}
              />
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={3} style={{ padding: "16px" }}>
              <Typography variant="h5" gutterBottom>
                Interview Invite Template
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                <strong>Subject:</strong> {data.interviewInviteSubject}
              </Typography>
              <Box
                dangerouslySetInnerHTML={{ __html: data.interviewInviteTemplate }}
                sx={{ px: 6, py: 2, backgroundColor: "#f9f9f9", border: "1px solid #ccc" }}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default FormPage;
