import { CircularProgress, Step, StepLabel, Stepper, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { useQuery } from "@tanstack/react-query";
import { BASE_URL } from "lib/utilities/globalConstants";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import CustomStepIcon from "./CustomStepIcon";
import InterviewStats from "./InterviewStats/InterviewStats";
import RoomData from "./RoomData";
import SubmissionResults from "./SubmissionResults";

const steps = [
  { label: "Assignment sent" },
  { label: "Assignment submitted" },
  { label: "Interview scheduled" },
  { label: "Interview done" },
  { label: "Game based assessment done" },
];

function scrollToStep(id) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}
function CandidateAssignment({ candidateId, formId, token, uid, companyId }) {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [pending, setPending] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // eslint-disable-next-line no-unused-vars
  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  // eslint-disable-next-line no-unused-vars
  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const handleScroll = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    function handleScrollButtonVisibilty() {
      window.pageYOffset > 500 ? setIsVisible(true) : setIsVisible(false);
    }
    window.addEventListener("scroll", handleScrollButtonVisibilty);
    return () => {
      window.removeEventListener("scroll", handleScrollButtonVisibilty);
    };
  }, []);

  async function getCandidateAssignment() {
    try {
      const res = await fetch(`${BASE_URL}admin/getCandidateAssignment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          formId,
          candidateId,
        }),
      });
      const data = await res.json();
      if (data.message !== null) {
        setActiveStep(steps.findIndex((step) => step.label === data.message));
        setPending(false);
      } else {
        setPending(true);
      }
      return data;
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }
  const { data } = useQuery({
    queryKey: ["getCandidateAssignment", candidateId, formId],
    queryFn: getCandidateAssignment,
    enabled: !!token && !!formId && !!candidateId && pending,
    refetchInterval: 10000,
    refetchOnWindowFocus: false,
  });
  if (isLoading) return <CircularProgress />;
  if (pending)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "white",
          borderRadius: "25px",
          padding: "2rem 3rem 2rem",
        }}
      >
        <h1
          style={{
            fontSize: "2rem",
            fontWeight: "800",
            textAlign: "center",
            color: "#344565",
            marginBottom: "2rem",
          }}
        >
          Currently sending the assignment to the candidate.
        </h1>
        <CircularProgress style={{ height: "40px", width: "40px" }} />
      </div>
    );
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#ebebeb",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {data.assignment?.deadline && (
          <h1 style={{ textAlign: "left", fontSize: "1.5rem" }}>
            Submission deadline:{" "}
            <span style={{ color: "red" }}>
              {data?.assignment?.deadline && data?.assignment?.deadline.split("T")[0]}
            </span>
          </h1>
        )}
        <Box sx={{ width: "100%", marginBottom: "2rem" }}>
          <Stepper activeStep={activeStep} orientation={isSmallScreen ? "vertical" : "horizontal"}>
            {steps.map((step, index) => (
              <Step
                style={{ cursor: "pointer" }}
                onClick={() => scrollToStep(`step${index + 1}`)}
                key={index}
              >
                <StepLabel StepIconComponent={CustomStepIcon}>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </div>

      {data?.assignment?.problemStatement && (
        <div
          id="step1"
          style={{
            width: "80%",
            padding: "2rem 4rem 2rem",
            backgroundColor: "white",
            height: "50rem",
            overflowX: "scroll",
            overflowY: "scroll",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
          dangerouslySetInnerHTML={{ __html: data?.assignment?.problemStatement }}
        ></div>
      )}
      {data?.submissionData && (
        <SubmissionResults id={"step2"} token={token} data={data?.submissionData} uid={uid} />
      )}
      {data?.roomData && <RoomData id={"step3"} data={data?.roomData} companyId={companyId} token={token} />}

      {data?.interviewStats && (
        <InterviewStats
          id={"step4"}
          data={{
            ...data?.interviewStats,
            recommendedQuestions: data?.submissionData?.recommendedQuestions,
          }}
        />
      )}

      <button
        disabled={!isVisible}
        onClick={handleScroll}
        style={{
          position: "fixed",
          bottom: "2rem",
          right: "1rem",
          padding: "1rem 1.5rem 1rem",
          backgroundColor: "white",
          color: "#e91e61",
          border: "none",
          borderRadius: "50%",
          cursor: `${isVisible ? "pointer" : ""}`,
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          transition: "opacity 0.5s ease, visibility 0.5s ease",
          opacity: `${isVisible ? "100" : "0"}`,
        }}
      >
        <h1>⌃</h1>
      </button>
    </div>
  );
}

CandidateAssignment.propTypes = {
  candidateId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  companyId: PropTypes.string,
};

export default CandidateAssignment;
