export const sendAssignmentTemplate = `
<p>Dear \${name},</p>
<p>I hope this email finds you well.</p>
<p>Couple of weeks back you filled out this form for the internship opportunity at Neusort.</p>
<p>
  I am
  <a href="https://www.linkedin.com/in/rohanmarwaha/" rel="noopener noreferrer" target="_blank"
    >Rohan</a
  >, Founder of
  <a href="https://www.linkedin.com/company/neusort/" rel="noopener noreferrer" target="_blank"
    >Neusort</a
  >. As part of our assessment process, we have attached an assignment for you to complete. This
  assignment will help us assess your skills and suitability for the role.
</p>
<p><strong>Please find the assignment attached in PDF format.</strong></p>
<p><strong>Instructions:</strong></p>
<ol>
  <li>Review the assignment carefully.</li>
  <li>Complete all tasks as outlined in the document.</li>
  <li>Ensure your work is well-organized and clearly presented.</li>
</ol>
<p><strong>Submission Deadline:</strong> \${deadline}</p>
<p>
  Please submit your completed assignment by submitting your github repo here:
  <a href="\${link}" rel="noopener noreferrer" target="_blank"
    >\${link}</a
  >
  no later than the deadline mentioned above.
</p>
<p>
  Should you have any questions or require any clarification regarding the assignment, feel free to
  reach out to us.
</p>
<p>
  We look forward to receiving your completed assignment and appreciate the effort you put into this
  task.
</p>
<h5 class="ql-align-center"><br /></h5>
<h4 class="ql-align-center">Best regards,</h4>
<h4 class="ql-align-center">Rohan</h4>
<h4 class="ql-align-center">CEO</h4>
<h4 class="ql-align-center">Neusort</h4>
`;

export const interviewScheduleTemplate = `
<h1 class="ql-align-center">Interview Schedule</h1>
<p class="ql-align-center">
  We are pleased to invite you to schedule an interview for the role of intern at Neusort. Please
  use the link below to select a convenient time for your interview.
</p>
<p class="ql-align-center">
  Schedule Interview Link:
  <a href="\${scheduleLink}" rel="noopener noreferrer" target="_blank">\${scheduleLink}</a>
</p>
<h4 class="ql-align-center">
  If you have any questions, feel free to contact us at contact@\${source}.com
</h4>
`;

export const interviewInviteTemplate = `
<h1 class="ql-align-center">Interview Invitation</h1>
<p class="ql-align-center">
  We are pleased to invite you to an interview for the role of intern at neusort. Below are the
  details of your interview:
</p>
<ul>
  <li class="ql-align-center"><strong>Date:</strong> \${date}</li>
  <li class="ql-align-center"><strong>Timing:</strong> \${startTime} - \${endTime}</li>
  <li class="ql-align-center">
    <strong>Interview Link: </strong>
    <a href="\${interviewLink}" rel="noopener noreferrer" target="_blank">Link</a>
  </li>
</ul>
<p class="ql-align-center">We look forward to speaking with you.</p>
<p class="ql-align-center">Best regards,</p>
<p class="ql-align-center">The Hiring Team</p>
<h4 class="ql-align-center">© 2024 \${source}. All rights reserved.</h4>
`;

export const sendAssignmentSubject = `Next Steps: Assignment Submission - \${name}`;

export const interviewScheduleSubject = `Interview Schedule - \${name}`;

export const interviewInviteSubject = `Interview Invite - \${name}`;

export const requiredVariables = {
  sendAssignmentTemplate: ["name", "deadline", "link"],
  interviewScheduleTemplate: ["scheduleLink", "source"],
  interviewInviteTemplate: ["date", "startTime", "endTime", "interviewLink", "source"],
};
