import { Card, Container, Typography } from "@mui/material";
import DefaultNavbar from "customComponents/DefaultNavbar";
import routes from "webRoutes";

const fontSize = {
  h3: "2rem",
  h4: "1.5rem",
  body1: "1rem",
  listItem: "1rem",
};

const DataRetentionPolicy = () => {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/pages/landing-pages/contact-us",
          label: "Contact Us",
          color: "info",
        }}
        sticky
      />
      <Card
        sx={{
          p: 2,
          pt: 18,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
          <Typography variant="h3" gutterBottom style={{ fontSize: fontSize.h3 }}>
            Neusort Data Retention Policy
          </Typography>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            1. Purpose
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            The purpose of this Data Retention Policy is to ensure that Neusort manages its data in
            a way that complies with applicable legal and regulatory requirements, reduces risk, and
            ensures that data is available when needed.
          </Typography>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            2. Scope
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            This policy applies to all data collected, processed, stored, and disposed of by
            Neusort, including electronic and paper records.
          </Typography>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            3. Definitions
          </Typography>
          <ul style={{ paddingLeft: "1.5rem", fontSize: fontSize.body1 }}>
            <li>Data: All information, in any form, that is created or received by Neusort.</li>
            <li>
              Retention Period: The length of time data must be kept before it is eligible for
              destruction.
            </li>
            <li>Destruction: The process of permanently deleting or destroying data.</li>
          </ul>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            4. Data Classification
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            Data shall be classified into the following categories:
          </Typography>
          <ul style={{ paddingLeft: "1.5rem", fontSize: fontSize.body1 }}>
            <li>
              Confidential: Data that must be protected from unauthorized access and disclosure.
            </li>
            <li>Internal: Data intended for internal use only.</li>
            <li>Public: Data that can be freely disclosed to the public.</li>
          </ul>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            5. Retention Periods
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            Different types of data have different retention periods based on legal, regulatory, and
            business requirements. The following are the standard retention periods for various data
            categories:
          </Typography>
          <ul style={{ paddingLeft: "1.5rem", fontSize: fontSize.body1 }}>
            <li>Financial Records: 7 years</li>
            <li>Employee Records: 6 years after termination</li>
            <li>Customer Data: 1 year after the end of the customer relationship</li>
            <li>Contracts and Agreements: 3 years after expiration</li>
            <li>Email Correspondence: 2 years</li>
            <li>Marketing Data: 3 years</li>
          </ul>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            6. Data Storage
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            All data must be stored securely to prevent unauthorized access, alteration, or
            destruction. Access to data should be restricted based on the classification of the
            data.
          </Typography>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            7. Data Destruction
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            Once the retention period for data has expired, the data must be securely destroyed. The
            following methods should be used:
          </Typography>
          <ul style={{ paddingLeft: "1.5rem", fontSize: fontSize.body1 }}>
            <li>Electronic Data: Permanently deleted using data-wiping software.</li>
            <li>Paper Records: Shredded or incinerated.</li>
          </ul>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            8. Exceptions
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            Any exceptions to this policy must be documented and approved by Brij Vaid.
          </Typography>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            9. Roles and Responsibilities
          </Typography>
          <ul style={{ paddingLeft: "1.5rem", fontSize: fontSize.body1 }}>
            <li>CTO: Oversees the implementation of this policy and ensures compliance.</li>
            <li>Department Heads: Ensure that their teams comply with the policy.</li>
            <li>Employees: Responsible for managing data in accordance with this policy.</li>
          </ul>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            10. Compliance
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            Failure to comply with this policy may result in disciplinary action, up to and
            including termination of employment.
          </Typography>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            11. Review and Update
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            This policy shall be reviewed annually and updated as necessary to reflect changes in
            legal, regulatory, and business requirements.
          </Typography>

          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            Approved by: Rohan Marwaha
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            Effective Date: 9 April 2024
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            Review Date: 6 August 2024
          </Typography>
        </Container>
      </Card>
    </>
  );
};

export default DataRetentionPolicy;
