import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import PropTypes from "prop-types";
import AttemptDetails from "./AttemptDetails";
import QuestionSkillSummary from "./QuestionSkillSummary";
import RatingCard from "./Ratingcard";

function QuestionAccordion({ item, questionIndex }) {
  return (
    <Accordion
      style={{
        backgroundColor:
          item.attempts.length === 0 || item.attempts[item.attempts.length - 1].answer === null
            ? "#ffdce0"
            : "#dff7f9",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        borderRadius: "10px",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${questionIndex}-content`}
        id={`panel${questionIndex}-header`}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
          <h3 style={{ margin: 0, fontWeight: "bold", color: "#333" }}>
            Question {questionIndex + 1}
          </h3>
          <p
            style={{
              width: "100%",
              fontWeight: "400",
              color:
                item.attempts.length === 0 ||
                item.attempts[item.attempts.length - 1].answer === null
                  ? "darkred"
                  : "#00796b",
              fontSize: "1.2rem",
              textAlign: "justify",
            }}
          >
            {item.question}
          </p>
          <RatingCard
            data={{
              rating_title:
                item.attempts.length === 0 ? "Question Not Attempted" : "Overall Rating",
              rating_value: parseFloat(item.overallRating),
            }}
          />
          <QuestionSkillSummary programming_languages={item.programming_languages} />
        </div>
      </AccordionSummary>
      <AccordionDetails
        style={{
          backgroundColor:
            item.attempts.length === 0 || item.attempts[item.attempts.length - 1].answer === null
              ? "#ffdce0"
              : "#dff7f9",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {item.attempts.map((attempt, attemptIndex) => (
          <AttemptDetails key={attemptIndex} attempt={attempt} attemptIndex={attemptIndex} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

QuestionAccordion.propTypes = {
  item: PropTypes.object.isRequired,
  questionIndex: PropTypes.number.isRequired,
};

export default QuestionAccordion;
