import { useTheme } from "@emotion/react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HomeIcon from "@mui/icons-material/Home";
import { useMediaQuery } from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { signOut } from "firebase/auth";
import { auth } from "lib/firebase/firebase";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export default function AdminHeader() {
  const [value, setValue] = React.useState("recents");
  const theme = useTheme();
  const isSmallAndMediumScreen = useMediaQuery(theme.breakpoints.down("sm" && "md"));
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case "home":
        navigate("/admin");
        break;
      case "dashboard":
        navigate("/admin/dashboard");
        break;
      default:
        break;
    }
  };

  return (
    <div
      style={{
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        marginBottom: "20px",
        position: "relative",
        display: "flex",
        flexDirection: `${isSmallAndMediumScreen ? "column" : "row"}`,
      }}
    >
      <BottomNavigation
        style={{
          width: `${isSmallAndMediumScreen ? "100%" : "90%"}`,
          display: "flex",
          justifyContent: "space-evenly",
        }}
        value={value}
        onChange={handleChange}
      >
        <BottomNavigationAction label="Home" value="home" icon={<HomeIcon fontSize="medium" />} />
        <BottomNavigationAction
          label="Dashboard"
          value="dashboard"
          icon={<DashboardIcon fontSize="medium" />}
        />
      </BottomNavigation>
      <div
        style={{
          width: `${isSmallAndMediumScreen ? "100%" : "10%"}`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
        }}
      >
        <button
          style={{
            marginRight: "10px",
            padding: "10px 40px 10px",
            fontSize: "12px",
            color: "#fff",
            backgroundColor: "#e91e62",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            height: "max-content",
            width: "max-content",
          }}
          onClick={async () => {
            await signOut(auth);
            navigate("/signin");
          }}
        >
          Logout
        </button>
      </div>
    </div>
  );
}
