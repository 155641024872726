import BlockIcon from "@mui/icons-material/Block";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from "@mui/icons-material/Mail";
import { Avatar } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import CandidateAssignment from "customComponents/AdminComponents/CandidateAssignment";
import { BASE_URL } from "lib/utilities/globalConstants";
import { tokenResult } from "lib/utilities/tokenResult";
import { UserAuth } from "provider/AuthProvider";
import { useState } from "react";
import { useParams } from "react-router-dom";

function CandidatePage() {
  const { formId, candidateId } = useParams();
  const [token, setToken] = useState("");
  const { user } = UserAuth();
  async function getCandidate() {
    const idTokenResult = await tokenResult(user);
    setToken(idTokenResult.token);
    try {
      const res = await fetch(`${BASE_URL}admin/getCandidateDetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idTokenResult.token}`,
        },
        body: JSON.stringify({
          formId,
          candidateId,
          adminId: user.uid,
        }),
      });
      const data = await res.json();
      return { candidate: data.candidate.candidate, companyId: data.companyId };
    } catch (error) {
      console.log(error);
    }
  }

  const { data, isLoading, isError } = useQuery({
    queryKey: ["getCandidate", formId, candidateId],
    queryFn: getCandidate,
    enabled: !!user,
    refetchOnWindowFocus: false,
  });
  if (isLoading) return <div>Loading...</div>;
  if (isError)
    return (
      <div
        style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <BlockIcon style={{ color: "red", height: "4rem", width: "4rem" }} />
          <p style={{ fontWeight: "700", color: "gray", fontSize: "1.3rem" }}>
            You cannot access this page
          </p>
        </div>
      </div>
    );
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "3rem",
      }}
    >
      <div
        style={{
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          padding: "2rem 5rem 2rem",
          // border: "1px solid gray",
          borderRadius: "50px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "0.5rem",
          }}
        >
          <Avatar
            style={{ backgroundColor: "orange", height: "70px", width: "70px" }}
            alt={data.candidate.name}
            src="/broken-image.jpg"
          ></Avatar>
        </div>
        <h1 style={{ textAlign: "center", fontWeight: "800", fontSize: "1.5rem" }}>
          {" "}
          {data.candidate.name.toUpperCase()}
        </h1>
        <h1
          style={{
            textAlign: "left",
            fontWeight: "400",
            fontSize: "1.2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.5rem",
          }}
        >
          {" "}
          <MailIcon />
          {data.candidate.email}
        </h1>
        <h1
          style={{
            textAlign: "left",
            fontWeight: "400",
            fontSize: "1.2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.5rem",
          }}
        >
          {" "}
          <LocalPhoneIcon /> {data.candidate.candidatePhoneNo}
        </h1>
      </div>
      <CandidateAssignment candidateId={candidateId} formId={formId} token={token} uid={data.candidate.uid} companyId={data.companyId} />
    </div>
  );
}

export default CandidatePage;
