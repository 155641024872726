import Chip from "@mui/material/Chip";
import { useQuery } from "@tanstack/react-query";
import DeleteDraftModal from "customComponents/Modals/DelelteDraftModal";
import { BASE_URL } from "lib/utilities/globalConstants";
import PropTypes from "prop-types";
import { UserAuth } from "provider/AuthProvider";
import { useState } from "react";

function FormDrafts({ setTotalDrafts }) {
  const { user, closeDraftModal, openJobForm, formData, setFormData, setDraftId } = UserAuth();
  const [isDeleting, setIsDeleting] = useState(false);

  async function getJobFormDrafts() {
    if (!user) return;
    const result = await fetch(`${BASE_URL}admin/getJobFormDrafts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.accessToken}`,
      },
      body: JSON.stringify({
        companyName: user?.email?.split("@")[1].split(".")[0],
        adminId: user.uid,
      }),
    });
    const data = await result.json();
    setTotalDrafts(data.total);
    return data.data;
  }

  const { data, isError } = useQuery({
    queryKey: ["getJobFormDrafts"],
    queryFn: getJobFormDrafts,
    enabled: !!user,
    refetchOnWindowFocus: false,
  });
  // console.log(draftId);
  async function onSelectDraft(draft) {
    if (isDeleting === true) return;
    closeDraftModal();
    openJobForm();
    setFormData({
      ...formData,
      skills: draft.languages,
      otherSkills: draft.otherSkills,
      jobRole: draft.jobRole,
      experience: draft.experience,
      candidates: [],
      companyName: user?.email?.split("@")[1].split(".")[0],
      adminId: draft.adminId,
      adminName: draft.adminName,
      oldSkills: draft.form_skill_mapping,
      newSkills: draft.custom_skills,
      draftTechStack: draft.draftTechStack,
    });
    setDraftId(draft.id);
  }

  if (isError) return <div>Error fetching form drafts ❌</div>;
  if (data?.length === 0) return <div>No drafts</div>;

  return (
    <div style={{ margin: "0% 5% 0%" }}>
      {data?.map((draft, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "10px 16px 10px",
            margin: "16px 0",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#fff",
            gap: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            onSelectDraft(draft);
          }}
        >
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h1 style={{ fontSize: "14px", color: "#333" }}>{draft?.jobRole?.toUpperCase()}</h1>
              <DeleteDraftModal formId={draft.id} setIsDeleting={setIsDeleting} />
            </div>
            <p style={{ color: "#666", fontSize: "12px" }}>{draft?.experience} years</p>
          </div>
          <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
            {draft?.languages?.map((lang) => (
              <Chip
                key={lang}
                label={lang}
                style={{
                  backgroundColor: "#e0f7fa",
                  color: "#00796b",
                  fontSize: "10px",
                }}
              />
            ))}
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "end" }}>
            <p style={{ margin: "0 0 8px 0", color: "#999", fontSize: "12px" }}>
              Created at {draft?.created_at?.split("T")[0]}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

FormDrafts.propTypes = {
  setTotalDrafts: PropTypes.func.isRequired,
  setDraftId: PropTypes.func.isRequired,
};

export default FormDrafts;