// @mui material components
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
import bgImageProfessionals from "assets/images/bg_professionals.webp";
import bgImage from "assets/images/evalai-bg.webp";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import DefaultFooter from "examples/Footers/DefaultFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import footerRoutes from "footer.routes";
import routes from "webRoutes";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function EvalAI() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/pages/landing-pages/contact-us",
          label: "Contact Us",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              style={textStyles}
            >
              Welcome to EvalAI{" "}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              style={textStyles}
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Revolutionizing Job Interviews with Artificial Intelligence
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" py={{ xs: 3, md: 12 }}>
          <Container>
            <Grid container spacing={3} sx={{ mb: 10 }}>
              <Grid item xs={12} lg={5}>
                <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
                  <MKTypography variant="h3" my={1}>
                    Why EvalAI?
                  </MKTypography>
                  <MKTypography variant="body2" color="text" mb={2}>
                    At EvalAI, we believe in the power of technology to transform traditional job
                    interviews into seamless, efficient, and insightful experiences. Our innovative
                    platform leverages the latest advancements in artificial intelligence to
                    revolutionize the way organizations evaluate and hire talent.
                  </MKTypography>
                </MKBox>
                {/* <MKTypography
                  component="a"
                  href="#"
                  variant="body2"
                  color="info"
                  fontWeight="regular"
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",

                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transform: "translateX(3px)",
                      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: "translateX(6px)",
                    },
                  }}
                >
                  More about us
                  <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography> */}
              </Grid>
              <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
                <Stack>
                  <MKBox display="flex" alignItems="center" p={2}>
                    <MKBox
                      width="3rem"
                      height="3rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      {/* <Icon fontSize="small">mediation</Icon> */}
                      <DirectionsRunIcon />
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      <MKTypography variant="h6">Efficiency:</MKTypography>
                      Say goodbye to the hassle of scheduling interviews and conducting repetitive
                      screenings.
                      <br />
                      EvalAI automates the entire interview process, saving your organization time
                      and resources.
                    </MKTypography>
                  </MKBox>
                  <MKBox display="flex" alignItems="center" p={2}>
                    <MKBox
                      width="3rem"
                      height="3rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <Icon fontSize="small">settings_overscan</Icon>
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      <MKTypography variant="h6">Accuracy:</MKTypography>
                      Our AI algorithms are designed to evaluate candidates objectively and
                      accurately,
                      <br />
                      providing valuable insights into their skills, competencies, and potential fit
                      for the role.
                    </MKTypography>
                  </MKBox>
                  <MKBox display="flex" alignItems="center" p={2}>
                    <MKBox
                      width="3rem"
                      height="3rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <FactCheckIcon />
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      <MKTypography variant="h6">Fairness:</MKTypography>
                      We prioritize fairness and inclusivity in every aspect of our platform.
                      <br />
                      EvalAI ensures that all candidates are assessed based on merit, free from
                      biases or prejudices.
                    </MKTypography>
                  </MKBox>
                  <MKBox display="flex" alignItems="center" p={2}>
                    <MKBox
                      width="3rem"
                      height="3rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <LightbulbIcon />
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      <MKTypography variant="h6">Insights:</MKTypography>
                      Gain deeper insights into candidate performance and behavior with our
                      comprehensive analytics dashboard.
                      <br />
                      Make informed hiring decisions backed by data-driven intelligence.
                    </MKTypography>
                  </MKBox>
                </Stack>
              </Grid>
            </Grid>
            <Grid item>
              <MKBox display="flex" alignItems="center" justifyContent="center" p={2}>
                <MKTypography variant="h3">Features</MKTypography>
              </MKBox>
            </Grid>
            <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <FilledInfoCard
                    icon="auto_fix_high"
                    title="AI-Powered Interviews"
                    description="Our advanced AI technology conducts interviews in real-time, analyzing verbal and non-verbal cues to assess candidate suitability."
                    // action={{
                    //   type: "internal",
                    //   route: "/pages/landing-pages/eval-ai",
                    //   label: "Read more",
                    // }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FilledInfoCard
                    icon="assessment"
                    title="Customizable Assessments"
                    description="Tailor interview questions and assessments to align with your organization's unique requirements and job roles."
                    // action={{
                    //   type: "internal",
                    //   route: "/test",
                    //   label: "Read more",
                    // }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
                <Grid item xs={12} md={6}>
                  <FilledInfoCard
                    icon="integration_instructions"
                    title="Seamless Integration"
                    description="Integrate EvalAI seamlessly with your existing HR systems and workflows for a streamlined hiring process."
                    // action={{
                    //   type: "internal",
                    //   route: "/test",
                    //   label: "Read more",
                    // }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FilledInfoCard
                    icon="feedback"
                    title="Candidate Feedback"
                    description="Provide detailed feedback to candidates, helping them understand their strengths and areas for improvement."
                    // action={{
                    //   type: "internal",
                    //   route: "/test",
                    //   label: "Read more",
                    // }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <MKBox display="flex" alignItems="center" justifyContent="center" p={2}>
                <MKTypography variant="h3">Get Started Today</MKTypography>
              </MKBox>
              <MKBox display="flex" alignItems="center" justifyContent="center" pb={2}>
                <MKTypography variant="body1">
                  Ready to revolutionize your hiring process? Sign up for EvalAI now and experience
                  the future of job interviews.
                </MKTypography>
              </MKBox>
            </Grid>
            <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <MKBox
                    display="flex"
                    alignItems="center"
                    borderRadius="xl"
                    my={2}
                    py={6}
                    sx={{
                      backgroundImage: ({
                        functions: { linearGradient, rgba },
                        palette: { gradients },
                      }) =>
                        `${linearGradient(
                          rgba(gradients.dark.main, 0.8),
                          rgba(gradients.dark.state, 0.8)
                        )}, url(${bgImageProfessionals})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <Container>
                      <Grid container item xs={12} lg={10} sx={{ ml: { xs: 0, lg: 6 } }}>
                        <MKTypography variant="h4" color="white" fontWeight="bold">
                          For Organizations
                        </MKTypography>
                        <MKTypography variant="body1" color="white" opacity={0.8} mb={2}>
                          Streamline your hiring process, access top talent, and make data-driven
                          decisions with EvalAI.
                        </MKTypography>
                      </Grid>
                    </Container>
                  </MKBox>
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <MKBox
                    display="flex"
                    alignItems="center"
                    borderRadius="xl"
                    my={2}
                    py={6}
                    sx={{
                      backgroundImage: ({
                        functions: { linearGradient, rgba },
                        palette: { gradients },
                      }) =>
                        `${linearGradient(
                          rgba(gradients.dark.main, 0.8),
                          rgba(gradients.dark.state, 0.8)
                        )}, url(${bgImageStudents})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <Container>
                      <Grid container item xs={12} lg={10} sx={{ ml: { xs: 0, lg: 6 } }}>
                        <MKTypography variant="h4" color="white" fontWeight="bold">
                          For Students
                        </MKTypography>
                        <MKTypography variant="body1" color="white" opacity={0.8} mb={2}>
                          Showcase your skills and expertise in a fair and unbiased environment.
                          Take your career to new heights with EvalAI.
                        </MKTypography>
                      </Grid>
                    </Container>
                  </MKBox>
                </Grid> */}
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={4} px={1} mt={4}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

const textStyles = { textShadow: "2px 2px 4px #000000", color: "##C0C0C0" };

export default EvalAI;
