import FolderZipIcon from '@mui/icons-material/FolderZip';
import SourceIcon from '@mui/icons-material/Source';
import { adminViewAssignmentDir, BASE_URL } from "lib/utilities/globalConstants";
import PropTypes from "prop-types";
import { UserAuth } from "provider/AuthProvider";
import { useState } from "react";

function AssignmentFeatures({ unimplemented, expected, githubLink, score, uid }) {
  const { user } = UserAuth();
  const [loading, setLoading] = useState(false);

  const hanldeDownloadZip = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}download-zip`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify({
          repoUrl: githubLink,
          targetDir: adminViewAssignmentDir + uid,
          uid: uid,
          is_admin: true,
        }),
      });

      if (response.status !== 200) {
        throw new Error("Error downloading file");
      }
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${uid}.zip`;
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  }

  const handleOpenAssignment = async () => {
    document.cookie = `authToken=${user.accessToken}; path=/; domain=.neusort.com; Secure; SameSite=Lax;`;
    document.cookie = `repoData=${JSON.stringify({
      repoUrl: githubLink,
      targetDir: adminViewAssignmentDir + uid,
      uid: uid,
      is_admin: true,
    })}; path=/; domain=.neusort.com; Secure; SameSite=Lax;`;
    window.open(`${BASE_URL}assignment-repo/${uid}/`, "_blank");
  };

  return (
    <div
      style={{
        display: "flex",
        width: "70%",
        flexDirection: "column",
        padding: "20px",
        backgroundColor: "white",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div style={{ display: "flex", width: "100%", justifyContent: "space-around" }}>
        <div style={{ width: "45%" }}>
          <h3 style={{ textAlign: "center", marginBottom: "20px", color: "black" }}>Expected</h3>
          {expected?.map((i) => (
            <div
              key={i}
              style={{
                padding: "10px",
                backgroundColor: "#4caf50",
                marginBottom: "10px",
                borderRadius: "5px",
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <h5 style={{ margin: 0, textAlign: "center", color: "white", fontSize: "13px" }}>
                {i}
              </h5>
            </div>
          ))}
        </div>
        <div style={{ width: "45%" }}>
          <h3
            style={{
              textAlign: "center",
              marginBottom: "20px",
              color: "black",
              whiteSpace: "pre-wrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            Unimplemented
          </h3>
          {unimplemented?.map((u) => (
            <div
              key={u}
              style={{
                padding: "10px",
                backgroundColor: "#ff9800",
                marginBottom: "10px",
                borderRadius: "5px",
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <h5 style={{ margin: 0, textAlign: "center", color: "white", fontSize: "13px" }}>
                {u}
              </h5>
            </div>
          ))}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px",
          borderRadius: "10px",
          maxWidth: "100%",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        <div>
          <h1
            style={{
              fontSize: "1.5rem",
              fontWeight: "900",
              color: "#344565",
              margin: 0,
              textAlign: "center",
            }}
          >
            Score: {(score * 100).toFixed(2)}%
          </h1>
        </div>
        <div style={{ textAlign: "center", display: "flex", flexDirection: "row" }}>
          <button
            onClick={handleOpenAssignment}
            disabled={loading}
            style={{
              background: "white",
              cursor: loading ? "not-allowed" : "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.5rem",
              fontSize: "1rem",
              fontWeight: "400",
              color: "#007bff",
              textDecoration: "none",
              border: "1px solid #007bff",
              padding: "10px 20px",
              borderRadius: "5px",
              marginRight: "10px",
            }}
          >
            {loading ? (
              <>Loading...</>
            ) : (
              <>
                View Project Files
                <SourceIcon fontSize="medium" />
              </>
            )}
          </button>
          <button
            onClick={hanldeDownloadZip}
            disabled={loading}
            style={{
              background: "white",
              cursor: loading ? "not-allowed" : "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.5rem",
              fontSize: "1rem",
              fontWeight: "400",
              color: "#007bff",
              textDecoration: "none",
              border: "1px solid #007bff",
              padding: "10px 20px",
              borderRadius: "5px",
            }}
          >
            {loading ? (
              <>Loading...</>
            ) : (
              <>
                Download Project Zip
                <FolderZipIcon fontSize="medium" />
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

AssignmentFeatures.propTypes = {
  unimplemented: PropTypes.arrayOf(PropTypes.string).isRequired,
  expected: PropTypes.arrayOf(PropTypes.string).isRequired,
  githubLink: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
};

export default AssignmentFeatures;
