import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

function QuestionHeader() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
      }}
    >
      <QuestionAnswerIcon style={{ color: "#007BFF" }} fontSize="large" />
      <h2 style={{ fontSize: "1.5rem", fontWeight: "bold", color: "#333" }}>Coding Conversation</h2>
    </div>
  );
}

export default QuestionHeader;
