import SendIcon from "@mui/icons-material/Send";
import PropTypes from "prop-types";
import { useState } from "react";
import { toast } from "react-toastify";

function SkillInput({
  setFormData,
  languageId,
  remainingSkills,
  setRemainingSkills,
  maxAllowedSkills,
  recievedCustomSkills,
}) {
  const [skill, setSkill] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isFocused, setIsFocused] = useState(false);

  const handleAddSkill = () => {
    if (remainingSkills === 0) {
      toast.error(
        `We can only test ${maxAllowedSkills} skills of a candidate in a single interview. Remove some skills to add more.`
      );
      return;
    }

    const existingCustomSkill = recievedCustomSkills.find(
      (s) =>
        s.programming_language.language_id === languageId &&
        s.skill.toLowerCase() === skill.toLowerCase()
    );

    let obj = {
      id: existingCustomSkill?.id,
      languageId: languageId,
      skill: skill,
      approved: !!existingCustomSkill,
      type: "new",
    };

    setFormData((prev) => {
      const data = { ...prev };
      data.newSkills = [...data.newSkills, obj];
      return data;
    });

    setSkill("");
    setRemainingSkills((prev) => prev - 1);
    setSuggestions([]);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSkill(value);

    if (value.length > 0) {
      const filteredSuggestions = recievedCustomSkills
        .filter(
          (s) =>
            s.programming_language.language_id === languageId &&
            s.skill.toLowerCase().startsWith(value.toLowerCase())
        )
        .map((s) => s.skill);

      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSkill(suggestion);
    setSuggestions([]);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setTimeout(() => setIsFocused(false), 100);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddSkill();
    }
  };

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          borderRadius: "5px",
          padding: "5px 10px 5px",
          border: "1px solid gray",
          gap: "5px",
        }}
      >
        <input
          type="text"
          placeholder="Add Skill"
          value={skill}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          style={{
            width: "100%",
            outline: "none",
            border: "none",
            padding: "5px 0px 5px",
            fontSize: "12px",
          }}
        />
        <SendIcon
          onClick={handleAddSkill}
          style={{ color: "#007BFF", cursor: "pointer" }}
          fontSize="small"
        />
      </div>

      {isFocused && suggestions.length > 0 && (
        <div
          style={{
            position: "absolute",
            top: "40px",
            left: "0",
            right: "0",
            backgroundColor: "#fff",
            border: "1px solid gray",
            borderRadius: "5px",
            zIndex: 10,
            maxHeight: "150px",
            overflowY: "auto",
          }}
        >
          {suggestions.map((suggestion, index) => (
            <div
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
              style={{
                padding: "5px",
                cursor: "pointer",
                borderBottom: "1px solid #f0f0f0",
              }}
            >
              {suggestion}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

SkillInput.propTypes = {
  languageId: PropTypes.number.isRequired,
  setFormData: PropTypes.func.isRequired,
  remainingSkills: PropTypes.number.isRequired,
  setRemainingSkills: PropTypes.func.isRequired,
  maxAllowedSkills: PropTypes.number.isRequired,
  recievedCustomSkills: PropTypes.array.isRequired,
};

export default SkillInput;
