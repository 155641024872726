// @mui material components
import JoinInnerIcon from "@mui/icons-material/JoinInner";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import bgImage from "assets/images/bg_hyrr.webp";
import bgImageProfessionals from "assets/images/bg_professionals.webp";
import MKButton from "components/MKButton";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import DefaultFooter from "examples/Footers/DefaultFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import footerRoutes from "footer.routes";
import routes from "webRoutes";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Hyrr() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/pages/landing-pages/contact-us",
          label: "Contact Us",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              style={textStyles}
            >
              Hyrr{" "}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              style={textStyles}
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Discover how our neuroscience-driven gaming platform not only entertains but unlocks
              your professional destiny.
            </MKTypography>
            <MKButton
              variant="contained"
              color="white"
              size="large"
              component="a"
              href="https://games.hyrr.app"
              sx={{ mt: 2 }}
            >
              Sign up now
            </MKButton>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" py={{ xs: 3, md: 12 }}>
          <Container>
            <Grid container spacing={3} sx={{ mb: 10 }}>
              <Grid item xs={12} lg={5}>
                <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
                  <MKTypography variant="h3" my={1}>
                    Why Hyrr?
                  </MKTypography>
                  <MKTypography variant="body2" color="text" mb={2}>
                    Join thousands who&apos;ve discovered their dream careers through our platform -
                    where gaming meets real-world opportunities
                  </MKTypography>
                </MKBox>
                {/* <MKTypography
                  component="a"
                  href="#"
                  variant="body2"
                  color="info"
                  fontWeight="regular"
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",

                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transform: "translateX(3px)",
                      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: "translateX(6px)",
                    },
                  }}
                >
                  More about us
                  <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography> */}
              </Grid>
              <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
                <Stack>
                  <MKBox display="flex" alignItems="center" p={2}>
                    <MKBox
                      width="3rem"
                      height="3rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      {/* <Icon fontSize="small">mediation</Icon> */}
                      <TipsAndUpdatesIcon />
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      <MKTypography variant="h6">Innovative Career Discovery</MKTypography>
                      Transform how you think about job hunting with our unique blend of gaming and
                      neuroscience.
                    </MKTypography>
                  </MKBox>
                  <MKBox display="flex" alignItems="center" p={2}>
                    <MKBox
                      width="3rem"
                      height="3rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <JoinInnerIcon />
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      <MKTypography variant="h6">Personalized Job Matching</MKTypography>
                      Our engine analyzes your skills and preferences during gameplay to recommend
                      the perfect job matches.
                    </MKTypography>
                  </MKBox>
                  <MKBox display="flex" alignItems="center" p={2}>
                    <MKBox
                      width="3rem"
                      height="3rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <LocalLibraryIcon />
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                      <MKTypography variant="h6">Engagement and Learning:</MKTypography>
                      Enhance your skillset and uncover hidden talents through engaging game
                      mechanics.
                    </MKTypography>
                  </MKBox>
                </Stack>
              </Grid>
            </Grid>
            <Grid item>
              <MKBox display="flex" alignItems="center" justifyContent="center" p={2}>
                <MKTypography variant="h3">Features</MKTypography>
              </MKBox>
            </Grid>
            <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <FilledInfoCard
                    icon="psychology"
                    title="Neuroscience-Based Engine"
                    description="Tailors job recommendations by analyzing gameplay."
                    // action={{
                    //   type: "internal",
                    //   route: "/pages/landing-pages/eval-ai",
                    //   label: "Read more",
                    // }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FilledInfoCard
                    icon="book"
                    title="Diverse Game Library"
                    description="A wide range of games designed to assess various skills and preferences."
                    // action={{
                    //   type: "internal",
                    //   route: "/test",
                    //   label: "Read more",
                    // }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
                <Grid item xs={12} md={6}>
                  <FilledInfoCard
                    icon="cached"
                    title="Career Progression Tracking"
                    description="Monitor skill development and career match evolution over time."
                    // action={{
                    //   type: "internal",
                    //   route: "/test",
                    //   label: "Read more",
                    // }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FilledInfoCard
                    icon="stars"
                    title="Role-specific Games"
                    description="Receive personalized recommendations and precisions for each individual's suitability for specific roles within your organization based on their cognitive, behavioral, and personality profiles."
                    // action={{
                    //   type: "internal",
                    //   route: "/test",
                    //   label: "Read more",
                    // }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <MKBox display="flex" alignItems="center" justifyContent="center" p={2}>
                <MKTypography variant="h3">Success Indicators</MKTypography>
              </MKBox>
              <MKBox display="flex" alignItems="center" justifyContent="center" pb={2}>
                <MKTypography variant="body1">
                  Increased user engagement and satisfaction rates, higher job placement success
                  stories, and positive user testimonials on platform effectiveness and innovation.
                </MKTypography>
              </MKBox>
            </Grid>
            <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <MKBox
                    display="flex"
                    alignItems="center"
                    borderRadius="xl"
                    my={2}
                    py={6}
                    sx={{
                      backgroundImage: ({
                        functions: { linearGradient, rgba },
                        palette: { gradients },
                      }) =>
                        `${linearGradient(
                          rgba(gradients.dark.main, 0.8),
                          rgba(gradients.dark.state, 0.8)
                        )}, url(${bgImageProfessionals})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <Container>
                      <Grid container item xs={12} lg={10} sx={{ ml: { xs: 0, lg: 6 } }}>
                        <MKTypography variant="h4" color="white" fontWeight="bold">
                          For Students
                        </MKTypography>
                        <MKTypography variant="body1" color="white" opacity={0.8} mb={2}>
                          Find your best fit role by playing games at hyrr. Sign up now!
                        </MKTypography>
                      </Grid>
                    </Container>
                  </MKBox>
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <MKBox
                    display="flex"
                    alignItems="center"
                    borderRadius="xl"
                    my={2}
                    py={6}
                    sx={{
                      backgroundImage: ({
                        functions: { linearGradient, rgba },
                        palette: { gradients },
                      }) =>
                        `${linearGradient(
                          rgba(gradients.dark.main, 0.8),
                          rgba(gradients.dark.state, 0.8)
                        )}, url(${bgImageStudents})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <Container>
                      <Grid container item xs={12} lg={10} sx={{ ml: { xs: 0, lg: 6 } }}>
                        <MKTypography variant="h4" color="white" fontWeight="bold">
                          For Students
                        </MKTypography>
                        <MKTypography variant="body1" color="white" opacity={0.8} mb={2}>
                          Showcase your skills and expertise in a fair and unbiased environment.
                          Take your career to new heights with EvalAI.
                        </MKTypography>
                      </Grid>
                    </Container>
                  </MKBox>
                </Grid> */}
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={4} px={1} mt={4}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

const textStyles = { textShadow: "2px 2px 4px #000000", color: "##C0C0C0" };

export default Hyrr;
