import { Card, Container, Typography } from "@mui/material";
import DefaultNavbar from "customComponents/DefaultNavbar";
import routes from "webRoutes";

const fontSize = {
  h3: "2rem",
  h4: "1.5rem",
  body1: "1rem",
  listItem: "1rem",
};

const CookiePolicy = () => {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/pages/landing-pages/contact-us",
          label: "Contact Us",
          color: "info",
        }}
        sticky
      />
      <Card
        sx={{
          p: 2,
          pt: 18,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
          <Typography variant="h3" gutterBottom style={{ fontSize: fontSize.h3 }}>
            Cookie Policy
          </Typography>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            Introduction
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            Welcome to Neusort’s cookie policy. This policy explains what cookies are, how we use
            them on our platforms neusort.com, evalai.neusort.com, the types of cookies we use, and
            how you can control them.
          </Typography>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            What Are Cookies?
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            Cookies are small text files that are stored on your device (computer, smartphone,
            tablet) when you visit a platform. They help the platform recognize your device and
            remember information about your visit, such as your preferences and settings.
          </Typography>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            How We Use Cookies
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            We use cookies to enhance your experience on our platform. Specifically, we use cookies
            to:
          </Typography>
          <ul style={{ paddingLeft: "1.5rem", fontSize: fontSize.body1 }}>
            <li>Remember your preferences and settings</li>
            <li>Improve our platform’s performance and functionality</li>
            <li>Enable certain functionalities, such as logging into your account</li>
          </ul>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            Types of Cookies We Use
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            We use the following types of cookies:
          </Typography>
          <ol style={{ paddingLeft: "1.5rem", fontSize: fontSize.body1 }}>
            <li style={{ marginBottom: "0.5rem" }}>
              <strong>Essential Cookies</strong>
              <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
                These cookies are necessary for the platform to function properly. They enable basic
                functions like page navigation and access to secure areas of the platform. Without
                these cookies, the platform cannot function correctly.
              </Typography>
            </li>
            <li style={{ marginBottom: "0.5rem" }}>
              <strong>Functionality Cookies</strong>
              <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
                These cookies allow our platform to remember choices you make and provide enhanced,
                more personalised features. They may also be used to provide services you have
                requested, such as watching a video.
              </Typography>
            </li>
          </ol>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            Managing Cookies
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            Please keep in mind that removing or blocking cookies can impact your user experience
            and parts of our platform may no longer be fully functional. Currently we don&apos;t
            give an option to opt out of cookies.
          </Typography>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            Changes to This Policy
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            We may update this cookie policy from time to time to reflect changes in our practices
            and services. When we make changes to this policy, we will update the “Effective Date”
            at the top of this page.
          </Typography>
        </Container>
      </Card>
    </>
  );
};

export default CookiePolicy;
