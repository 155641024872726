import { Card, Container, Typography } from "@mui/material";
import DefaultNavbar from "customComponents/DefaultNavbar";
import routes from "webRoutes";

const fontSize = {
  h3: "2rem",
  h4: "1.5rem",
  body1: "1rem",
  listItem: "1rem",
};

const DataPrivacyPolicy = () => {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/pages/landing-pages/contact-us",
          label: "Contact Us",
          color: "info",
        }}
        sticky
      />
      <Card
        sx={{
          p: 2,
          pt: 18,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
          <Typography variant="h3" gutterBottom style={{ fontSize: fontSize.h3 }}>
            Data Privacy Policy
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            Last Updated: 9 April 2024
          </Typography>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            1. Introduction
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            Neusort (&quot;we,&quot; &quot;our,&quot; &quot;us&quot;) is committed to protecting
            your privacy. This Data Privacy Policy explains how we collect, use, disclose, and
            safeguard your information when you visit our platforms neusort.com, evalai.neusort.com,
            use our services, or interact with us. Please read this policy carefully to understand
            our views and practices regarding your personal data and how we will treat it.
          </Typography>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            2. Information We Collect
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            We may collect and process the following types of information:
          </Typography>
          <ul style={{ paddingLeft: "1.5rem", fontSize: fontSize.body1 }}>
            <li style={{ marginBottom: "0.5rem" }}>
              <strong>Personal Identification Information</strong>
              <ul style={{ paddingLeft: "1.5rem" }}>
                <li>Name</li>
                <li>Email address</li>
                <li>Phone number</li>
                <li>Gender</li>
              </ul>
            </li>
            <li style={{ marginBottom: "0.5rem" }}>
              <strong>Technical Data</strong>
              <ul style={{ paddingLeft: "1.5rem" }}>
                <li>IP address</li>
                <li>Browser type and version</li>
                <li>Time zone setting</li>
              </ul>
            </li>
            <li style={{ marginBottom: "0.5rem" }}>
              <strong>Usage Data</strong>
              <ul style={{ paddingLeft: "1.5rem" }}>
                <li>Information about how you use our products and services</li>
              </ul>
            </li>
          </ul>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            3. How We Use Your Information
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            We use the information we collect in the following ways:
          </Typography>
          <ul style={{ paddingLeft: "1.5rem", fontSize: fontSize.body1 }}>
            <li>To provide, operate, and maintain our platform and services</li>
            <li>To improve, personalise, and expand our platform and services</li>
            <li>To understand and analyse how you use our platform and services</li>
            <li>To develop new products, services and features</li>
          </ul>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            4. Data Sharing
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            We don&apos;t share any personal information with third parties.
          </Typography>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            5. Data Protection
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            We are committed to ensuring that your information is secure. In order to prevent
            unauthorised access or disclosure, we have put in place suitable physical, electronic,
            and managerial procedures to safeguard and secure the information we collect online.
            These measures include:
          </Typography>
          <ul style={{ paddingLeft: "1.5rem", fontSize: fontSize.body1 }}>
            <li style={{ marginBottom: "0.5rem" }}>
              <strong>Encryption</strong>
              <ul style={{ paddingLeft: "1.5rem" }}>
                <li>
                  We use encryption protocols to protect personal data during transmission. This
                  includes the use of Secure Socket Layer (SSL) technology to encrypt data
                  transmitted over the internet.
                </li>
              </ul>
            </li>
            <li style={{ marginBottom: "0.5rem" }}>
              <strong>Access Controls</strong>
              <ul style={{ paddingLeft: "1.5rem" }}>
                <li>
                  Access to personal data is restricted to authorised personnel only. Employees and
                  contractors who have access to your data are subject to strict contractual
                  confidentiality obligations and may be disciplined or terminated if they fail to
                  meet these obligations.
                </li>
              </ul>
            </li>
            <li style={{ marginBottom: "0.5rem" }}>
              <strong>Data Anonymization and Pseudonymization</strong>
              <ul style={{ paddingLeft: "1.5rem" }}>
                <li>
                  Where possible, we anonymize and pseudonymize personal data to further enhance
                  privacy and security.
                </li>
              </ul>
            </li>
            <li style={{ marginBottom: "0.5rem" }}>
              <strong>Regular Security Assessments</strong>
              <ul style={{ paddingLeft: "1.5rem" }}>
                <li>
                  We conduct regular security assessments and audits to ensure our data protection
                  practices meet industry standards and regulatory requirements.
                </li>
              </ul>
            </li>
            <li style={{ marginBottom: "0.5rem" }}>
              <strong>Data Breach Response</strong>
              <ul style={{ paddingLeft: "1.5rem" }}>
                <li>
                  In the event of a data breach, we have established procedures to promptly address
                  the breach, including notifying affected individuals and regulatory authorities
                  when required by law.
                </li>
              </ul>
            </li>
            <li style={{ marginBottom: "0.5rem" }}>
              <strong>Data Minimization</strong>
              <ul style={{ paddingLeft: "1.5rem" }}>
                <li>
                  We ensure that we only collect and process data that is necessary for the purposes
                  for which it was collected. We also periodically review our data collection
                  practices to ensure that we are not collecting excessive or unnecessary data.
                </li>
              </ul>
            </li>
            <li style={{ marginBottom: "0.5rem" }}>
              <strong>Secure Data Disposal</strong>
              <ul style={{ paddingLeft: "1.5rem" }}>
                <li>
                  Personal data that is no longer needed is securely deleted or destroyed. We use
                  data wiping, degaussing, shredding, or other secure methods to dispose of data.
                </li>
              </ul>
            </li>
            <li style={{ marginBottom: "0.5rem" }}>
              <strong>Third-Party Service Providers</strong>
              <ul style={{ paddingLeft: "1.5rem" }}>
                <li>We don&apos;t send any personal data to third parties.</li>
              </ul>
            </li>
            <li style={{ marginBottom: "0.5rem" }}>
              <strong>Training and Awareness</strong>
              <ul style={{ paddingLeft: "1.5rem" }}>
                <li>
                  We provide regular training and awareness programs for our employees to ensure
                  they understand and comply with our data protection policies and procedures.
                </li>
              </ul>
            </li>
          </ul>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            6. User Rights
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            Please refer to the Data subject rights policy.
          </Typography>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            7. Data Retention
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            Please refer to the Data retention policy.
          </Typography>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            8. Changes to This Policy
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            We may update this Data Protection section from time to time to reflect changes in our
            practices or legal requirements. We will notify you of any significant changes by
            posting the updated policy on our platform and indicating the date of the latest
            revision.
          </Typography>
        </Container>
      </Card>
    </>
  );
};

export default DataPrivacyPolicy;
