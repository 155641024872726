import AddIcon from "@mui/icons-material/Add";
import { Divider, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

function DeletedSkills({
  skills,
  setFormData,
  setDeletedSkills,
  remainingSkills,
  setRemainingSkills,
  maxAllowedSkills,
}) {
  //   console.log(skills);
  function addAgain(type, skill, languageId) {
    // console.log(skill);
    if (remainingSkills === 0) {
      toast.error(`We can only test ${maxAllowedSkills} skills of a candidate in a single interview. Remove some skills to add more.`);
      return;
    }
    if (type === "old") {
      setFormData((prev) => {
        const data = { ...prev };
        const oldSkills = [...data.oldSkills];
        for (let i = 0; i < oldSkills.length; i++) {
          if (oldSkills[i].languageId === languageId) {
            oldSkills[i].skills.push(skill);
          }
        }
        data.oldSkills = oldSkills;
        return data;
      });
    }
    if (type === "new") {
      setFormData((prev) => {
        const data = { ...prev };
        data.newSkills.push(skill);
        return data;
      });
    }
    setDeletedSkills((prev) => {
      const data = [...prev];
      //   console.log(data);
      const newData = data.filter((d) => {
        if (skill.skillId) {
          return d.skill.skillId !== skill.skillId;
        }
        if (skill.id) {
          return d.skill.id !== skill.id;
        }
      });
      return newData;
    });
    setRemainingSkills((prev) => prev - 1);
  }
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Divider />
      {skills.map((s, index) => (
        <div
          style={{
            display: "flex",
            padding: "0px 10px 0px",
            alignItems: "center",
            gap: "20px",
            justifyContent: "space-between",
          }}
          key={index}
        >
          <p style={{ marginTop: "8px" }}>{s.skill.skillName || s.skill.skill}</p>
          <Tooltip title="Add" placement="right">
            <AddIcon
              onClick={() => addAgain(s.skill.type, s.skill, s.languageId)}
              fontSize="medium"
              style={{ color: "green", cursor: "pointer" }}
            />
          </Tooltip>
        </div>
      ))}
    </div>
  );
}

DeletedSkills.propTypes = {
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      skillName: PropTypes.string,
      languageId: PropTypes.number,
      languageName: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  setFormData: PropTypes.func,
  setDeletedSkills: PropTypes.func,
};

export default DeletedSkills;
