import { Grid, List, ListItem, ListItemText, Paper, Typography } from "@mui/material";

const QuestionSkillSummary = ({ programming_languages }) => {
  if (!programming_languages || !Object.entries(programming_languages).length) return null;
  console.log(programming_languages);
  return (
    <>
      <Typography style={{ marginTop: "1rem", color: "#00796b" }} variant="h5">
        Skills Tested:
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {Object.entries(programming_languages).map(([language, skills], index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper
              elevation={3}
              style={{
                background: "#fff",
                borderRadius: "10px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                overflow: "hidden",
              }}
            >
              <Typography
                variant="h5"
                component="h3"
                style={{
                  fontWeight: "bold",
                  padding: "0.5rem 1rem 0.5rem 1rem",
                  backgroundColor: "#f0f0f0",
                }}
              >
                {language}
              </Typography>
              <List style={{ margin: "1rem", marginTop: "0.5rem" }}>
                {skills.map((skill, skillIndex) => (
                  <ListItem key={skillIndex} style={{ paddingLeft: "0", display: "flex" }}>
                    <span style={{ marginRight: "0.5rem", color: "#000", alignSelf: "flex-start" }}>
                      •
                    </span>
                    <ListItemText
                      primary={skill}
                      primaryTypographyProps={{
                        style: { fontSize: "1rem", color: "#000", textAlign: "justify" },
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default QuestionSkillSummary;
