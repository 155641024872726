/* eslint-disable react/prop-types */
import RemoveIcon from "@mui/icons-material/Remove";
import { Tooltip } from "@mui/material";

function NewSkills({ skills, setDeletedSkills, setFormData, languageId, setRemainingSkills }) {
  // console.log(skills);
  function removeSkill(id, skill) {
    // console.log(index, skill);
    setDeletedSkills((prev) => {
      const obj = { languageId: languageId, skill: { ...skill } };
      const newData = [...prev, obj];
      return newData;
    });
    setFormData((prev) => {
      const data = { ...prev };
      const newSkills = [...data.newSkills];
      const newData = newSkills.filter((s) => s.id !== id);
      data.newSkills = newData;
      return data;
    });
    setRemainingSkills((prev) => prev + 1);
  }
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {skills.map((skill) => (
        <div
          style={{
            display: "flex",
            padding: "0px 10px 0px",
            alignItems: "center",
            gap: "20px",
            justifyContent: "space-between",
          }}
          key={skill.id}
        >
          <p style={{ marginTop: "8px" }}>{skill.skill}</p>
          <Tooltip title="Remove" placement="right">
            <RemoveIcon
              onClick={() => removeSkill(skill.id, skill)}
              fontSize="medium"
              style={{ color: "red", cursor: "pointer" }}
            />
          </Tooltip>
        </div>
      ))}
    </div>
  );
}

export default NewSkills;
