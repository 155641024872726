import PropTypes from "prop-types";
import DiffViewer from "react-diff-viewer";

function GitDiff({ answer }) {
  if (!answer) {
    return <p style={{ fontWeight: "600" }}>No answer </p>;
  }

  const diffLines = answer.split("\n");
  const oldLines = [];
  const newLines = [];

  diffLines.forEach((line) => {
    if (line.startsWith("-")) {
      oldLines.push(line.slice(1));
      newLines.push("");
    } else if (line.startsWith("+")) {
      oldLines.push("");
      newLines.push(line.slice(1));
    } else {
      oldLines.push(line);
      newLines.push(line);
    }
  });
  return (
    <div
      style={{
        borderRadius: "10px",
        display: "flex",
        width: "100%",
        fontSize: "0.8rem",
        padding: "2rem ",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        backgroundColor: "white",
        color: "black",
        overflow: "auto",
      }}
    >
      <DiffViewer
        oldValue={oldLines.join("\n")}
        newValue={newLines.join("\n")}
        splitView={true}
        showDiffOnly={false}
        styles={{
          variables: {
            light: {
              diffViewerBackground: "#f7f7f7",
              addedBackground: "#e6ffed",
              addedColor: "#24292e",
              removedBackground: "#ffeef0",
              removedColor: "#24292e",
              wordAddedBackground: "rgba(34, 134, 58, 0.3)",
              wordRemovedBackground: "rgba(255, 41, 41, 0.3)",
              addedGutterBackground: "#cdffd8",
              removedGutterBackground: "#ffdce0",
              gutterBackground: "#f7f7f7",
              gutterBackgroundDark: "#f3f1f1",
              highlightBackground: "#fffbdd",
              highlightGutterBackground: "#fff5b1",
              codeFoldGutterBackground: "#dbedff",
              codeFoldBackground: "#f1f8ff",
            },
          },
        }}
      />
    </div>
  );
}
GitDiff.propTypes = {
  answer: PropTypes.string,
};

export default GitDiff;
