import { HOST_NAMES, TEST_IPS } from "./globalConstants";

export const validateDomain = (url) => {
  try {
    const { hostname } = new URL(url);
    if (TEST_IPS.includes(hostname)) {
      return true;
    }
    return HOST_NAMES.includes(hostname.split(".").slice(-2).join("."));
  } catch (error) {
    console.log(error);
  }
};
