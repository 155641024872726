import Footer from "./Footer";
import CustomTechyrrFooter from "./TechyrrFooter";

const configFooter = (origin) => {
  switch (origin) {
    case "techyrr":
      return TechyrrFooter;
    case "evalai":
      return EvalaiFooter;
    case "hyrr":
      return HyrrFooter;
    default:
      return DefaultFooter;
  }
};

export default configFooter;

const TechyrrFooter = () => <CustomTechyrrFooter />;

const EvalaiFooter = () => <Footer brand={"EvalAi"} />;

const HyrrFooter = () => <Footer brand={"Hyrr"} />;

const DefaultFooter = () => <Footer brand={"Neusort"} />;
