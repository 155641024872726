import { Card, Container, Typography } from "@mui/material";
import DefaultNavbar from "customComponents/DefaultNavbar";
import routes from "webRoutes";

const fontSize = {
  h3: "2rem",
  h4: "1.5rem",
  body1: "1rem",
  listItem: "1rem",
};

const DataSubjectRightsPolicy = () => {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/pages/landing-pages/contact-us",
          label: "Contact Us",
          color: "info",
        }}
        sticky
      />
      <Card
        sx={{
          p: 2,
          pt: 18,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
          <Typography variant="h3" gutterBottom style={{ fontSize: fontSize.h3 }}>
            Data Subject Rights Policy
          </Typography>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            1. Introduction
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            Neusort (&quot;we&quot;, &quot;our&quot;, &quot;us&quot;) is committed to protecting the
            privacy and security of personal data. This policy outlines the rights of data subjects
            under data protection laws, including the General Data Protection Regulation (GDPR), and
            explains how individuals can exercise those rights.
          </Typography>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            2. Scope
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            This policy applies to all personal data processed by Neusort, including data relating
            to employees, customers, and other third parties.
          </Typography>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            3. Data Subject Rights
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            Under the GDPR and other applicable data protection laws, data subjects have the
            following rights:
          </Typography>
          <ol style={{ paddingLeft: "1.5rem", fontSize: fontSize.body1 }}>
            <li>
              <strong>Right to Access</strong>: Data subjects have the right to obtain confirmation
              as to whether their personal data is being processed and, if so, access to the
              personal data and information about the processing.
            </li>
            <li>
              <strong>Right to Rectification</strong>: Data subjects have the right to request the
              rectification of inaccurate personal data concerning them. They also have the right to
              have incomplete personal data completed.
            </li>
            <li>
              <strong>Right to Erasure (Right to be Forgotten)</strong>: Data subjects have the
              right to request the erasure of their personal data under certain conditions, such as
              when the data is no longer necessary for the purposes for which it was collected.
            </li>
            <li>
              <strong>Right to Restriction of Processing</strong>: Data subjects have the right to
              request the restriction of processing of their personal data under certain conditions,
              such as when they contest the accuracy of the data.
            </li>
            <li>
              <strong>Right to Data Portability</strong>: Data subjects have the right to receive
              their personal data in a structured, commonly used, and machine-readable format and
              have the right to transmit those data to another controller without hindrance.
            </li>
            <li>
              <strong>Right to Object</strong>: Data subjects have the right to object to the
              processing of their personal data on grounds relating to their particular situation,
              including profiling. They also have the right to object where personal data is
              processed for direct marketing purposes.
            </li>
            <li>
              <strong>Rights in Relation to Automated Decision-Making and Profiling</strong>: Data
              subjects have the right not to be subject to a decision based solely on automated
              processing, including profiling, which produces legal effects concerning them or
              similarly significantly affects them.
            </li>
          </ol>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            4. Exercising Data Subject Rights
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            <strong>4.1 How to Make a Request</strong>
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            Data subjects can exercise their rights by contacting us at:
          </Typography>
          <ul style={{ paddingLeft: "1.5rem", fontSize: fontSize.body1 }}>
            <li>Email: brij@neusort.com</li>
          </ul>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            Requests should include:
          </Typography>
          <ul style={{ paddingLeft: "1.5rem", fontSize: fontSize.body1 }}>
            <li>The data subject’s name and contact details.</li>
            <li>The right(s) they wish to exercise.</li>
            <li>
              Any information that will help us identify the data subject and locate the personal
              data.
            </li>
          </ul>

          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            <strong>4.2 Response Time</strong>
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            We aim to respond to all requests within one month. If the request is complex or
            numerous, we may extend this period by a further two months, in which case we will
            inform the data subject within the initial one-month period.
          </Typography>

          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            <strong>4.3 Verification of Identity</strong>
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            To protect personal data, we may ask data subjects to verify their identity before we
            process their request.
          </Typography>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            5. Changes to This Policy
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            We may update this policy from time to time. Any changes will be posted on our website,
            and, where appropriate, notified to data subjects by email.
          </Typography>

          <Typography variant="h4" gutterBottom style={{ fontSize: fontSize.h4 }}>
            6. Contact Us
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: fontSize.body1 }}>
            If you have any questions about this policy or our data protection practices, please
            contact us at:
          </Typography>
          <ul style={{ paddingLeft: "1.5rem", fontSize: fontSize.body1 }}>
            <li>Email: brij@neusort.com</li>
          </ul>
        </Container>
      </Card>
    </>
  );
};

export default DataSubjectRightsPolicy;
