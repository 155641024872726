import {
  child as firebaseChild,
  off as firebaseOff,
  onValue as firebaseOnValue,
  push as firebasePush,
  ref as firebaseRef,
  set as firebaseSet,
  update as firebaseUpdate,
} from "firebase/database";

import mixpanel from "mixpanel-browser";
import { auth } from "./firebase";
mixpanel.init("a0a0a5e6625d0709211fcafdc1b80c3d", {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
  ignore_dnt: true,
});

export const specificEmail = "demo@hyrr.app";
const demoUrl = "https://demo.hyrr.app";

export const ref = (db, path) => {
  if (
    (auth.currentUser && auth.currentUser.email === specificEmail) ||
    window.location.href === demoUrl
  ) {
    return {
      set: () => Promise.resolve(),
      update: () => Promise.resolve(),
      remove: () => Promise.resolve(),
    };
  }
  return firebaseRef(db, path);
};

export const update = (ref, data) => {
  if (
    (auth.currentUser && auth.currentUser.email === specificEmail) ||
    window.location.href === demoUrl
  ) {
    return Promise.resolve();
  }
  return firebaseUpdate(ref, data);
};

export const set = (ref, data) => {
  if (
    (auth.currentUser && auth.currentUser.email === specificEmail) ||
    window.location.href === demoUrl
  ) {
    return Promise.resolve();
  }
  return firebaseSet(ref, data);
};

export const child = (ref, path) => {
  if (
    (auth.currentUser && auth.currentUser.email === specificEmail) ||
    window.location.href === demoUrl
  ) {
    return {
      set: () => Promise.resolve(),
      update: () => Promise.resolve(),
      remove: () => Promise.resolve(),
    };
  }
  return firebaseChild(ref, path);
};

export const push = (child, data) => {
  if (
    (auth.currentUser && auth.currentUser.email === specificEmail) ||
    window.location.href === demoUrl
  ) {
    return Promise.resolve();
  }
  return firebasePush(child, data);
};

export const onValue = (ref, callback) => {
  if (
    (auth.currentUser && auth.currentUser.email === specificEmail) ||
    window.location.href === demoUrl
  ) {
    return () => {};
  }
  return firebaseOnValue(ref, callback);
};

export const off = (ref, eventType, callback) => {
  if (
    (auth.currentUser && auth.currentUser.email === specificEmail) ||
    window.location.href === demoUrl
  ) {
    return;
  }
  return firebaseOff(ref, eventType, callback);
};

export const trackEvent = (eventName, value) => {
  try {
    mixpanel.track(eventName, value);
  } catch (e) {
    console.log("error while tracking events", e);
  }
};
