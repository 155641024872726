import SummarizeIcon from "@mui/icons-material/Summarize";
import PropTypes from "prop-types";
import RatingCard from "./Ratingcard";

function InterviewSummary({ data, hintsTaken, ratings }) {
  return (
    <div
      style={{
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
        margin: "20px",
        maxWidth: "800px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <SummarizeIcon
          style={{
            color: "#007BFF",
          }}
          fontSize="large"
        />
        <h2
          style={{
            margin: "0 0 0 10px",
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: "#333",
          }}
        >
          Interview Summary
        </h2>
      </div>
      <div
        style={{
          padding: "10px",
          borderRadius: "5px",
          backgroundColor: "#f9f9f9",
          marginBottom: "20px",
        }}
      >
        <p
          style={{
            fontSize: "1rem",
            color: "#555",
            lineHeight: "1.5",
          }}
        >
          {data}
        </p>
      </div>
      <div
        style={{
          padding: "10px",
          borderRadius: "5px",
          backgroundColor: "#f1f1f1",
          marginBottom: "20px",
        }}
      >
        <h3
          style={{
            fontSize: "1.2rem",
            fontWeight: "bold",
            color: "#333",
          }}
        >
          Hints Taken: {hintsTaken}
        </h3>
      </div>
      <RatingCard data={ratings} />
    </div>
  );
}

InterviewSummary.propTypes = {
  data: PropTypes.string.isRequired,
  hintsTaken: PropTypes.number.isRequired,
  ratings: PropTypes.array.isRequired,
};

export default InterviewSummary;
