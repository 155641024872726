import DraftsIcon from "@mui/icons-material/Drafts";
import Badge from "@mui/material/Badge";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Tooltip from "@mui/material/Tooltip";
import FormDrafts from "customComponents/AdminComponents/JobForm/FormDrafts";
import { UserAuth } from "provider/AuthProvider";
import * as React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormDraftsModal() {
  const [totalDrafts, setTotalDrafts] = React.useState(0);
  const { isDraftsOpen, openDraftModal, closeDraftModal } = UserAuth();

  return (
    <React.Fragment>
      <div
        onClick={openDraftModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Tooltip title="Your Drafts">
          <Badge badgeContent={totalDrafts} color="primary">
            <DraftsIcon fontSize="large" style={{ color: "#006566" }} />
          </Badge>
        </Tooltip>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={isDraftsOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeDraftModal}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "900",
            borderBottom: "1px solid gray",
          }}
        >
          {"DRAFTS"}
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "#eff2f5" }}>
          <FormDrafts setTotalDrafts={setTotalDrafts} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}